import React from "react";
import { VerticalLinearStepper } from "../components/UI";
import { QueryProvider, QueryMode } from "../components/queries";
import { IQuery } from "../components/queries/interfaces/IQuery";
import { useHistory } from "react-router-dom";

interface IState {
    query: IQuery;
    queryMode: QueryMode;
}

export const EditQueryPage = () => {
    const history = useHistory();
    const query = (history.location.state as IState)?.query;
    const queryMode = (history.location.state as IState)?.queryMode;

    return (
        <QueryProvider queryMode={queryMode} query={query}>
            <VerticalLinearStepper />
        </QueryProvider>
    );
};
