const ButtonContainer = {
    width: "290px"
};

const BaseStyles = {
    borderRadius: "2px",
    fontSize: "14px",
    color: "white",
    textTransform: "none"
};

const DefaultFormButton = {
    ...BaseStyles,
    background: "#005677"
};

const PrimaryButton = {
    ...BaseStyles,
    margin: "0 10px 0 0",
    background: "#007BC0",
    lineHeight: "16px",
    minWidth: "150px"
};

const SecondaryButton = {
    ...BaseStyles,
    margin: "0 10px 0 0",
    color: "#626262",
    lineHeight: "5px",
    border: "2px solid #007BC0",
    minWidth: "150px"
};

const ChoiseBaseButton = {
    ...BaseStyles,
    background: "#EDF1F1",
    color: "#626262",
    margin: "0 0 10px 0"
};

const ChoiseSelectedButton = {
    ...ChoiseBaseButton,
    background: "#108393",
    color: "#FFFFFF"
};

const SaveButtonIcon = {
    fontSize: "1rem",
    position: "relative",
    top: "1px",
    marginRight: "10px"
};

export const ButtonStyled = {
    ButtonContainer,
    ChoiseBaseButton,
    ChoiseSelectedButton,
    DefaultFormButton,
    PrimaryButton,
    SecondaryButton,
    SaveButtonIcon
};
