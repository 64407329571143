import React from "react";
import { Styled } from "./styled";
import { UserActivityApi } from "../../../core/api/userActivityApi";
import { UserActivityActionNames } from "../../usersManagement";

interface IProps {
    value: string;
}

export const EmailCell = (props: IProps) => {
    return (
        <a
            onClick={async () => {
                await UserActivityApi.create(
                    UserActivityActionNames.TriggerEmailViaContactUs
                );
            }}
            style={Styled.EmailLink}
            href={"mailto:" + props.value}
        >
            {props.value}
        </a>
    );
};
