import { IRole } from "../../typings";
import { httpGet, httpPost, httpDelete } from "./request/requestApi";

const controllerEndPoint = "roles";

export class RolesApi {
    static async getAll(): Promise<Array<IRole>> {
        return await httpGet(`${controllerEndPoint}/get-all`);
    }

    static async get(roleId: number): Promise<IRole> {
        return await httpGet(`${controllerEndPoint}/get?roleId=${roleId}`);
    }

    static async create(role: IRole): Promise<void> {
        await httpPost(`${controllerEndPoint}/create`, { body: role });
    }

    static async update(role: IRole): Promise<void> {
        await httpPost(`${controllerEndPoint}/update`, { body: role });
    }

    static async delete(roleId: number | undefined): Promise<void> {
        await httpDelete(`${controllerEndPoint}/delete`, { body: { roleId: roleId } });
    }
}
