import { MDBSelect } from "mdbreact";
import React from "react";
import { Styled } from "../styled";
import { ISelectOption } from "../../interfaces/ISelectOption";
import { useQuery } from "../../store/queryContext";
import { DataSetApi } from "../../../../core/api";
import { toLowerCaseFirstChar } from "../../../../common";
import { QueryMode } from "../../enums/queryMode";
import { IDataSet } from "../../../../typings";

interface IProps {
    options: Array<ISelectOption>;
    title: string;
}

export const MarketSelector = (props: IProps) => {
    const { query, setQuery, queryMode, updateQuery } = useQuery();

    const getValue = async (values: any) => {
        const propertyName = toLowerCaseFirstChar(props.title);
        const value = values[0];
        if (values.length && query[propertyName] !== value) {
            query.dataSets = await DataSetApi.getAll(props.title, values);
            query.dataSets.forEach(d => updateDataSet(d, values));
            query[propertyName] = value;
            query[propertyName + "Description"] = props.options.find(o => o.value === value)?.text;
            setQuery({ ...query });
            updateQuery();
        }
    };

    const updateDataSet = (dataSet: IDataSet, values: any) => {
        dataSet.filters = [{ propertyName: props.title, values: values }];
    };

    return (
        <div id={props.title} className="d-flex" style={Styled.MarketSelectorBlock}>
            <p style={Styled.MarketSelectorHeader}>{props.title}</p>
            <MDBSelect
                className={queryMode === QueryMode.View ? "disabled" : ""}
                options={props.options}
                getValue={getValue}
            />
        </div>
    );
};
