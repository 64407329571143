import React from "react";
import { INotificationContext } from "./INotificationContext";

export const notificationContext = React.createContext<INotificationContext>({
    notificationId: "",
    setNotificationId: () => {}
});

export const useNotification = () => {
    return React.useContext(notificationContext);
};
