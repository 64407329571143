import { QueryCreationSteps } from "./queryCreationSteps";
import { Messages } from "../../queries/common/messages";
import { IQueryError } from "../../queries/interfaces";

const isStepAllowed = (step: QueryCreationSteps, errors: Array<IQueryError>) =>
    !errors.length ||
    (step < QueryCreationSteps.PreviewAndExport &&
        !errors.some(
            e => e.message === Messages.PleaseChooseADataset || e.message === Messages.PleaseSelectTwoDatasetsToCorrelateThem
        ));

export const StepperService = {
    isStepAllowed
};
