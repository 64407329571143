const VisualizationBlock = {
    marginBottom: 25
};

const ActionButton = (isRightMargin: boolean) => ({
    color: "#626262",
    textDecorationLine: "underline",
    marginRight: isRightMargin ? "15%" : 0,
    marginLeft: !isRightMargin ? "15%" : 0
});

export const Styled = { VisualizationBlock, ActionButton };
