import React from 'react'

export interface IProps {
    date: Date;
}

export const DateField = (props: IProps) => {
    const getDate = () => {
        const date = new Date(props.date);
        return `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}  ${("0" + (date?.getHours() % 12 || 12)).slice(-2)}:${("0" + date?.getMinutes()).slice(-2)} ${date?.getHours()>= 12 ? "PM":"AM"}`;
    }

    return (
        <div>{getDate()}</div>
    )
}
