const PleaseChooseADataset = "Please choose a dataset.";
const PleaseFillInReportTitleField = "Please fill in Report Title field.";
const VisualizationIsNotAvailable = "Visualization is not available for the chosen dataset(s) or attributes.";
const PleaseSelectTwoDatasetsToCorrelateThem = "Please select two datasets to correlate them.";
const PleaseSelectDateRangesAndCalculations = "Please select date ranges and calculations.";
const PleaseSelectDateRanges = "Please select date ranges";
const SelectDateType = "Select date type";
const SelectVolumeType = "Select volume type";

const AdjustDateRangesSoTheyHaveTheSameNumberOfTimePeriods =
    "Adjust date ranges so they have the same number of time periods shown in the report.";

export const Messages = {
    PleaseChooseADataset,
    PleaseSelectTwoDatasetsToCorrelateThem,
    AdjustDateRangesSoTheyHaveTheSameNumberOfTimePeriods,
    PleaseFillInReportTitleField,
    VisualizationIsNotAvailable,
    PleaseSelectDateRangesAndCalculations,
    PleaseSelectDateRanges,
    SelectDateType,
    SelectVolumeType
};
