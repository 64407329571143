import { httpPost } from "./request/requestApi";

const controllerEndPoint = "userActivity";

export class UserActivityApi {
    static async create(actionName: string) {
        await httpPost(`${controllerEndPoint}/create`, {
            body: { actionName }
        });
    }
}
