import { MDBInput } from "mdbreact";
import React from "react";
import { SortDescriptionTypes } from "./sortDescriptionTypes";

interface IProps {
    id?: string;
    isDisabled: boolean;
    isFirstSelected: boolean | undefined;
    descriptionType: SortDescriptionTypes | undefined;
    setDescriptionType: (descriptionType: SortDescriptionTypes) => void;
}

export const SortDescriptionRadioButton = (props: IProps) => (
    <>
        <MDBInput
            disabled={props.isDisabled}
            onClick={() => props.setDescriptionType(SortDescriptionTypes.Long)}
            checked={props.descriptionType === SortDescriptionTypes.Long}
            label="Long"
            type="radio"
            id={`radioLong${props.id}`}
            containerClass="mr-3"
        />
        <MDBInput
            disabled={props.isDisabled}
            onClick={() => props.setDescriptionType(SortDescriptionTypes.Short)}
            checked={props.descriptionType === SortDescriptionTypes.Short}
            label="Short"
            type="radio"
            id={`radioShort${props.id}`}
            containerClass="mr-3"
        />
        <MDBInput
            disabled={props.isDisabled || props.isFirstSelected}
            onClick={() => props.setDescriptionType(SortDescriptionTypes.No)}
            checked={props.descriptionType === SortDescriptionTypes.No}
            label="None"
            type="radio"
            id={`radioNo${props.id}`}
            containerClass="mr-3"
        />
    </>
);
