import React from "react";
import { MDBIcon, MDBNavItem, MDBNavLink } from "mdbreact";
import { useHistory } from "react-router-dom";
import { Styled } from "./style/styled";

interface IProps {
    url: string;
    icon: string;
    text: string;
    isSideBarOpened: boolean;
}

export const SideBarLink = (props: IProps) => {
    const history = useHistory();

    const isLinkOpened = history.location.pathname === props.url;

    return (
        <MDBNavItem>
            <MDBNavLink
                active
                to={props.url}
                style={Styled.SideBarLinkContainer(props.isSideBarOpened)}
                className={
                    isLinkOpened ? "text-nowrap bd-highlight sideBarlinkContainerPressed" : "text-nowrap bd-highlight"
                }
                onClick={() => {
                    document.location.href=props.url;
                }}
            >
                <div className={isLinkOpened ? "sideBarlinkPressed" : "sideBarlinkImage"}>
                    <MDBIcon icon={props.icon} style={Styled.SideBarLinkIcon(props.isSideBarOpened)} size="lg" />
                    <div className={isLinkOpened ? "sideBarlinkPressed" : "sideBarlinkText"}>{props.text}</div>
                </div>
            </MDBNavLink>
        </MDBNavItem>
    );
};
