import React from "react";
import { MDBNavbarNav, MDBNavItem, MDBIcon } from "mdbreact";

interface IProps {
    setSideBarOpened: Function;
}

export const BarButton = (props: IProps) => (
    <div>
        <MDBNavbarNav left>
            <MDBNavItem>
                <MDBIcon
                    icon="bars"
                    className="text-white ml-3 cursor-pointer"
                    size="2x"
                    onClick={() => props.setSideBarOpened()}
                />
            </MDBNavItem>
        </MDBNavbarNav>
    </div>
);
