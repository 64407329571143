import React, { PropsWithChildren } from "react";
import { MDBTooltip } from "mdbreact";

interface IProps {
    text: string | undefined;
}

export const Tooltip = (props: PropsWithChildren<IProps>) => (
    <MDBTooltip domElement tag="span" material placement="top">
        <span>{props.children}</span>
        <span>{props.text}</span>
    </MDBTooltip>
);
