import { MDBContainer } from "mdbreact";
import React from "react";
import { Markets } from "./markets";
import { Datasets } from "./datasets";
import { SpecificCalculations } from "./checkboxes/specificCalculations";

export const SelectMarketAndDataset = () => (
    <MDBContainer>
        <Markets />
        <Datasets />
        <SpecificCalculations />
    </MDBContainer>
);
