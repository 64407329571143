import React from "react";
import { Route, Switch } from "react-router-dom";
import { Routes, AdminPrivateRoute } from "../../common";
import { Styled } from "./styled";
import {
    HelpPage,
    NewQueryPage,
    MyQueriesPage,
    DocumentationPage,
    ContactUsPage,
    HomePage,
    UserManagementPage,
    SignUpPage,
    ContactUsManagementPage,
    LandingPageManagement,
    HelpManagementPage,
    UpdateUserPage,
    DocumentManagementPage,
    FooterManagementPage,
    EditQueryPage,
    UserQueriesPage,
    RoleManagementPage,
    EditRolePage,
    CreateRolePage,
    ReportPreviewPage,
    MyAccountPage
} from "../../pages";

export const Main = () => (
    <main style={Styled.Main(window.innerHeight)}>
        <Switch>
            <Route exact path={Routes.Home} component={HomePage} />
            <Route path={Routes.Help} component={HelpPage} />
            <Route path={Routes.NewQuery} component={NewQueryPage} />
            <Route path={Routes.EditQuery} component={EditQueryPage} />
            <Route path={Routes.MyQueries} component={MyQueriesPage} />
            <Route path={Routes.Documentation} component={DocumentationPage} />
            <Route path={Routes.ContactUs} component={ContactUsPage} />
            <Route exact path={Routes.MyAccount} component={MyAccountPage} />
            <Route path={Routes.ReportPreview} component={ReportPreviewPage} />
            <AdminPrivateRoute path={Routes.RolesManagement} component={RoleManagementPage} />
            <AdminPrivateRoute path={Routes.EditRole} component={EditRolePage} />
            <AdminPrivateRoute path={Routes.CreateRole} component={CreateRolePage} />
            <AdminPrivateRoute path={Routes.UsersManagement} component={UserManagementPage} />
            <AdminPrivateRoute path={Routes.CreateUser} component={SignUpPage} />
            <AdminPrivateRoute path={Routes.ContactUsManagement} component={ContactUsManagementPage} />
            <AdminPrivateRoute path={Routes.UpdateUser} component={UpdateUserPage} />
            <AdminPrivateRoute path={Routes.LandingPageManagement} component={LandingPageManagement} />
            <AdminPrivateRoute path={Routes.HelpManagement} component={HelpManagementPage} />
            <AdminPrivateRoute path={Routes.DocumentManagement} component={DocumentManagementPage} />
            <AdminPrivateRoute path={Routes.FooterManagement} component={FooterManagementPage} />
            <AdminPrivateRoute path={Routes.UserQueries} component={UserQueriesPage} />
        </Switch>
    </main>
);
