import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// @ts-ignore
import ReactNotification from "react-notifications-component";
import { UserActivityManager } from "./components/userActivityManager";
import { UserContextProvider } from "./stores";
import { NotificationProvider, ArticleProvider } from "./components/UI";
import { Routes, LoginPrivateRoute } from "./common";
import "./common/prototypes/arrayPrototypes";
import "./common/prototypes/numberPrototypes";
import { LoginPage, ForgotPasswordPage, ResetPasswordPage, ContactUsPageUnauthorized, LandingPage } from "./pages";

const App: React.FC = () => (
    <BrowserRouter>
        <UserContextProvider>
            <NotificationProvider>
                <ReactNotification />
                <UserActivityManager />
                <ArticleProvider>
                    <Switch>
                        <Route path={Routes.Login} component={LoginPage} />
                        <Route path={Routes.ForgotPassword} component={ForgotPasswordPage} />
                        <Route path={Routes.ResetPassword} component={ResetPasswordPage} />
                        <Route path={Routes.ContactUsPageUnauthorized} component={ContactUsPageUnauthorized} />
                        <LoginPrivateRoute component={LandingPage} />
                    </Switch>
                </ArticleProvider>
            </NotificationProvider>
        </UserContextProvider>
    </BrowserRouter>
);

export default App;
