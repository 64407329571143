import React from "react";
import { EditorSaveButton, EditorCancelButton, DefaultParagraph, ArticleEditor, Constants } from "../components/UI";
import { ArticleTypes } from "../typings";
import { MDBContainer } from "mdbreact";
import { EditableArticleProvider } from "../components/UI/textEditors/articleEditor/stores/editableArticleProvider";

export const FooterManagementPage = () => {
    return (
        <EditableArticleProvider>
            <MDBContainer>
                <DefaultParagraph text="Footer" />
                <EditorSaveButton
                    articleType={ArticleTypes.Footer}
                    bodyMaxLength={Constants.FooterAndLandingTitleMaxLength}
                />
                <EditorCancelButton articleType={ArticleTypes.Footer} />
                <ArticleEditor
                    articleType={ArticleTypes.Footer}
                    maxLength={Constants.FooterAndLandingTitleMaxLength}
                    propertyName="articleBody"
                    label="Footer"
                />
            </MDBContainer>
        </EditableArticleProvider>
    );
};
