import { MDBContainer, MDBIcon, MDBCard } from "mdbreact";
import React from "react";
import { Styled } from "./styled";

interface IProps {
    text: string | undefined;
    index: number;
    isOpen: boolean;
    isFailed?: boolean;
    setCollapseId: (collapseId: number | undefined) => void;
}

export const AccordionHeader = (props: IProps) => (
    <MDBCard style={props.isOpen ? Styled.AccordionSelected : Styled.Accordion(props.isFailed)} className="my-1">
        <MDBContainer
            className="mt-2"
            onClick={() => {
                if (props.isOpen) props.setCollapseId(undefined);
                else props.setCollapseId(props.index);
            }}
        >
            {props.text}
            <MDBIcon
                icon={props.isOpen ? "angle-up" : "angle-down"}
                className={props.isOpen ? "white-text float-right mt-2" : "black-text float-right mt-2"}
            />
        </MDBContainer>
    </MDBCard>
);
