import React from "react";
import { MDBContainer } from "mdbreact";
import {
    DefaultParagraph,
    Preloader,
    GridProvider,
    UploadFileForm,
    FilesProvider,
} from "../components/UI";
import { DocumentManagementGrid } from "../components/documentation";
import { AttachmentType } from "../typings";

export const DocumentManagementPage = () => (
    <GridProvider>
        <FilesProvider attachmentType={AttachmentType.Documentation}>
            <MDBContainer>
                <DefaultParagraph text="Documentation" />
                <UploadFileForm />
                <div style={{height: "5px"}}>
                    <Preloader />
                </div>
                <DocumentManagementGrid />
            </MDBContainer>
        </FilesProvider>
    </GridProvider>
);
