import React from "react";
import { tableColumns } from "./tableColumns";
import { DisableUserCheckbox } from "./disableUserCheckbox";
import { useUsers } from "./stores/usersContext";
import { DeleteUserButton } from "./buttons/deleteUserButton";
import { EditUserButton } from "./buttons/editUserButton";
import { UserQueriesButton } from "./buttons/userQueriesButton";
import { ScrollTable } from "../UI";
import "./styled/style.css";

export const UserManagementTable = () => {
    const { users } = useUsers();

    const rows = users.map(user => ({
        id: user.id,
        userName: user.userName,
        fullName: user.fullName?.trim(),
        description: user.description?.trim(),
        email: user.email,
        roles: user.roles,
        isDisabled: (
            <div className="text-center">
                {!user.isSuperAdmin && (
                    <DisableUserCheckbox userId={user.id} isDisabled={user.isDisabled} key={user.id} />
                )}
            </div>
        ),
        actions: (
            <div className="d-flex justify-content-around">
                <EditUserButton user={user} />
                {!user.isSuperAdmin && <DeleteUserButton userId={user.id} />}
                <UserQueriesButton user={user} />
            </div>
        )
    }));

    return (
        <ScrollTable
            className="mt-4 user-management-table"
            maxHeight="76vh"
            searching={true}
            small
            data={{
                columns: tableColumns,
                rows: rows
            }}
        />
    );
};
