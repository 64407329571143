import React from "react";
import { PeriodSelector } from "../selectors/periodSelector";
import { YearsSelector } from "../selectors/yearsSelector";
import { datePeriod, periods } from "../../../../../typings";
import { IPeriodsRanges } from "../../../interfaces/IPeriodsRanges";
import { MDBRow, MDBCol } from "mdbreact";

interface IProps {
    period: datePeriod | undefined;
    year: string | undefined;
    yearSetter: (year: string | undefined) => void;
    datePeriod: string | undefined;
    datePeriodSetter: (datePeriod: string | undefined) => void;
    isStart?: boolean;
    ranges?: IPeriodsRanges;
    periodType: periods;
    fromYear?: string;
    toYear?: string;
    fromPeriod?: number;
    toPeriod?: number;
    additionalPeriodOptions?: string[];
}

export const PeriodPicker = (props: IProps) => (
    <span>
        <MDBRow>
            <MDBCol className="px-0">
                <YearsSelector
                    value={props.year}
                    from={props.fromYear ? props.fromYear : props.ranges?.beginYear}
                    to={props.toYear ? props.toYear : props.ranges?.endYear}
                    setter={props.yearSetter}
                    period={props.period}
                    isStart={props.isStart}
                    ranges={props.ranges}
                />
            </MDBCol>
            <MDBCol className="px-0">
                <PeriodSelector
                    value={props.datePeriod}
                    setter={props.datePeriodSetter}
                    periodType={props.periodType}
                    isStart={props.isStart}
                    period={props.period}
                    from={props.fromPeriod}
                    to={props.toPeriod}
                    additionalPeriodOptions={props.additionalPeriodOptions}
                />
            </MDBCol>
        </MDBRow>
    </span>
);
