//@ts-ignore
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { Notifications } from "../../components/UI";

export const useRedirect = (isUpdate: boolean, entityName: string, url: string) => {
    const history = useHistory();

    const redirectAfterEdit = () => {
        store.addNotification(
            Notifications.Successfull(
                `The ${entityName} has been successfully ${
                isUpdate ? "updated" : "created"
                }!`
            )
        );
        history.push(url);
    }

    return [redirectAfterEdit];
};
