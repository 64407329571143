import React from "react";
import { DataSetNames } from "../../../../common/dataSetNames";
import { DataSetService, QueryErrorService, QueryService, SortService } from "../../common";
import { useQuery } from "../../store/queryContext";
import { SpecificCalculationCheckbox } from "./specificCalculationCheckbox";

export const CorrelationCheckbox = () => {
    const { query, setQuery, errors, setErrors } = useQuery();
    return (
        <>
            {query.dataSets?.some(
                d =>
                    (d.dataSetName === DataSetNames.Sales ||
                        d.dataSetName === DataSetNames.Production ||
                        d.dataSetName === DataSetNames.Inventory) &&
                    d.isSelected &&
                    !query.isSAAR &&
                    DataSetService.getSelected(query.dataSets).length < 3
            ) && (
                <SpecificCalculationCheckbox
                    id="correlationCheckbox"
                    label="Build correlation between 2 datasets"
                    onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                        query.isCorrelation = e.currentTarget.checked;
                        const sorts = SortService.getSorts(query.dataSets);
                        await QueryService.updateQueryDatasetsAvailability(query);
                        setErrors(QueryErrorService.filterStepErrors(errors));
                        setQuery({ ...query, sorts: sorts });
                    }}
                    checked={query.isCorrelation}
                />
            )}
        </>
    );
};
