import React, { PropsWithChildren, useEffect, useState } from "react";
import { userContext } from "..";
import { IUser } from "../../typings";
import { UserContextActions } from "./userContextActions";

export const UserContextProvider = (props: PropsWithChildren<{}>) => {
    const [user, setUser] = useState<IUser>({});
    const value: any = { user, setUser };

    useEffect(() => {
        (async function loadUserContext(): Promise<void> {
            try {
                let user = await UserContextActions.load();
                user.isLoaded = true;
                setUser(user);
            } catch (e) {
                setUser({ isLoaded: true });
            }
        })();
    }, []);

    return <userContext.Provider value={value}>{props.children}</userContext.Provider>;
};
