import React, { useState } from "react";
import { HeaderWithMenu, UserSideBar, AdminSideBar } from ".";
import { useUser } from "../../stores";
import { Constants } from "../../common";

export const BaseNavigation = () => {
    const [isSideBarOpened, setIsSideBarOpened] = useState(true);
    const onSideBarStateChange = () => {
        setIsSideBarOpened(!isSideBarOpened);
        const content = document.getElementById(Constants.ContentId);
        content!.className = !isSideBarOpened ? "sidebar-opened" : "sidebar-closed";
    };
    const { user } = useUser();
    return (
        <>
            <HeaderWithMenu setSideBarOpened={onSideBarStateChange} />
            {user.isAdmin ? (
                <AdminSideBar isSideBarOpened={isSideBarOpened} />
            ) : (
                <UserSideBar isSideBarOpened={isSideBarOpened} />
            )}
        </>
    );
};
