const ControlButtons = {
    color: "#999999"
};

const PwdStyle = {
    marginTop: "25px"
}
export const Styled = {
    ControlButtons,
    PwdStyle
};
