import React, { useEffect } from "react";
import { useRole } from "../store/roleContext";
import { ISelectOption } from "../../queries/interfaces/ISelectOption";
import { RegionApi } from "../../../core/api";
import { Selector } from "./selector";

interface IProps {
    isSubmitted: boolean;
}

export const RegionSelector = (props: IProps) => {
    const { role, regions, setRegions } = useRole();

    useEffect(() => {
        (async () => {
            const regions = await RegionApi.getAll();
            const options = new Array<ISelectOption>();
            regions.forEach(r =>
                options.push({
                    value: r.region_ID,
                    text: r.description,
                    checked: role.entityDatasets?.some(rc => rc.isRegion && rc.entity === r.region_ID)
                })
            );
            setRegions(options);
        })();
    }, []);

    return (
        <Selector
            isSubmitted={props.isSubmitted}
            selected="Select regions"
            options={regions}
            property="isRegion"
            text="Region Access"
        />
    );
};
