import React from "react";
import { Styled } from "./style/styled";

export const LoginLogo = () => (
    <div className="text-center">
        <img
            src={require("../../common/img/loginlogo.png")}
            className="img-fluid"
            unselectable="on"
            style={Styled.Logo}
            alt="logo"
        />
    </div>
);
