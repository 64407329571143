import React, { useState, useEffect } from "react";
import { Scatter } from "react-chartjs-2";
import { GraphDataReceiver } from "../services/graphDataReceiver";
import { useQuery } from "../../store/queryContext";
import "./plugins/chartjs-plugin-watermark";

export const ScatterChart = () => {
    const { query } = useQuery();
    const [data, setData] = useState<any>();

    useEffect(() => {
        setData(
            GraphDataReceiver.getScatterChartData(
                query.chartData?.data.first(),
                query.chartData?.data.second(),
                query.chartData?.trendCoefficientA,
                query.chartData?.trendCoefficientB
            )
        );
    }, [query.chartData]);

    return (
        <>
            <div className="text-right">
                y = {query.chartData?.trendCoefficientA?.toFixed(4)}x
                {query.chartData?.trendCoefficientB && (
                    <>
                        {query.chartData.trendCoefficientB < 0 ? " - " : " + "}
                        {Math.abs(query.chartData.trendCoefficientB).toFixed(2)}
                    </>
                )}
                <div>
                    R
                    <sup>
                        <small>2</small>
                    </sup>
                    = {query.chartData?.determinationCoefficient?.toFixed(4)}
                </div>
            </div>
            <Scatter
                data={data}
                options={GraphDataReceiver.getScatterChartOptions(
                    query.chartData?.attributeNames.first(),
                    query.chartData?.attributeNames.second(),
                    query.title
                )}
            />
        </>
    );
};
