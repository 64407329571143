import React, { useState } from "react";
import { useQuery } from "../../store/queryContext";
import { QueryMode } from "../..";
import { QueryCreationSteps } from "../../../UI";
import { MDBRow, MDBCol, MDBContainer, MDBInput } from "mdbreact";
import { CorrelationConstants, QueryErrorService } from "../../common";
import { Styled } from "./styles/styled";
import "./styles/style.css";

export const CorrelationStep = () => {
    const { query, queryMode, setErrors, errors } = useQuery();

    const [correlationStep, setCorrelationStep] = useState(query.correlationStep);

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    return (
        <>
            {query.isCorrelation && (
                <MDBContainer>
                    <MDBRow>
                        <MDBCol size="9" className="my-auto">
                            <span style={Styled.CorrelationStepInput}>
                                Define number of time periods in set of paired data to be used in correlation coefficient
                                calculation:
                            </span>
                        </MDBCol>
                        <MDBCol size="1">
                            <MDBInput
                                className="text-center"
                                disabled={queryMode === QueryMode.View}
                                required
                                type="number"
                                value={correlationStep}
                                min={CorrelationConstants.MinCorrelationStep}
                                onKeyDown={onKeyDownHandler}
                                getValue={(value: any) => {
                                    setCorrelationStep(value);
                                    if (errors.some(e => e.step === QueryCreationSteps.ChooseDatesAndCalculations))
                                        setErrors(QueryErrorService.filterStepErrors(errors));
                                    query.correlationStep = value;
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            )}
        </>
    );
};
