import React from "react";
import { useHistory } from "react-router-dom";
import { Styled } from "../styled/styled";
import { Routes } from "../../../common";
import { IUser } from "../../../typings";
import { IconWithTooltip } from "../../UI";

interface IProps {
    user: IUser;
}

export const EditUserButton = (props: IProps) => {
    const history = useHistory();
    return (
        <IconWithTooltip style={Styled.ControlButtons} tooltip="Edit" icon="pencil-alt" onClick={() =>
            history.push(Routes.UpdateUser, {
                user: props.user
            })
        } />
    );
};
