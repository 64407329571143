import React, { useState } from "react";
import { QueryApi } from "../../../core/api";
import { DefaultModal, Notifications, NotificationMessages, IconWithTooltip } from "../../UI";
import { useQuery } from "../store/queryContext";
// @ts-ignore
import { store } from "react-notifications-component";

interface IProps {
    queryId: number | undefined;
    tooltip: string;
}

export const DeleteQueryButton = (props: IProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { userQueries, setUserQueries } = useQuery();

    const deleteQuery = async () => {
        await QueryApi.delete(props.queryId);
        setUserQueries(userQueries.filter(q => q.queryId !== props.queryId));
        setModalIsOpen(false);
        store.addNotification(Notifications.Successfull(NotificationMessages.TheQueryHasBeenSuccessfullyDeleted));
    };

    return (
        <>
            <IconWithTooltip tooltip={props.tooltip} icon="trash-alt" onClick={() => setModalIsOpen(true)} />
            <DefaultModal
                isOpen={modalIsOpen}
                onCancel={() => setModalIsOpen(false)}
                onСonfirm={deleteQuery}
                cancelButtonText="No, cancel"
                bodyText="Do you want to delete the query?"
                confirmButtonText="Yes, delete"
            />
        </>
    );
};
