import React, { useEffect, useState } from "react";
import { useQuery } from "../../store/queryContext";
import { Chart } from "chart.js";
import { GraphDataReceiver } from "../services/graphDataReceiver";
import "./plugins/chartjs-plugin-watermark";

export const CombinedChart = () => {
    const { query } = useQuery();
    const [chart, setChart] = useState<Chart>();

    useEffect(() => {
        if (chart) {
            chart.destroy();
        }
        var context = (document.getElementById("combinedChart") as HTMLCanvasElement).getContext("2d");
        var chartData = GraphDataReceiver.getCombinedChartData(
            query.chartData?.attributeNames.second(),
            query.chartData?.attributeNames.first(),
            query.chartData?.labels,
            query.chartData?.data.second(),
            GraphDataReceiver.getChartData(query)?.first(),
            query.isSAAR,
            query.title
        );
        if (context && chartData) setChart(new Chart(context, chartData));
    }, [query.chartData]);

    return <canvas id="combinedChart" />;
};
