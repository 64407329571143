import React from "react";
import { MDBListGroup, MDBListGroupItem, MDBModal, MDBModalBody, MDBModalFooter } from "mdbreact";
import { useQuery } from "../store/queryContext";
import { InputCell, DefaultButton, useGrid } from "../../UI";
import { Styled } from "./styled";
import { ButtonStyled } from "../../UI/buttons/buttonStyled";

interface IProps {
    value: string;
    getValue: (value: string) => void;
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export const QueryNameModal = (props: IProps) => {
    const { userQueries, query } = useQuery();
    const { setIsSave } = useGrid();

    const handleConfirm = () => {
        setIsSave(true);
        props.onConfirm();
    };

    return (
        <>
            <MDBModal
                isOpen={props.isOpen}
                toggle={() => {}}
                noClickableBodyWithoutBackdrop={false}
                inline={false}
                overflowScroll={false}
                centered
            >
                <MDBModalBody>
                    <div className="d-flex mb-2 align-items-center">
                    <label className="QN-label" >Query Name: </label>
                    <div className="QueryNameinput">
                    <InputCell
                        className="font-weight-bold"
                        defaultValue={props.value}
                        value={props.value}
                        getValue={props.getValue}
                        label="Query name"
                    />
                    </div>

                    </div>
                
                    <h5>My Queries</h5>
                    <MDBListGroup style={Styled.UserQueries}>
                        {userQueries
                            ?.filter(q => q.queryId !== query.queryId)
                            .map(query => (
                                <MDBListGroupItem key={query.queryId} className="border-0 py-0">
                                    {query.queryName}
                                </MDBListGroupItem>
                            ))}
                    </MDBListGroup>
                </MDBModalBody>
                <MDBModalFooter className="d-flex justify-content-around">
                    <DefaultButton
                        style={{ ...ButtonStyled.PrimaryButton, ...Styled.Button }}
                        onClick={handleConfirm}
                        text="SAVE QUERY"
                    />
                    <DefaultButton className="btn-light" style={Styled.Button} onClick={props.onCancel} text="CANCEL" />
                </MDBModalFooter>
            </MDBModal>
        </>
    );
};
