import { zIndex } from "../../../UI";

const Header = {
    paddingLeft: "16px",
    zIndex: zIndex.Header,
    background:
        "linear-gradient(122.28deg, #28A5AD 2.45%, rgba(255, 255, 255, 0) 73.11%), #01688F"
};

const HeaderText = {
    marginLeft: "40px",
    color: "white"
};

const HeaderMenuContainer = {
    marginRight: "10px"
};

const DefaultFormHeader = {
    color: "white",
    background:
        "linear-gradient(122.28deg, #28A5AD 2.45%, rgba(255, 255, 255, 0) 73.11%), #01688F",
    fontSize: "15px"
};

const DefaultPageHeader = {
    marginLeft: "29px",
    color: "white",
    padding: "11px"
};

const DefaultPageHeaderLink = {
    color: "white"
};

export const Styled = {
    Header,
    DefaultFormHeader,
    HeaderText,
    DefaultPageHeader,
    HeaderMenuContainer,
    DefaultPageHeaderLink
};
