import React, { useState } from "react";
import { EmailField, PasswordField, DefaultFormButton, Preloader, UserNameField, CustomInputField } from "../UI";
import { RolesSelect } from "./rolesSelect";
import { ISignUp } from "../../typings";
import { signUpActions } from "./actions/signUpAction";
import { userWithSameEmailAlreadyExists, userWithSameNameAlreadyExists } from "./messages/serverResponse";
import { Styled } from "./styled/styled";
import { useRedirect } from "../../common/hooks/useRedirect";
import { Routes } from "../../common";

interface IProps {
    user?: ISignUp;
    isUpdate?: boolean;
}

export const SignUpForm = (props: IProps) => {
    const [usersData, setUsersData] = useState<ISignUp | undefined>(props.user);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isEmailUsed, setEmailIsUsed] = useState(false);
    const [isUserNameExist, setIsUserNameExist] = useState(false);
    const [redirectAfterEdit] = useRedirect(props.isUpdate !== undefined, "user account", Routes.UsersManagement);
    const isFormDataCorrect = () => {
        // Added this to find Password Input and remove error/invalid class if found.
        // This is in a case where 'Update password' component is rendered with this on same page
        // and Password field is not getting 'IsSubmitted' true on that component. 
        // To ignore Password component 'IsSubmitted'
        const pwdIgnoreSubmit= document.querySelector("[type=password]");
        if (pwdIgnoreSubmit?.classList.contains("is-invalid")){
            pwdIgnoreSubmit.classList.remove("is-invalid");
        }
        else if(pwdIgnoreSubmit?.classList.contains("error")){
            pwdIgnoreSubmit.classList.remove("error");
        }
        // till here
        const unsubmittedFormErrors = document.getElementsByClassName("error").length;
        const submittedFormErrors = document.getElementsByClassName("is-invalid").length;
        return !(unsubmittedFormErrors || submittedFormErrors);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormSubmitted(true);
        if (isFormDataCorrect()) {
            const response = props.isUpdate
                ? await signUpActions.updateUser(usersData)
                : await signUpActions.createUser(usersData);
            if (response) {
                setEmailIsUsed(response.includes(userWithSameEmailAlreadyExists));
                setIsUserNameExist(response.includes(userWithSameNameAlreadyExists));
            } else redirectAfterEdit();
        }
    };

    return (
        <form className="w-75" onSubmit={onSubmit} noValidate style={Styled.FormStyle}>
            <UserNameField
                isSubmitted={formSubmitted}
                value={usersData?.userName}
                isUserNameExist={isUserNameExist}
                getValue={(value: any) =>
                    setUsersData({
                        ...usersData,
                        userName: value.toString()
                    })
                }
            />
            <CustomInputField
                placeholder="Full Name"
                label="Full Name"
                icon="user"
                isRequired
                isSubmitted={formSubmitted}
                value={usersData?.fullName}
                getValue={(value: any) =>
                    setUsersData({
                        ...usersData,
                        fullName: value.toString()
                    })
                }
                maxLength={40}
            />
            <CustomInputField
                placeholder="Description"
                label="Description"
                type="textarea"
                icon="user"
                isRequired
                isSubmitted={formSubmitted}
                value={usersData?.description}
                getValue={(value: any) =>
                    setUsersData({
                        ...usersData,
                        description: value.toString()
                    })
                }
                maxLength={80}
            />
            <EmailField
                isSubmited={formSubmitted}
                isEmailUsed={isEmailUsed}
                value={usersData?.email}
                getValue={value => {
                    setEmailIsUsed(false);
                    setUsersData({
                        ...usersData,
                        email: value.toString()
                    });
                }}
            />
            {!props.isUpdate && (
                <PasswordField
                    label="Password"
                    isIconPaddingNeeded
                    isSubmitted={formSubmitted}
                    value={usersData?.password}
                    getValue={value =>
                        setUsersData({
                            ...usersData,
                            password: value.toString()
                        })
                    }
                />
            )}
            <RolesSelect
                getValue={values =>
                    setUsersData({
                        ...usersData,
                        roleIds: values.map(v => +v)
                    })
                }
                selectedRoles={usersData?.roleIds}
                isSubmitted={formSubmitted}
            />
            <Preloader />
            <DefaultFormButton text={`${props.isUpdate ? "UPDATE" : "CREATE"} USER`} />
        </form>
    );
};
