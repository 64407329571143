import React from "react";
import { useHistory } from "react-router-dom";
import { MDBContainer } from "mdbreact";
import { RoleManagementForm } from "../components/roleManagement";
import { RoleProvider } from "../components/roleManagement/store/roleProvider";

interface IState {
    roleId: number;
}

export const EditRolePage = () => {
    const history = useHistory();
    return (
        <RoleProvider roleId={(history.location.state as IState)?.roleId}>
            <MDBContainer>
                <RoleManagementForm />
            </MDBContainer>
        </RoleProvider>
    );
};
