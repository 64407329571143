import React, { useState, useEffect } from "react";
import { Styled } from "./styles/styled";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "..";
import { CustomInputField, QueryCreationSteps } from "../../UI";
import { QueryErrorService } from "../common";

export const ReportTitle = () => {
    const { query, queryMode, isSubmitted, setErrors, errors, setIsSubmitted } = useQuery();
    const [title, setTitle] = useState(query.title);
    useEffect(() => {
        if (query.title !== title) setTitle(query.title);
    }, [query.title]);
    return (
        <div style={Styled.ReportTitle}>
            <h5 style={Styled.Header}>Report Title</h5>
            <div style={Styled.InputWrapper}>
                <CustomInputField
                    isSubmitted={isSubmitted}
                    isDisabled={queryMode === QueryMode.View}
                    label="Report Title"
                    maxLength={254}
                    getError={(errorMessage: string | undefined) => {
                        if (errorMessage && !errors.some(e => e.message === errorMessage)) {
                            QueryErrorService.pushError(errors, QueryCreationSteps.PreviewAndExport, errorMessage);
                            setErrors([...errors]);
                        }
                    }}
                    getValue={(value: any) => {
                        if (!value.length) setIsSubmitted(true);
                        query.title = value.toString();
                        setTitle(query.title);
                        if (errors.some(e => e.step === QueryCreationSteps.PreviewAndExport))
                            setErrors([...errors.filter(e => e.step !== QueryCreationSteps.PreviewAndExport)]);
                    }}
                    value={title}
                />
            </div>
        </div>
    );
};
