import React, { useState } from "react";
import { FileApi } from "../../../../core/api";
import { useDropzone } from "react-dropzone";
import { Styled } from "./styles/styled";
import { useFiles } from "../..";
import { AttachmentType } from "../../../../typings";
import { Messages } from "./messages";
import { Constants } from "./constants";
import { FileService } from "../../../../common";
import { SaveOverExistedFileModal } from "../../";

export const UploadFileForm = () => {
    const { setFiles, attachmentType, files } = useFiles();
    const [error, setError] = useState("");
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [uploadFile, setUploadFile] = useState<File | null>(null);

    const sendFile = async (file: File, isForce?: boolean) => {
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("attachmentType", AttachmentType.Documentation.toString());
            await FileApi.create(formData, isForce);
            setFiles(await FileApi.getAll(attachmentType));
            setError("");
        }
    };

    const onDrop = (inputFiles: Array<File>) => {
        const file = inputFiles.first();
        if (FileService.convertToMB(file.size) > Constants.MaxFileSize) {
            setError(Messages.ErrorFileSize);
            return;
        }
        if (!FileService.isValidFormat(file.name)) {
            setError(Messages.ErrorFileFormat);
            return;
        }
        if (files.find(f => f.name === FileService.getFileName(file.name))) {
            setUploadFile(file);
            setIsModalOpen(true);
        } else {
            sendFile(file);
        }
    };

    const onModalConfirm = () => {
        sendFile(uploadFile as File, true);
        setIsModalOpen(false);
        setUploadFile(null);
    };

    const onModalCancel = () => {
        setIsModalOpen(false);
        setUploadFile(null);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    return (
        <>
            <div
                {...getRootProps()}
                className="d-flex justify-content-center"
                style={isDragActive ? Styled.UploadFileFormActive : Styled.UploadFileForm}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p style={Styled.UploadFileFormText}>Drop the file here ...</p>
                ) : (
                    <p style={Styled.UploadFileFormText}>Drag 'n' drop file here, or click to select file</p>
                )}
            </div>
            <p className="text-danger">{error}</p>
            <SaveOverExistedFileModal isOpen={isModalOpen} onConfirm={onModalConfirm} onCancel={onModalCancel} />
        </>
    );
};
