import React, { PropsWithChildren, useEffect, useState } from "react";
import { usersContext } from "./usersContext";
import { IUserManagement } from "../../../typings";
import { UsersApi } from "../../../core/api";

export const UsersProvider = (props: PropsWithChildren<{}>) => {
    const [users, setUsers] = useState<Array<IUserManagement>>([]);

    const value: any = {
        users,
        setUsers
    };

    useEffect(() => {
        (async function loadUsers(): Promise<void> {
            setUsers(await UsersApi.getUsers());
        })();
    }, []);

    return (
        <usersContext.Provider value={value}>
            {props.children}
        </usersContext.Provider>
    );
};
