import React from "react";
import { MDBTableBody } from "mdbreact";
import { IContact } from "../../typings";
import { useContacts } from ".";
import { ContactGridCell } from "./contactGridCell";
import { DeleteContactAction } from "./deleteContactAction";
import { useUser } from "../../stores";
import { GridRow } from "../UI";

export const ContactsManagementGridBody = () => {
    const { contacts } = useContacts();
    const { user } = useUser();

    return (
        <MDBTableBody>
            {contacts.map((contact: IContact, index: number) => (
                <GridRow key={index + new Date().getMilliseconds()} index={index}>
                    <ContactGridCell contact={contact} label="Contact" propertyName="contactName" />
                    <ContactGridCell contact={contact} isEmail label="Email" propertyName="email" />
                    <ContactGridCell contact={contact} label="Notes" propertyName="notes" />
                    {user.isAdmin && (
                        <td className="d-flex justify-content-center">
                            <DeleteContactAction index={index} />
                        </td>
                    )}
                </GridRow>
            ))}
        </MDBTableBody>
    );
};
