import { MDBInput } from "mdbreact";
import React, { useState, useEffect } from "react";
import { useQuery } from "../store/queryContext";

export const PivotCheckbox = () => {
    const { query, setQuery } = useQuery();
    const [isPivod, setIsPivod] = useState(query.isPivot);
    useEffect(() => {
        if (query.isPivot !== isPivod) setIsPivod(query.isPivot);
    }, [query.isPivot]);

    return (
        <MDBInput
            type="checkbox"
            id="checkboxPivot"
            label="Pivot"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setQuery({ ...query, isPivot: e.currentTarget.checked });
                setIsPivod(e.currentTarget.checked);
            }}
            checked={isPivod}
        />
    );
};
