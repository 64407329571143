import React from "react";
import { MDBRow } from "mdbreact";
import { dateRanges, IDataSet } from "../../../../typings";
import { DefaultButton } from "../../../UI";
import { useQuery } from "../../store/queryContext";
import { Styled } from "../styled";
import { DatesService } from "./services/datesService";
import { QueryMode } from "../..";
import { CalculationService } from "../calculations/calculationService";
import { CalculationNames } from "../../common/calculationNames";

interface IProps {
    dataSet: IDataSet;
}

export const AddDateRangeButton = (props: IProps) => {
    const { query, setQuery, queryMode } = useQuery();

    const addDateRange = async () => {
        const dataSet = query.dataSets.find(d => d.dataSetName === props.dataSet.dataSetName);
        const dateRange = await DatesService.getDateRange(props.dataSet, query.country);
        setSpecificCalculationsStatus(dateRange);
        dataSet?.dateRanges?.push(dateRange);
        await CalculationService.updateDateRangeCalculations(dateRange, dataSet?.dataSetName);
        setQuery({ ...query, dataSets: query.dataSets });
    };

    const setSpecificCalculationsStatus = (dateRange: dateRanges) => {
        CalculationService.updateSpecificCalculation(dateRange, query.isFEIA, CalculationNames.FEIA);
        CalculationService.updateSpecificCalculation(dateRange, query.isFEIU, CalculationNames.FEIU);
    };

    return (
        <>
            {!query.isCorrelation && (
                <MDBRow className="mt-1 ml-1">
                    <DefaultButton
                        text="Add Date Range"
                        onClick={addDateRange}
                        style={Styled.AddRangeButton}
                        className="cursor-pointer"
                        icon="fas fa-plus-circle"
                        iconStyle={Styled.AddRangeIcon}
                        disabled={queryMode === QueryMode.View}
                    />
                </MDBRow>
            )}
        </>
    );
};
