import React, { PropsWithChildren, useEffect, useState } from "react";
import { IRole } from "../../../typings";
import { RolesApi } from "../../../core/api";
import { roleContext } from "./roleContext";
import { ISelectOption } from "../../queries/interfaces/ISelectOption";

interface IProps {
    roleId?: number;
    isLoadRolesNeeded?: boolean;
}

export const RoleProvider = (props: PropsWithChildren<IProps>) => {
    const [roles, setRoles] = useState<Array<IRole>>([]);
    const [role, setRole] = useState<IRole>({
        rolePermissions: {},
        entityDatasets: []
    });
    const [countries, setCountries] = useState<Array<ISelectOption>>([]);
    const [regions, setRegions] = useState<Array<ISelectOption>>([]);

    const value: any = {
        roles,
        setRoles,
        role,
        setRole,
        countries,
        setCountries,
        regions,
        setRegions
    };

    useEffect(() => {
        (async function loadRoles(): Promise<void> {
            if (props.isLoadRolesNeeded) setRoles(await RolesApi.getAll());
        })();
    }, []);

    useEffect(() => {
        (async function loadRole(): Promise<void> {
            if (props.roleId) setRole(await RolesApi.get(props.roleId));
        })();
    }, []);

    return <roleContext.Provider value={value}>{props.children}</roleContext.Provider>;
};
