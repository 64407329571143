import React from "react";
import { MDBTableHead } from "mdbreact";
import { Styled } from "./styled";

interface IProps {
    headers: string[];
}

export const GridHeader = (props: IProps) => {
    return (
        <MDBTableHead>
            <tr>
                {props.headers.map((header, index) => (
                    <th style={Styled.GridHeader} key={index}>
                        {header}
                    </th>
                ))}
            </tr>
        </MDBTableHead>
    );
};
