import React from "react";
import { ContactUsTable } from "../components/contactUs";
import { Footer } from "../components/footer";
import { DefaultPageHeader } from "../components/baseNavigation";
import { DefaultParagraph } from "../components/UI";
import { MDBContainer } from "mdbreact";

export const ContactUsPageUnauthorized = () => (
    <>
        <DefaultPageHeader />
        <MDBContainer>
            <DefaultParagraph text="Contacts" />
            <ContactUsTable />
        </MDBContainer>
        <Footer />
    </>
);
