import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUser } from "../../stores";

interface IProps {
    redirectTo: string;
    isAccessAllow: boolean | undefined;
    component: any;
    path?: string;
}

export const BasePrivateRoute = (props: IProps) => {
    const { user } = useUser();

    const routeComponent = () => {
        if (user.isLoaded !== true) {
            return;
        }
        return props.isAccessAllow ? (
            React.createElement(props.component, props)
        ) : (
            <Redirect to={{ pathname: props.redirectTo }} />
        );
    };

    return <Route exact path={props.path} render={routeComponent} />;
};
