import React from "react";
import { Styled } from "./styled";
import { useQuery } from "../store/queryContext";
import { ChartSavingService } from "./services/chartSavingService";

export const DownloadButton = () => {
    const { query } = useQuery();
    const titleName = `${query.title} chart.png`;
    const downloadAction = () => {
        const chartCanvas = document.getElementsByClassName("chartjs-render-monitor").item(0) as HTMLCanvasElement;
        if (navigator.msSaveBlob) {
            ChartSavingService.getCanvasBlob(ChartSavingService.changeCanvasBackground(chartCanvas)).then(
                function(blob) {
                    navigator.msSaveBlob(blob, titleName);
                },
                function(err) {
                    console.log(err);
                    return;
                }
            );
        } else {
            const downloadButton = document.getElementById("download") as HTMLAnchorElement;
            downloadButton.href = ChartSavingService.getCanvasData(chartCanvas);
        }
    };
    return (
        <a
            style={Styled.ActionButton(false)}
            className="cursor-pointer"
            onClick={downloadAction}
            download={titleName}
            id="download"
        >
            Save image
        </a>
    );
};
