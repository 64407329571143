import React from "react";
import { IUsersContext } from "./IUsersContext";

export const usersContext = React.createContext<IUsersContext>({ users: [], setUsers: () => {} });

export const useUsers = () => {
    const { users, setUsers } = React.useContext(usersContext);
    if (!users) {
        throw new Error("Users context has not been initialized.");
    }
    return { users, setUsers };
};
