import React from "react";
import { ResetPasswordForm } from "../components/passwordManagement";
import { DefaultPageHeader } from "../components/baseNavigation";
import { Footer } from "../components/footer";

export const ResetPasswordPage = () => (
    <div>
        <DefaultPageHeader />
        <ResetPasswordForm />
        <Footer />
    </div>
);
