import React from "react";
import { useContacts } from ".";
import { GridDeleteAction, Notifications, NotificationMessages } from "../UI";
import { ContactApi } from "../../core/api";
// @ts-ignore
import { store } from "react-notifications-component";

interface IProps {
    index: number;
}

export const DeleteContactAction = (props: IProps) => {
    const { contacts, setContacts } = useContacts();

    const Delete = async () => {
        const contact = contacts[props.index];
        const newArray = [...contacts];
        newArray.splice(props.index, 1);
        setContacts(newArray);
        if (contact.contactId) {
            await ContactApi.delete(contact.contactId);
            store.addNotification(Notifications.Successfull(NotificationMessages.ContactHasBeenSuccessfullyDeleted));
        }
    };

    return <GridDeleteAction onDelete={Delete} />;
};
