import React from "react";
import { IFileContext } from "./IFileContext";
import { AttachmentType } from "../../../../typings";

export const filesContext = React.createContext<IFileContext>({
    files: [],
    attachmentType: AttachmentType.Documentation,
    setFiles: () => {}
});

export const useFiles = () => {
    const context = React.useContext(filesContext);
    if (!context) {
        throw new Error("Files context has not been initialized.");
    }
    return context;
};
