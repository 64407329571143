import { Messages } from "..";
import { QueryCreationSteps } from "../../../UI";
import { IQueryError } from "../../interfaces/IQueryError";

const getError = (step: QueryCreationSteps, message: string, index?: number) => {
    return { step, message, index } as IQueryError;
}

const pushError = (errors: Array<IQueryError>, step: QueryCreationSteps, message: string, index?: number) => {
    const error = getError(step, message, index);
    errors.push(error);
}

const unshiftError = (errors: Array<IQueryError>, step: QueryCreationSteps, message: string, index?: number) => {
    const error = getError(step, message, index);
    errors.unshift(error);
}

const filterStepErrors = (errors: Array<IQueryError>) => [
    ...errors.filter(
        e => e.step !== QueryCreationSteps.SelectMarketAndDataset && e.step !== QueryCreationSteps.ChooseDatesAndCalculations
    )
];

const filterMessageErrors = (errors: Array<IQueryError>) =>
    errors.filter(e => e.message !== Messages.PleaseChooseADataset && e.message !== Messages.PleaseFillInReportTitleField);

export const QueryErrorService = {
    getError,
    pushError,
    unshiftError,
    filterStepErrors,
    filterMessageErrors
}