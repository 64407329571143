import React, { useState, useEffect } from "react";
import { useQuery } from "../../store/queryContext";
import { Bar } from "react-chartjs-2";
import { GraphDataReceiver } from "../services/graphDataReceiver";
import { DataSetService } from "../../common";
import "./plugins/chartjs-plugin-watermark";

export const BarChart = () => {
    const { query } = useQuery();
    const [data, setData] = useState<any>();
    const [isChartRemountRequire, setIsChartRemountRequire] = useState<boolean>(true);

    useEffect(() => {
        const chartData = GraphDataReceiver.getBarChartData(
            GraphDataReceiver.getChartData(query),
            query.chartData?.labels,
            query.chartData?.attributeNames,
            DataSetService.isRate(query.dataSets.find(d => d.isSelected)?.dataSetName)
        );
        setData(chartData);
        setIsChartRemountRequire(true);
    }, [query]);

    useEffect(() => {
        if (isChartRemountRequire) {
            setIsChartRemountRequire(false);
        }
    }, [isChartRemountRequire]);

    return isChartRemountRequire ? (
        <></>
    ) : (
        <Bar data={data} options={GraphDataReceiver.getBarChartOptions(query.title)} />
    );
};
