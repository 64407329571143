import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../common";
import { IconWithTooltip } from "../../UI";
import { Styled } from "./styled";

interface IProps {
    roleId: number | undefined;
}

export const EditRoleButton = (props: IProps) => {
    const history = useHistory();
    return (
        <IconWithTooltip
            style={Styled.ControlButtons}
            tooltip="Edit"
            icon="pencil-alt"
            onClick={() =>
                history.push(Routes.EditRole, {
                    roleId: props.roleId
                })
            }
        />
    );
};
