import { CSSProperties } from "react";

const Form = {
    margin: "auto",
    width: "60%"
};
const TableColumn = {
    wordBreak: "break-all"
} as CSSProperties;

export const Styled = {
    Form,
    TableColumn
};
