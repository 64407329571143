import React from "react";
import { IFile, AttachmentType } from "../../typings";
import { IconWithTooltip, useFiles } from "../UI";
import { FileApi } from "../../core/api";
import { Styled } from "./style/styled";

interface IProps {
    file: IFile;
    currentPosition: number;
    maxPosition: number;
}

export const DocumentationOrderCell = (props: IProps) => {
    const { setFiles } = useFiles();

    const update = async (position: number) => {
        await FileApi.updateFilePosition(props.file.id, position);
        setFiles(await FileApi.getAll(AttachmentType.Documentation));
    };

    return (
        <div className="d-flex flex-column align-items-center">
            <div className={props.currentPosition === 0 ? "invisible" : undefined}>
                <IconWithTooltip
                    icon="caret-up"
                    tooltip="Up"
                    onClick={() => update(props.currentPosition - 1)}
                    style={Styled.Arrow}
                />
            </div>
            <div className={props.currentPosition === props.maxPosition ? "invisible" : undefined}>
                <IconWithTooltip
                    icon="caret-down"
                    tooltip="Down"
                    onClick={() => update(props.currentPosition + 1)}
                    style={Styled.Arrow}
                />
            </div>
        </div>
    );
};
