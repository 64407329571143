import React from "react";
import { useHistory } from "react-router-dom";
import { SignUpForm } from "../components/signUp";
import { IUserManagement } from "../typings";
import { MDBContainer } from "mdbreact";
import { Routes } from "../common";
import { UpdatePasswordForm } from "../components/usersManagement/updatePasswordForm";

interface IState {
    user: IUserManagement;
}

export const UpdateUserPage = () => {
    const history = useHistory();
    const user = (history.location.state as IState)?.user;
    if (!user) {
        history.push(Routes.UsersManagement);
    }
    return (
        <MDBContainer>
            <SignUpForm user={user} isUpdate />
            <UpdatePasswordForm user={user} isUpdate></UpdatePasswordForm>
        </MDBContainer>
    );
};
