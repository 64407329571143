import React from "react";
import { IQuery } from "../interfaces/IQuery";
// @ts-ignore
import { store } from "react-notifications-component";
import { Notifications, NotificationMessages, IconWithTooltip, DefaultButton, ButtonStyled } from "../../UI";
import { Constants } from "../common/constants";
import { localStorageService } from "../../../common";
import { UserActivityApi } from "../../../core/api/userActivityApi";
import { UserActivityActionNames } from "../../usersManagement";
import { useUser } from "../../../stores";

interface IProps {
    query: (number | undefined)[];
    tooltip: string;
}

export const CopyButton = (props: IProps) => {
    const { user } = useUser();
    const copy = async () => {
        if(props.query && props.query.length > 0){
        if (user.isAdmin) await UserActivityApi.create(UserActivityActionNames.CopyUserQuery);
        store.addNotification(Notifications.Successfull(NotificationMessages.TheQueryHasBeenSuccessfullyCopied));
        localStorageService.setValue(Constants.QueryLocalStorageKey, props.query);
        }
        else{
            store.addNotification(Notifications.Warning(NotificationMessages.PleaseSelectQueryToCopy));
        }
    };

    return <DefaultButton
    className="ml-0"
    onClick={copy}
    icon="copy"
    style={ButtonStyled.SecondaryButton}
    size="lg"
    text="Copy"
/>
};
