const FixChoiseBaseButton = {
    background: "#EDF1F1",
    borderRadius: "2px",
    color: "#626262",
    fontSize: "12px",
    width: "155px",
    height: "38px",
    textTransform: "none"
};

const FixChoiseSelectedButton = {
    ...FixChoiseBaseButton,
    background: "#108393",
    color: "#FFFFFF"
};

const Select = {
    marginBottom: "0px",
    width: "280px"
};

const SelectDisabled = {
    ...Select,
    pointerEvents: "none",
    opacity: "0.4"
};

export const Styled = {
    FixChoiseSelectedButton,
    FixChoiseBaseButton,
    Select,
    SelectDisabled
};
