import { httpGet } from "./request/requestApi";
import { IRegion } from "../../components/queries";

const controllerEndPoint = "region";

export class RegionApi {
    static async getAll(): Promise<Array<IRegion>> {
        return await httpGet(`${controllerEndPoint}/get-all`);
    }
}
