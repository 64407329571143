import { Constants } from "../../common/constants";
import { IQuery } from "../../interfaces/IQuery";
import { IChartColorSchema } from "../types/IChartColorSchema";
import { IChartData } from "../types/IChartData";
import { ColorPalette } from "../colorPalette";
import { DataSetService } from "../../common";

const getLineChartData = (
    dataSets: Array<Array<number>> | undefined,
    attributeNames: Array<string> | undefined,
    labels: Array<string> | undefined,
) => {
    const colorSchema = generateColorSchema(attributeNames);
    return {
        labels: labels,
        datasets: attributeNames?.map((d, i) => ({
            label: d,
            fill: false,
            lineTension: 0.2,
            backgroundColor: getColor(i),
            borderColor: colorSchema[d],
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: colorSchema[d],
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(0, 0, 0)",
            pointHoverBorderColor: "rgba(220, 220, 220, 1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: dataSets ? dataSets[i] : undefined

        }))
    }
};

const getBarMaxRoundValue = (length: number) => {
    return length > 2 ? Math.pow(10, length - 2) : 1;
}

const getCombinedChartProperies = (barData: Array<number>, lineData: Array<number>, isSaar: boolean | undefined) => {
    if (!isSaar) {
        return [];
    }
    const maxbar = Math.max(...barData);
    const barMaxRoundValue = getBarMaxRoundValue(maxbar.toString().length);
    let roundedMaxBar = Math.ceil(maxbar / barMaxRoundValue + 1) * barMaxRoundValue;
    let roundedMaxLine = Math.ceil(Math.max(...lineData) + 1) * Constants.CombinedChartLineMaxRoundValue;
    roundedMaxBar = Math.max(roundedMaxBar, roundedMaxLine);
    roundedMaxLine = roundedMaxBar / Constants.CombinedChartLineMaxRoundValue;
    return [Constants.CombinedChartBarStepNumber, Constants.CombinedChartLineStepNumber, roundedMaxBar, roundedMaxLine];
}

const getCombinedChartData = (
    barLabel: string | undefined,
    lineLabel: string | undefined,
    labels: Array<string> | undefined,
    barData: Array<number> | undefined,
    lineData: Array<number> | undefined,
    isSaar: boolean | undefined,
    label: string | undefined
) => {
    if (!barData) return;
    if (!lineData) return;
    const [barStep, lineStep, barMax, lineMax] = getCombinedChartProperies(barData, lineData, isSaar);
    return {
        type: "bar",
        data: {
            datasets: [
                {
                    label: barLabel,
                    data: barData,
                    yAxisID: "bar",
                    backgroundColor: barData.map(() => ColorPalette[0]),
                    borderColor: barData.map(() => ColorPalette[0]),
                    order: 1
                },
                {
                    label: lineLabel,
                    data: lineData,
                    yAxisID: "line",
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    borderColor: "rgba(0, 0, 0, 1)",
                    pointBackgroundColor: "rgba(0, 0, 0, 1)",
                    type: "line",
                    order: 0
                }
            ],
            labels: labels
        },
        options: {
            title: getTitleOption(label),
            legend: {
                reverse: true
            },
            scales: {
                yAxes: [
                    {
                        id: "bar",
                        scaleLabel: {
                            display: true,
                            labelString: barLabel,
                            fontSize: 20
                        },
                        position: "left",
                        ticks: {
                            beginAtZero: true,
                            stepSize: barStep,
                            max: barMax,
                            callback: (value: number) => value.formatDecimal()
                        }
                    },
                    {
                        id: "line",
                        scaleLabel: {
                            display: true,
                            labelString: lineLabel,
                            fontSize: 20
                        },
                        position: "right",
                        ticks: {
                            beginAtZero: true,
                            stepSize: lineStep,
                            max: lineMax,
                            callback: (value: number) => value.toFixed(1),
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            padding: 6
                        }
                    }
                ]
            },
            watermark: {
                x: 140,
                y: 20
            },
            ...formattedTooltips
        }
    };
};

const getCombinationChartData = (
    barLabel: string | undefined,
    labels: Array<string> | undefined,
    barData: Array<number> | undefined,
    lineData: Array<number> | undefined,
    label: string | undefined
) => {
    if (!barData) return;
    return {
        type: "bar",
        data: {
            datasets: [
                {
                    label: barLabel,
                    data: barData,
                    yAxisID: "bar",
                    backgroundColor: barData.map(() => ColorPalette[0]),
                    borderColor: barData.map(() => ColorPalette[0]),
                    order: 1
                },
                {
                    label: "YoY % Change",
                    data: lineData,
                    yAxisID: "line",
                    backgroundColor: "rgba(0, 0, 0, 1)",
                    borderColor: "rgba(0, 0, 0, 1)",
                    type: "line",
                    fill: false,
                    order: 0
                }
            ],
            labels: labels
        },
        options: {
            title: getTitleOption(label),
            legend: {
                reverse: true
            },
            scales: {
                yAxes: [
                    {
                        id: "bar",
                        scaleLabel: {
                            display: true,
                            labelString: barLabel,
                            fontSize: 20
                        },
                        position: "left",
                        ticks: {
                            beginAtZero: true,
                            callback: (value: number) => value.formatDecimal()
                        }
                    },
                    {
                        id: "line",
                        scaleLabel: {
                            display: true,
                            labelString: "YoY % Change",
                            fontSize: 20
                        },
                        gridLines: {
                            display: false
                        },
                        position: "right",
                        ticks: {
                            beginAtZero: true,
                            callback: (value: number) => value.formatDecimal() + "%"
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            padding: 6
                        }
                    }
                ]
            },
            watermark: {
                x: 150,
                y: 20
            },
            ...formattedTooltips
        }
    };
};

const getBarChartData = (
    data: Array<Array<number>> | undefined,
    labels: Array<string> | undefined,
    attributeNames: Array<string> | undefined,
    isRate: boolean,
) => (isRate
    ? getRateBarChartData(data, labels, attributeNames)
    : getCommonBarChartData(data, labels, attributeNames));

const getCommonBarChartData = (
    data: Array<Array<number>> | undefined,
    labels: Array<string> | undefined,
    attributeNames: Array<string> | undefined,
) => {
    const colorSchema = generateColorSchema(attributeNames);
    return {
        labels: labels,
        datasets: attributeNames?.map((a, i) => ({
            label: a,
            data: data ? data[i] : undefined,
            backgroundColor: colorSchema[a],
            borderWidth: 2,
            borderColor: colorSchema[a],
        }))
    }
};

const getRateBarChartData = (
    data: Array<Array<number>> | undefined,
    labels: Array<string> | undefined,
    attributeNames: Array<string> | undefined,
) => {
    const colorSchema = generateColorSchema(labels);
    return {
        labels: attributeNames,
        datasets: labels?.map((a, i) => ({
            label: a,
            data: data ? data.map(d => d[i]) : undefined,
            backgroundColor: colorSchema[a],
            borderWidth: 2,
            borderColor: colorSchema[a],
        }))
    }
};

const getBarChartOptions = (title: string | undefined) => ({
    responsive: true,
    title: getTitleOption(title),
    scales: {
        xAxes: [
            {
                barPercentage: 1,
                gridLines: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)"
                },
                scaleLabel: {
                    display: true,
                    padding: 6
                }
            }
        ],
        yAxes: [
            {
                gridLines: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)"
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value: number) => value.formatDecimal()
                }
            }
        ]
    },
    watermark: {
        x: 80,
        y: 20
    },
    ...formattedTooltips
});

const getLineChartOptions = (title: string | undefined) => ({
    responsive: true,
    title: getTitleOption(title),
    scales: {
        yAxes: [
            {
                ticks: {
                    callback: (value: number) => value.formatDecimal()
                }
            }
        ],
        xAxes: [
            {
                scaleLabel: {
                    display: true,
                    padding: 6
                }
            }
        ]
    },
    watermark: {
        x: 90,
        y: 20
    },
    ...GraphDataReceiver.formattedTooltips
});

const getScatterChartData = (
    firstDataSetData: Array<number> | undefined,
    secondDataSetData: Array<Number> | undefined,
    trendCoeffA: number | undefined,
    trendCoeffB: number | undefined
) => {
    if (!firstDataSetData || !secondDataSetData || !trendCoeffA || !trendCoeffB) return;
    const minX = firstDataSetData.min();
    const maxX = firstDataSetData.max();
    return {
        labels: ["Scatter"],
        datasets: [
            {
                borderColor: ColorPalette[0],
                backgroundColor: ColorPalette[0],
                label: "",
                data: firstDataSetData.map((v, i) => ({ x: v, y: secondDataSetData[i] }))
            },
            {
                label: "Trend",
                data: [
                    {
                        x: minX,
                        y: trendCoeffA * firstDataSetData.min() + trendCoeffB
                    },
                    {
                        x: maxX,
                        y: trendCoeffA * firstDataSetData.max() + trendCoeffB
                    }
                ],
                pointRadius: 0,
                pointHoverRadius: 0,
                borderWidth: 1,
                borderDash: [3, 3],
                backgroundColor: "rgba(255, 255, 255, 0)",
                borderColor: ColorPalette[1],
                type: "line"
            }
        ]
    };
};

const getScatterChartOptions = (
    firstDataSetLabel: string | undefined,
    secondDataSetLabel: string | undefined,
    title: string | undefined
) => ({
    title: getTitleOption(title),
    scales: {
        xAxes: [
            {
                type: "linear",
                scaleLabel: {
                    labelString: firstDataSetLabel,
                    display: true,
                    fontSize: 18
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value: number) => value.formatDecimal()
                }
            }
        ],
        yAxes: [
            {
                type: "linear",
                scaleLabel: {
                    labelString: secondDataSetLabel,
                    display: true,
                    fontSize: 18
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value: number) => value.formatDecimal()
                }
            }
        ]
    },
    watermark: {
        x: 105,
        y: 20
    },
    tooltips: {
        callbacks: {
            label: (tooltipItem: any) => `${tooltipItem.yLabel.formatDecimal()}; ${tooltipItem.xLabel.formatDecimal()}`
        }
    }
});

const getTitleOption = (title: string | undefined) => ({
    display: true,
    text: title,
    fontSize: 20
});

const generateColorSchema = (attributeNames: string[] | undefined): IChartColorSchema => {
    if (attributeNames) {
        return attributeNames.reduce(
            (accum, name, index) => ({
                ...accum,
                [name]: getColor(index),
            }),
            {} as IChartColorSchema,
        )
    }
    return {};
}

const getColorSchema = (chartData: IChartData): IChartColorSchema | undefined => {
    return chartData.datasets?.reduce(
        (accum, dataset) => ({
            ...accum,
            [dataset.label as string]: dataset.borderColor,
        }),
        {} as IChartColorSchema,
    )
}

const getColor = (index: number): string => (index < ColorPalette.length ? ColorPalette[index] : getRandomColor());

const getRandomColor = () => {
    const round = Math.round,
        random = Math.random,
        maxValue = 255;
    return `rgb(${round(random() * maxValue)},${round(random() * maxValue)},${round(random() * maxValue)})`;
};

const getChartData = (query: IQuery) =>
    query.isSAAR || DataSetService.getSelected(query.dataSets).length > 1
        ? query.chartData?.data
        :
        query.dataSets?.some(d =>
            d.dateRanges?.first().calculations?.some(c => c.calculationName?.includes("%") && c.isSelected)
        )
            ? query.chartData?.data.map(d => d.map(n => n * 100))
            : query.chartData?.data;

const formattedTooltips = {
    tooltips: {
        callbacks: {
            label: (tooltipItem: any, data: any) =>
                `${data.datasets[tooltipItem.datasetIndex].label}: ${(+tooltipItem.value).formatDecimal({
                    maximumFractionDigits: 2
                })}`
        }
    }
};

export const GraphDataReceiver = {
    getBarChartOptions,
    getBarChartData,
    getCombinedChartData,
    getCombinationChartData,
    getLineChartData,
    getLineChartOptions,
    getScatterChartData,
    getScatterChartOptions,
    getTitleOption,
    getChartData,
    getColorSchema,
    formattedTooltips
};
