import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { datePeriod, periods } from "../../../../../typings";
import { TextField } from "@material-ui/core";
import { DatesService } from "../services/datesService";
import { Styled } from "../../styled";
import { useQuery } from "../../../store/queryContext";
import { QueryMode } from "../../..";
import { DatePeriodConstants } from "../constants/datePeriodConstants";
import { QueryErrorService } from "../../../common";

interface IProps {
    setter: (period: string | undefined) => void;
    isStart?: boolean;
    period: datePeriod | undefined;
    periodType: periods;
    value: string | undefined;
    from?: number;
    to?: number;
    additionalPeriodOptions?: string[];
}

export const PeriodSelector = (props: IProps) => {
    let options = props.additionalPeriodOptions
        ? props.additionalPeriodOptions
        : [DatePeriodConstants.Current, DatePeriodConstants.Prior, DatePeriodConstants.Prior2];
    const { errors, setErrors, queryMode } = useQuery();
    options = options.concat(
        props.periodType === periods.quarter
            ? DatePeriodConstants.Quarters.slice(props.from, props.to)
            : DatePeriodConstants.Months.slice(props.from, props.to)
    );
    return (
        <Autocomplete
            key={`period-selector-${props.value}`}
            id={`period-selector-${new Date().getMilliseconds()}`}
            size="small"
            options={options}
            value={props.value}
            getOptionLabel={option => option}
            onChange={(_event: object, value: string | null) => {
                setErrors(QueryErrorService.filterStepErrors(errors));
                DatesService.setPeriod(value ?? undefined, props.setter, props.periodType, props.period, props.isStart);
            }}
            disabled={queryMode === QueryMode.View}
            style={Styled.PeriodSelector}
            renderInput={params => (
                <TextField
                    {...params}
                    label={props.periodType === periods.quarter ? "Quarter" : "Month"}
                    variant="outlined"
                />
            )}
        />
    );
};
