import React from "react";
import IdleTimer from "react-idle-timer";
// @ts-ignore
import { store } from "react-notifications-component";
import { useNotification, NotificationMessages, Notifications } from "./UI";
import { useHistory } from "react-router-dom";
import { useUser } from "../stores";
import { Routes } from "../common";
import { AccountApi } from "../core/api";
import { Constants } from "./queries/common/constants";

export const UserActivityManager = () => {
    const history = useHistory();
    const { user, setUser } = useUser();
    const { setNotificationId } = useNotification();

    const Logout = async () => {
        await AccountApi.logout();
        setUser({ isLoaded: true });
        setNotificationId(store.addNotification(Notifications.Warning(NotificationMessages.YourSessionIsExpired)));
        window.localStorage.removeItem(Constants.QueryLocalStorageKey);
        history.push(Routes.Login);
    };

    return (
        <>{user.id !== undefined && <IdleTimer element={document} onIdle={Logout} timeout={user.lockoutTimeSpan} />}</>
    );
};
