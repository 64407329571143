import React, { useEffect } from "react";
import { LineChart } from "./charts/lineChart";
import { DownloadButton } from "./downloadButton";
import { useQuery } from "../store/queryContext";
import { Styled } from "./styled";
import { MDBFormInline } from "mdbreact";
import { ChartTypeSelect } from "./chartTypeSelect";
import { chartType } from "./types/chartTypes";
import { CombinedChart } from "./charts/combinedChart";
import { BarChart } from "./charts/barChart";
import { CombinationChart } from "./charts/combinationChart";
import { Messages, DataSetService } from "../common";
import { VisualizationAvailabilityService } from "./services/visualizationAvailabilityService ";
import { RefreshVisualizationButton } from "./refreshVisualizationButton";
import { ScatterChart } from "./charts/scatterChart";

interface IProps {
    error: boolean;
    setError: (status: boolean) => void;
}

export const VisualizationChart = (props: IProps) => {
    const { query } = useQuery();
    useEffect(() => {
        if (query.chartData)
            props.setError(![...query.chartData.data]?.any() || [...query.chartData.data]?.every(d => !d.any()));
    }, [query.chartData]);
    useEffect(() => {
        if (!query.isCorrelation) {
            if (
                !VisualizationAvailabilityService.isCombinedChartAvailable(query) &&
                query.chartType === chartType.special
            )
                query.chartType = chartType.bar;
            if (
                VisualizationAvailabilityService.isCombinedChartAvailable(query) &&
                query.chartType !== chartType.special
            )
                query.chartType = chartType.special;
        }
        query.isVisualization =
            query.isVisualization &&
            VisualizationAvailabilityService.isVisualizationAvailable(
                DataSetService.getSelected(query.dataSets),
                query.isCorrelation
            );
    }, [query.dataSets]);

    const getSpecialChart = () =>
        query.isCorrelation ? (
            <ScatterChart />
        ) : VisualizationAvailabilityService.isCombinedChartAvailable(query) ? (
            <CombinedChart />
        ) : (
            <CombinationChart />
        );

    return (
        <>
            {query.isVisualization && !props.error && (
                <div style={Styled.VisualizationBlock}>
                    {query.chartType === chartType.bar && <BarChart />}
                    {query.chartType === chartType.line && <LineChart />}
                    {query.chartType === chartType.special && getSpecialChart()}
                    <MDBFormInline>
                        <RefreshVisualizationButton />
                        <ChartTypeSelect />
                        <DownloadButton />
                    </MDBFormInline>
                    <hr />
                </div>
            )}
            {props.error && <div className="text-danger">{Messages.VisualizationIsNotAvailable}</div>}
        </>
    );
};
