const SideBarCollapseContainer = {
    width: "90px",
    color: "#7c7c7c",
    fontSize: "17px",
    paddingLeft: "20px"
};

const SideBarCollapse = {
    paddingLeft: "35px"
};

const SideBarCollapseIcon = (isSideBarOpened: boolean) => {
    return {
        margin: "20px 18px 0 15px",
        color: "#c0ced0",
        paddingRight: isSideBarOpened ? "0" : "40px",
        transition: isSideBarOpened ? "none" : "padding 0.7s"
    };
};

const SideBarCollapseHeader = (isSideBarOpened: boolean) => {
    return { width: isSideBarOpened ? "300px" : "90px" };
};

export const Styled = {
    SideBarCollapseContainer,
    SideBarCollapse,
    SideBarCollapseIcon,
    SideBarCollapseHeader
};
