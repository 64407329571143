import React, { useState, useEffect } from "react";
import { QueryNameModal } from "./queryNameModal";
import { useQuery } from "../store/queryContext";
import {
    CorrelationService,
    CorrelationValidationService,
    DataSetValidationService,
    DateRangesValidationService,
    Messages,
    QueryErrorService
} from "../common";
import { QueryCreationSteps, Notifications, NotificationMessages, GridProvider } from "../../UI";
import { QueryApi } from "../../../core/api";
import { useUser } from "../../../stores";
// @ts-ignore
import { store } from "react-notifications-component";
import { ReplaceQueryModal } from "./replaceQueryModal";

interface IProps {
    setActiveStep: (step: QueryCreationSteps) => void;
}

export const QueryNameInput = (props: IProps) => {
    const {
        query,
        userQueries,
        saveQueryRequest,
        setSaveQueryRequest,
        errors,
        setErrors,
        setIsSubmitted,
        setUserQueries
    } = useQuery();
    const { user } = useUser();
    const [isEditQueryNameOpen, setIsEditQueryNameOpen] = useState<boolean>(false);
    const [isReplaceRequestOpen, setIsReplaceRequestOpen] = useState<boolean>(false);
    const [queryName, setQueryName] = useState<string>("");

    useEffect(() => {
        if (saveQueryRequest.isRequire) {
            setIsEditQueryNameOpen(true);
            setQueryName(query.queryName || query.title || "");
        }
    }, [saveQueryRequest.isRequire]);

    useEffect(() => {
        (async () => setUserQueries(await QueryApi.getAll(user.id)))();
    }, [user.id]);

    const validate = () => {
        DataSetValidationService.validate(query, errors);
        if (!query.title?.trim().length) {
            QueryErrorService.pushError(
                errors,
                QueryCreationSteps.PreviewAndExport,
                Messages.PleaseFillInReportTitleField
            );
        }
        CorrelationValidationService.validate(query, errors);
        DateRangesValidationService.validate(query, errors);
        setErrors([...errors]);
    };

    const saveQuery = async () => {        
        query.queryName = queryName.trim();
        validate();
        setIsSubmitted(true);
        if (errors.length) {
            props.setActiveStep(errors.first().step);
            return;
        }
        if (!query.queryName) query.queryName = query.report;
        if (!query.userId) query.userId = user.id;
        if (query.isCorrelation) CorrelationService.updateCorrelationRelatedDataSetDateRanges(query.dataSets);
        const message = query.queryId
            ? NotificationMessages.TheQueryHasBeenSuccessfullyUpdated
            : NotificationMessages.TheQueryHasBeenSuccessfullyCreated;
        query.queryId = await QueryApi.create(query);
        store.addNotification(Notifications.Successfull(message));
        setIsSubmitted(false);
        if (saveQueryRequest.afterSave) {
            saveQueryRequest.afterSave();
        }
        setSaveQueryRequest({ isRequire: false });
    };

    const handleConfirmSaveQueryName = () => {
        const trimQueryName = queryName.trim();
        if (trimQueryName !== "") {
            if (userQueries.find(q => trimQueryName === q.queryName && query.queryId !== q.queryId)) {
                setIsReplaceRequestOpen(true);
            } else {
                saveQuery();
            }
            setIsEditQueryNameOpen(false);
        }
    };

    const handleCancelReplaceQuery = () => {
        setQueryName(query.queryName || query.title || "");
        setIsEditQueryNameOpen(true);
        setIsReplaceRequestOpen(false);
    };

    return (
        <GridProvider>
            <QueryNameModal
                isOpen={isEditQueryNameOpen}
                onConfirm={handleConfirmSaveQueryName}
                onCancel={() => {
                    setIsEditQueryNameOpen(false);
                    setSaveQueryRequest({ isRequire: false });
                }}
                value={queryName}
                getValue={setQueryName}
            />
            <ReplaceQueryModal
                isOpen={isReplaceRequestOpen}
                onConfirm={() => {
                    saveQuery();
                    setIsReplaceRequestOpen(false);
                }}
                onCancel={handleCancelReplaceQuery}
            />
        </GridProvider>
    );
};
