import React from "react";
import { FileApi } from "../../../core/api";
import { GridActionIcon } from "..";
import { IFile } from "../../../typings";
import { FileService } from "../../../common";

interface IProps {
    file: IFile;
}

export const DownloadFileButton = (props: IProps) => {
    const downloadFile = async () => {
        const file = await FileApi.get(props.file.id);
        FileService.downloadFile(file.data, file.name + file.extension);
    };

    return <GridActionIcon onClick={downloadFile} icon="arrow-down" tooltip="Download" />;
};
