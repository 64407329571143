const Header = {
    color: "#1F1F1F",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    marginRight: "3rem",
    marginBottom: "0",
};

const Icon = {
    color: "#108393"
};

const Span = {
    color: "#626262",
    textDecorationLine: "underline"
};

const InputWrapper = {
    marginTop: "-0.5rem",
    flex: "1 1 auto",
    marginRight: "2.5rem"
}

const ReportTitle = {
    display: "flex",
    alignItems: "start",
    marginBottom: "-1rem",
}

const CheckBoxWrapper = {
    marginLeft: "-2.5rem",
}

export const Styled = {
    Header,
    Icon,
    Span,
    InputWrapper,
    ReportTitle,
    CheckBoxWrapper,
};
