import React from "react";
import { DefaultModal } from "..";

interface IProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

export const SaveOverExistedFileModal = (props: IProps) => (
    <DefaultModal
        isOpen={props.isOpen}
        onСonfirm={props.onConfirm}
        onCancel={props.onCancel}
        bodyText="Do you want to save over existing document?"
        confirmButtonText="YES"
        cancelButtonText="NO"
    />
);
