import { zIndex } from "../../../UI";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Hidden } from "@material-ui/core";

const SideBarContainer = (isSideBarOpened: boolean): CSSProperties => {
    return {
        position: "fixed",
        top: "50px",
        height: "calc(100% - 50px)",
        maxHeight: "calc(100% - 50px)",
        width: isSideBarOpened ? "300px" : "90px",
        left: "0px",
        background: "#FFFFFF",
        boxShadow: "4px 4px 20px rgba(174, 191, 191, 0.2)",
        transition: "width 0.3s",
        zIndex: zIndex.SideBar,
        overflow: "hidden"
    };
};

const LogoSmall = {
    marginTop: "20px",
    marginLeft: "28px",
    width: "34px"
};

const LogoBig = {
    marginTop: "20px",
    marginLeft: "28px",
    height: "50px",
    width: "250px"
};

const Hr = {
    width: "30%",
    border: "1px solid #EDF1F1"
};

const SideBarList = {
    flexWrap: "nowrap",
    maxHeight: "100%",
}

const SideBarLinkContainer = (isSideBarOpened: boolean) => {
    return {
        margin: "20px 0 0 0px",
        paddingLeft: "35px",
        width: isSideBarOpened ? "300px" : "90px"
    };
};

const SideBarLinkIcon = (isSideBarOpened: boolean) => {
    return {
        float: "left",
        paddingRight: isSideBarOpened ? "10px" : "40px",
        transition: isSideBarOpened ? "none" : "padding 0.7s",
        paddingTop: "3px"
    };
};

const RefreshButtonWrapper = {
    display: "flex",
    justifyContent: "space-around",
    color: "#999999"
} as CSSProperties;

const PreloaderWrapper = {
    height: "3px"
};

const DocumentList = {
    overflowX: "hidden",
    overflowY: "auto",
    flex: "1 1"
} as CSSProperties;

export const Styled = {
    SideBarContainer,
    LogoSmall,
    LogoBig,
    Hr,
    SideBarLinkContainer,
    SideBarLinkIcon,
    SideBarList,
    DocumentList,
    RefreshButtonWrapper,
    PreloaderWrapper
};
