import React from "react";
import { Styled } from "./styles/styled";
import { ArticleTypes } from "../../../../typings";
import { getHtmlTextLength } from "../../../../common";
import { useEditableArticle } from "./stores/editableArticleContext";

interface IProps {
    propertyName: string;
    label: string;
    maxLength: number;
    articleType: ArticleTypes;
}

export const ArticleError = (props: IProps) => {
    const { isSubmited, editableArticle } = useEditableArticle(props.articleType);

    const getErrorMessage = (): string => {
        if (!editableArticle) return "";
        if (getHtmlTextLength(editableArticle[props.propertyName]) < 1) {
            return `Please fill in ${props.label} field.`;
        }
        if (getHtmlTextLength(editableArticle[props.propertyName]) > props.maxLength) {
            return `Please limit ${props.label} field to ${props.maxLength} characters max.`;
        }
        return "";
    };

    return <div>{isSubmited && <p style={Styled.TextEditorError}>{getErrorMessage()}</p>}</div>;
};
