import React from "react";
import { Attribute } from "./attribute";
import { MDBRow } from "mdbreact";
import { IAttribute } from "../interfaces";
import { IDataSet } from "../../../typings";
import { useQuery } from "../store/queryContext";
import { SaarService } from "../common";

interface IProps {
    dataSet: IDataSet;
}

export const Attributes = (props: IProps) => {
    const { query } = useQuery();
    const attributes = SaarService.filterAttributes(query.isSAAR, props.dataSet.attributes);
    return (
        <div className="ml-4">
            <MDBRow>
                {attributes.splitIntoTwoGroups().map((a: IAttribute) => (
                    <Attribute key={a.description} attribute={a} dataSet={props.dataSet} />
                ))}
            </MDBRow>
        </div>
    );
};
