import React from "react";
import { MDBContainer } from "mdbreact";
import { Styled } from "./styled";
import { useArticle } from "../UI";
import { ArticleTypes } from "../../typings";

interface IProps {
    isContentPage?: boolean;
}

export const Footer = (props: IProps) => {
    const { article } = useArticle(ArticleTypes.Footer);

    const createMarkup = (text?: string) => ({ __html: text ?? "" });

    return (
        <MDBContainer fluid style={props.isContentPage ? Styled.ContentFooter : Styled.Footer}>
            <div
                className="footer-copyright text-center py-3"
                dangerouslySetInnerHTML={createMarkup(article?.articleBody)}
            />
        </MDBContainer>
    );
};
