import React from "react";
import { DefaultButton,ButtonStyled } from "../../UI";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "..";
import { Routes } from "../../../common";
import { useHistory } from "react-router-dom";

interface IProps {
    size?: "sm" | "lg";
}

export const SaveQueryButton = (props: IProps) => {
    const { setSaveQueryRequest, queryMode } = useQuery();
    const history = useHistory();

    return (
        <>
            <DefaultButton
                onClick={() =>
                    setSaveQueryRequest({
                        isRequire: true,
                        afterSave: () => history.push(Routes.MyQueries)
                    })
                }
                text="SAVE QUERY"
                style={ButtonStyled.PrimaryButton}
                size={props.size}
                disabled={queryMode === QueryMode.View}
            />
        </>
    );
};
