import React from "react";
import { CustomInputField } from "./customInputField ";

interface IProps {
    getValue: (value: string) => void;
    value: string | undefined;
    isSubmitted: boolean;
    isUserNameExist: boolean;
}

export const UserNameField = (props: IProps) => (
    <>
        <CustomInputField
            placeholder="User Name"
            label="User Name"
            icon="user"
            isRequired
            mask="^[\w.\-@+]{1,}$"
            maskError='The User Name shall contain alphabet or numbers or special characters "-._@+"'
            isSubmitted={props.isSubmitted}
            value={props.value}
            getValue={props.getValue}
            maxLength={80}
        />
        {props.isUserNameExist && (
            <div className="invalid-feedback d-block  mb-3">
                This Username is already used. Please fill in a different Username.
            </div>
        )}
    </>
);
