import React, { useState, useEffect } from "react";
// @ts-ignore
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "./styles/reportPreviewTable.css";
import { changeColumnLetters, formatRows } from "./services/tableValuesFormatting";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../common";
import { IExcelTableColumn } from "../interfaces/IExcelTableColumn";
import { DataReceiver } from "./services/dataReceiver";
import { Constants } from "../common/constants";

interface IProps {
    file?: string | null;
    isQueryPage?: boolean;
    isPivot?: boolean;
}

export const ReportPreview = (props: IProps) => {
    const history = useHistory();
    if (!props.isQueryPage && !props.file) {
        history.push(Routes.Home);
    }
    const [rows, setRows] = useState<Array<Array<string | number>>>();
    const [columns, setColumns] = useState<IExcelTableColumn>();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (props.file?.length) {
            const byteArray = DataReceiver.getByteArray(props.file);
            if (!byteArray) return;
            const file = new Blob([byteArray], {});
            ExcelRenderer(file, (err: any, resp: any) => {
                if (byteArray) {
                    resp.cols = resp.cols.slice(0, resp.rows.maxArrayLength());
                    changeColumnLetters(resp.cols);
                    formatRows(resp.rows, props.isPivot);
                    setColumns(resp.cols);
                    setRows(resp.rows);
                    setIsDataLoaded(true);
                }
            });
        }
    }, [props.file]);
    return (
        <div 
            className={props.isQueryPage ? "report-preview" : ""} 
            style={{ maxHeight: Constants.ReportAndChartHeight * Constants.ReportHeightCoefficient }}
        >
            {isDataLoaded && (
                <OutTable data={rows} columns={columns} tableClassName="ExcelTable" tableHeaderRowClass="heading" />
            )}
        </div>
    );
};
