import { Chart } from "chart.js";

(function(Chart) {
    Chart.plugins.register({
        defaultOptions: {
            // where to draw the text (if null, right bottom text on chart)
            x: 0,
            y: 0,

            // how many degrees to rotate the text
            rotate: 0,

            // format of the text
            font: Chart.helpers.fontString(12, "normal", Chart.defaults.global.defaultFontFamily),
            fillStyle: "rgba(102, 102, 102, 0.8)",
            textAlign: "center",
            textBaseline: "middle",

            // the text to draw on the canvas
            text: "Source: Wards Intelligence"
        },

        afterDraw: function(chartInstance) {
            const watermark = chartInstance.watermark;
            const ctx = chartInstance.chart.ctx;
            ctx.save();
            ctx.font = watermark.font;
            ctx.fillStyle = watermark.fillStyle;
            ctx.textAlign = watermark.textAlign;
            ctx.textBaseline = watermark.textBaseline;
            const canvas = chartInstance.chart;
            const x = canvas.width - watermark.x;
            const y = canvas.height - watermark.y;
            ctx.translate(x, y);
            ctx.rotate((watermark.rotate * Math.PI) / 180);
            ctx.fillText(watermark.text, 0, 0);
            ctx.restore();
        },

        beforeInit: function(chartInstance) {
            const plugin = this;
            chartInstance.watermark = {};
            const helpers = Chart.helpers,
                options = chartInstance.options;
            if (options.watermark) {
                const clonedDefaultOptions = helpers.clone(plugin.defaultOptions),
                    watermark = helpers.extend(clonedDefaultOptions, options.watermark);
                chartInstance.watermark = watermark;
            }
        }
    });
})(Chart);
