import { httpGet, httpPost, httpPut } from "./request/requestApi";
import { IUser, ISignUp } from "../../typings";
import { IUserManagement } from "../../typings/IUserManagement";

const controllerEndPoint = "users";

export class UsersApi {
    static async getUserContext(): Promise<IUser> {
        return await httpGet(`${controllerEndPoint}/get-user-context`);
    }

    static async createUser(usersData: ISignUp | undefined) {
        await httpPost(`${controllerEndPoint}/create`, { body: usersData });
    }

    static async getUsers(): Promise<IUserManagement[]> {
        return await httpGet(`${controllerEndPoint}/get-all`);
    }

    static async deleteUser(id: number | undefined) {
        await httpPost(`${controllerEndPoint}/delete`, {
            body: {
                userId: id
            }
        });
    }

    static async updateUser(user: ISignUp | undefined) {
        await httpPut(`${controllerEndPoint}/update`, { body: user });
    }

    static async updatePassword(user: ISignUp | undefined) {
        await httpPut(`${controllerEndPoint}/update-password`, { body: user });
    }

    static async disableUser(id: number | undefined, isDisabled: boolean) {
        await httpPost(`${controllerEndPoint}/change-user-state`, {
            body: {
                id: id,
                isDisabled: isDisabled
            }
        });
    }
}
