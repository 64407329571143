import React from "react";
import { SpecificCalculationCheckbox } from "./specificCalculationCheckbox";
import { useQuery } from "../../store/queryContext";
import { MarketNames, QueryService, SaarService, DataSetService } from "../../common";

export const SaarCheckbox = () => {
    const { query, setQuery } = useQuery();

    const shouldRenderSaarCheckbox = () =>
        !query.isCorrelation &&
        query.market === MarketNames.Us &&
        DataSetService.isOnlyOneSelected(query.dataSets) &&
        DataSetService.IsSalesSelected(query.dataSets);

    const onChangeHandler = async (event: React.FormEvent<HTMLInputElement>) => {
        query.isSAAR = event.currentTarget.checked;
        await SaarService.updateSalesDataSet(query);
        await QueryService.updateQueryDatasetsAvailability(query);
        setQuery({ ...query });
    };

    return (
        <>
            {shouldRenderSaarCheckbox() && (
                <SpecificCalculationCheckbox
                    id="saarCheckbox"
                    label="Seasonally Adjusted Annual Rate"
                    onChange={onChangeHandler}
                    checked={query.isSAAR}
                />
            )}
        </>
    );
};
