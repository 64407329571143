import { MDBRow, MDBCol } from "mdbreact";
import React from "react";

export const CorrelationSortAndDisplayOptions = () => (
    <MDBRow>
        <MDBCol>
            <span>The display and sort options are not applicable when building the correlations between datasets.</span>
        </MDBCol>
    </MDBRow>
);
