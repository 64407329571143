import React, { useState } from "react";
import { MDBInput } from "mdbreact";
// @ts-ignore
import { store } from "react-notifications-component";
import { DefaultModal, Notifications } from "../UI";
import { UsersApi } from "../../core/api";
import { useUsers } from "./stores/usersContext";

interface IProps {
    userId: number | undefined;
    isDisabled: boolean;
}

export const DisableUserCheckbox = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { users, setUsers } = useUsers();
    const [isSelected, setIsSelected] = useState<boolean>(props.isDisabled);

    const changeState = async () => {
        setIsModalOpen(false);
        await UsersApi.disableUser(props.userId, isSelected);
        let newArray = [...users];
        const user = newArray.find(u => u.id === props.userId);
        if (user) user.isDisabled = isSelected;
        setUsers(newArray);
        store.addNotification(
            Notifications.Successfull(`The user account has been ${isSelected ? "disabled" : "enabled"}.`)
        );
    };

    const getModalText = () => `Do you want to ${isSelected ? "disable" : "enable"} the selected user account?`;

    const getModalConfirmButtonText = () => (isSelected ? "Yes, disable" : "Yes, enable");

    const onInput = (value: boolean) => {
        setIsSelected(value);
        setIsModalOpen(true);
    };

    return (
        <>
            <MDBInput
                type="checkbox"
                label=" "
                id={`checkbox${props.userId}`}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    onInput(e.currentTarget.checked);
                }}
                checked={isSelected}
            />
            <DefaultModal
                isOpen={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    setIsSelected(!isSelected);
                }}
                onСonfirm={changeState}
                bodyText={getModalText()}
                cancelButtonText="No, cancel"
                confirmButtonText={getModalConfirmButtonText()}
            />
        </>
    );
};
