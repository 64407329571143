import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../common";
import { Styled } from "../styled/styled";
import { IUser } from "../../../typings";
import { IconWithTooltip } from "../../UI";

interface IProps {
    user: IUser;
}

export const UserQueriesButton = (props: IProps) => {
    const history = useHistory();
    return (
        <IconWithTooltip style={Styled.ControlButtons} tooltip="User Queries" icon="archive"
            onClick={() => history.push(Routes.UserQueries, {
                user: props.user
            })
            } />
    );
};
