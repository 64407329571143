import { CSSProperties } from "react";

const PrimaryHeader = (isActiveStep: boolean) =>
    (({
        fontStyle: "normal",
        fontSize: "24px",
        fontWeight: "Bold",
        lineHeight: "35px",
        marginTop: 8,
        color: isActiveStep ? "#078494" : "#636363"
    } as unknown) as CSSProperties);

const Stepper = {
    background: "#F9F9F9"
};

export const Styled = {
    PrimaryHeader,
    Stepper
};
