import React from "react";
import { DefaultButton } from "..";
import { QueryCreationSteps } from "./queryCreationSteps";
import { ButtonStyled } from "../buttons/buttonStyled";
import { useQuery } from "../../queries/store/queryContext";
import { QueryService } from "../../queries/common";
import { ResetStepService } from "./resetStepService";
import { QueryMode } from "../../queries";

interface IProps {
    step: QueryCreationSteps;
}

export const ResetStepButton = (props: IProps) => {
    const { query, setQuery, queryMode } = useQuery();
    return (
        <DefaultButton
            text="RESET STEP"
            size="lg"
            disabled={queryMode === QueryMode.View}
            onClick={async () => {
                const originQuery = query.queryId
                    ? await QueryService.getQuery(query.queryId)
                    : QueryService.getDefaultQuery();
                switch (props.step) {
                    case QueryCreationSteps.SelectMarketAndDataset:
                        setQuery(QueryService.getDefaultQuery());
                        return;
                    case QueryCreationSteps.NarrowSearch:
                        ResetStepService.resetNarrowSearchStep(query, originQuery);
                        break;
                    case QueryCreationSteps.ChooseSortandDisplayOptions:
                        ResetStepService.resetChooseSortAndDisplayOptionsStep(query, originQuery);
                        break;
                    case QueryCreationSteps.ChooseDatesAndCalculations:
                        ResetStepService.resetChooseDatesAndCalculationsStep(query, originQuery, setQuery);
                        break;
                    case QueryCreationSteps.PreviewAndExport:
                        ResetStepService.resetPreviewAndExportStep(query, originQuery);
                        break;
                }
                setQuery({ ...query });
            }}
            style={ButtonStyled.SecondaryButton}
        />
    );
};
