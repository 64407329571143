export const RoleManagementTableColums = [
    {
        label: "Name",
        field: "name",
        sort: "disabled"
    },
    {
        label: "Actions",
        field: "actions",
        sort: "disabled"
    }
];
