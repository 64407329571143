import React, { useState, PropsWithChildren } from "react";
import { MDBCollapse, MDBIcon } from "mdbreact";
import { Styled } from "./styled";

interface IProps {
    header: string;
    isSideBarOpen: boolean;
}

export const SideBarCollapse = (props: PropsWithChildren<IProps>) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            style={Styled.SideBarCollapseContainer}
            onClick={() => setIsOpen(!isOpen)}
            className={"text-nowrap bd-highlight cursor-pointer"}
        >
            <MDBIcon icon="file" style={Styled.SideBarCollapseIcon(props.isSideBarOpen)} />
            {props.header}
            <i className={isOpen && props.isSideBarOpen ? "fa fa-angle-up ml-2" : "fa fa-angle-down ml-2"} />
            <MDBCollapse style={Styled.SideBarCollapse} isOpen={isOpen && props.isSideBarOpen}>
                {props.children}
            </MDBCollapse>
        </div>
    );
};
