import React from "react";
import { MDBNavbar, MDBNavItem } from "mdbreact";
import { Styled } from "./Styles/styled";
import { HeaderUserMenu } from "./headerUserMenu";
import { BarButton } from "./buttons/barButton";

interface IProps {
    setSideBarOpened: Function;
}

export const HeaderWithMenu = (props: IProps) => (
    <MDBNavbar style={Styled.Header} double fixed="top">
        <BarButton setSideBarOpened={props.setSideBarOpened} />
        <MDBNavItem className="d-none d-md-inline" style={Styled.HeaderText}>
            Wards Intelligence Data Query
        </MDBNavItem>
        <HeaderUserMenu />
    </MDBNavbar>
);
