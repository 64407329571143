import { httpPost } from "./request/requestApi";
import { IFilter } from "../../typings";
import { ISubattribute } from "../../components/queries/interfaces/ISubattribute";

const controllerEndPoint = "subattribute";

export class SubattributeApi {
    static async getAll(
        dataSetName: string | undefined,
        filters: Array<IFilter> | undefined,
        subattributeName: string
    ): Promise<Array<ISubattribute>> {
        return await httpPost(`${controllerEndPoint}/get-all`, {
            body: {
                filters: filters,
                subattributeName: subattributeName,
                dataSetName: dataSetName
            }
        });
    }
}
