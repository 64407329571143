const PeriodSelector = {
    width: 160,
};

const AddRangeButton = {
    textDecorationLine: "underline",
    color: "#626262"
};

const AddRangeIcon = {
    color: "#108393"
};

const CurrentDatesPopper = {
    marginTop: 15,
    zIndex: 10000
};

export const Styled = {
    PeriodSelector,
    AddRangeButton,
    AddRangeIcon,
    CurrentDatesPopper
};
