import { IQuery } from "../../components/queries/interfaces/IQuery";
import { DatesService } from "../../components/queries";
import { httpPost } from "./request/requestApi";
import { IVisualizationData } from "../../components/queries/interfaces/IVisualizationData";
import { DataSetService, CorrelationService } from "../../components/queries/common";

const controllerEndPoint = "visualization";

export class VisualizationApi {
    static async get(query: IQuery): Promise<IVisualizationData> {
        const dataSets = DatesService.getDatasetsWithCastedDatePeriods(DataSetService.getSelected(query.dataSets));
        if (query.isCorrelation) CorrelationService.updateCorrelationRelatedDataSetDateRanges(dataSets);
        DataSetService.createSorts(dataSets, query);
        return await httpPost(`${controllerEndPoint}/get`, {
            body: {
                ...query,
                dataSets: dataSets
            }
        });
    }
}
