import React from "react";
import { ContactApi } from "../../../core/api";
import { useContacts } from "..";
import { DefaultButton, useGrid, Notifications, NotificationMessages, ButtonStyled } from "../../UI";
import { validateEmail } from "../../../common";
//@ts-ignore
import { store } from "react-notifications-component";

export const SaveContactsButton = () => {
    const { contacts, setContacts } = useContacts();
    const { setIsSave } = useGrid();

    const save = async () => {
        setIsSave(true);
        if (isContactsInvalid()) return;
        const updatedContacts = contacts.filter(c => c.isUpdated);
        if (updatedContacts.length) await ContactApi.update(updatedContacts);
        const insertedContacts = contacts.filter(c => c.isInserted);
        if (insertedContacts.length) await ContactApi.create(insertedContacts);
        if (updatedContacts.any() || insertedContacts.any()) setContacts(await ContactApi.getAll());
        setIsSave(false);
        store.addNotification(Notifications.Successfull(NotificationMessages.ChangeHasBeenSuccessfullySaved));
    };

    const isContactsInvalid = () =>
        contacts.some(c => !c.contactName || !c.email || !validateEmail(c.email) || !c.notes);

    return <DefaultButton onClick={save} style={ButtonStyled.PrimaryButton} icon="save" text="Save" />;
};
