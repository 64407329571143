const UserQueries = {
    overflow: "auto",
    maxHeight: "60vh"
};

const Button = {
    minWidth: "9rem"
};

export const Styled = {
    UserQueries,
    Button
};