import React from "react";
import { MDBCard, MDBCardBody } from "mdbreact";
import { DateRange } from "../dates/dateRange";
import { IDateRangeComponentProps } from "../../interfaces/IDateRangeComponentProps";
import { RemoveDateRangeButton } from "../../buttons/removeDateRangeButton";
import { useQuery } from "../../store/queryContext";
import { Styled } from "./styles/styled";

export const DateRangeCard = (props: IDateRangeComponentProps) => {
    const { query } = useQuery();
    return (
        <MDBCard id={props.index}>
            <MDBCardBody className="p-2">
                {!query.isCorrelation && (
                    <div style={Styled.RangeCardClose}>
                        <RemoveDateRangeButton dataSet={props.dataSet} index={props.index} />
                    </div>
                )}
                <DateRange
                    dataSet={props.dataSet}
                    index={props.index}
                    ranges={props.dataSet?.ranges}
                    dateRange={props.dateRange}
                />
            </MDBCardBody>
        </MDBCard>
    );
};
