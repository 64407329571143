import React, { useState } from "react";
import { DefaultModal } from "..";
import { Styled } from "./styled";
import { IconWithTooltip } from "../tooltips/iconWithTooltip";

interface IProps {
    onDelete: () => void;
    isFile?: boolean;
}
export const GridDeleteAction = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <IconWithTooltip
                style={Styled.ActionsFieldIcon}
                onClick={() => setIsModalOpen(true)}
                icon="trash-alt"
                tooltip="Delete"
            />
            <DefaultModal
                isOpen={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onСonfirm={() => {
                    setIsModalOpen(false);
                    props.onDelete();
                }}
                isFade={false}
                cancelButtonText="No, cancel"
                confirmButtonText="Yes, delete"
                bodyText={`Do you want to delete the selected ${
                    props.isFile ? "file" : "line"
                }?`}
            />
        </>
    );
};
