import { Styled as NQStyles } from "../styled";
import { MDBRow, MDBCol, MDBFormInline } from "mdbreact";
import React from "react";
import { CountrySelector } from "./selectors/countrySelector";
import { RegionSelector } from "./selectors/regionSelector";
import { useQuery } from "../store/queryContext";
import { Constants } from "../common/constants";
import { DataSetApi } from "../../../core/api";
import { useUser } from "../../../stores";
import { Market } from "./market";
import { PropertyNames } from "../../../common/propertyNames";
import { MarketNames } from "../common/marketNames";

export const Markets = () => {
    const { query, updateQuery } = useQuery();
    const { user } = useUser();

    return (
        <>
            <MDBRow>
                <MDBCol className="pt-3">
                    <h5 style={NQStyles.Headers}> Market </h5>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBFormInline className="ml-3">
                    <Market
                        isMarketAllowed={user.marketPermissions?.isUsViewAllowed}
                        marketName={MarketNames.Us}
                        onClick={async () => {
                            const dataSets = await DataSetApi.getAll(PropertyNames.Country, Constants.UsCountry);
                            dataSets.forEach(d => {
                                d.filters = [
                                    {
                                        propertyName: PropertyNames.Country,
                                        values: [Constants.UsCountry]
                                    }
                                ];
                            });
                            updateQuery({
                                ...query,
                                market: MarketNames.Us,
                                dataSets: dataSets,
                                country: Constants.UsCountry,
                                periods: [],
                                region: undefined
                            });
                        }}
                    />
                    <Market
                        isMarketAllowed={user.marketPermissions?.isCountryViewAllowed}
                        marketName={MarketNames.Country}
                        onClick={() =>
                            updateQuery({
                                ...query,
                                market: MarketNames.Country,
                                dataSets: [],
                                periods: [],
                                country: undefined,
                                region: undefined,
                                isSAAR: undefined
                            })
                        }
                    />
                    <Market
                        isMarketAllowed={user.marketPermissions?.isRegionViewAllowed}
                        marketName={MarketNames.Region}
                        onClick={() => {
                            updateQuery({
                                ...query,
                                market: MarketNames.Region,
                                dataSets: [],
                                periods: [],
                                country: undefined,
                                region: undefined,
                                isSAAR: undefined
                            });
                        }}
                    />
                    <Market
                        isMarketAllowed={user.marketPermissions?.isWorldViewAllowed}
                        marketName={MarketNames.World}
                        onClick={async () =>
                            updateQuery({
                                ...query,
                                market: MarketNames.World,
                                dataSets: await DataSetApi.getAll(),
                                periods: [],
                                country: undefined,
                                region: undefined,
                                isSAAR: undefined
                            })
                        }
                    />
                    {query.market === MarketNames.Country && <CountrySelector />}
                    {query.market === MarketNames.Region && <RegionSelector />}
                </MDBFormInline>
            </MDBRow>
        </>
    );
};
