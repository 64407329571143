import React from "react";
import { ReportPreview } from "../components/queries/previewExport/reportPreview";
import { Constants } from "../components/queries/common/constants";
import { MDBContainer, MDBCol } from "mdbreact";

export const ReportPreviewPage = () => (
    <MDBContainer>
        <MDBCol>
            <ReportPreview file={localStorage.getItem(Constants.ReportLocalSessionKey)} />;
        </MDBCol>
    </MDBContainer>
);
