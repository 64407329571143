import React, { useState } from "react";
import { Styled } from "./styles/styled";
import { useUser } from "../../stores";
import { InputCell, EmailCell } from "../UI";
import { IContact } from "../../typings";

interface IProps {
    contact: IContact;
    label: string;
    propertyName: string;
    isEmail?: boolean;
}

export const ContactGridCell = (props: IProps) => {
    const [inputValue, setInputValue] = useState(props.contact[props.propertyName]);
    const { user } = useUser();

    const Update = (value: string) => {
        setInputValue(value);
        props.contact[props.propertyName] = value;
        props.contact.isUpdated = !props.contact.isInserted;
    };

    return (
        <td style={Styled.Cell}>
            {user.isAdmin ?
                <InputCell
                    isDisabled={!user.isAdmin}
                    defaultValue={props.contact[props.propertyName]}
                    getValue={Update}
                    isEmail={props.isEmail}
                    value={inputValue}
                    label={props.label} />
                : props.isEmail ?
                    <EmailCell value={props.contact[props.propertyName]} />
                    : props.contact[props.propertyName]
            }
        </td>
    );
};
