const ModalBody = {
    textAlign: "center",
    fontWeight: "bold"
};
const Button = {
    minWidth: "9rem",
    fontSize: 14,
    textTransform: "uppercase !important"
};
export const Styled = {
    ModalBody,
    Button
};
