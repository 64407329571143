import { RolesApi, ApiError } from "../../../core/api";
import { IRole } from "../../../typings";

export const SaveRole = async (role: IRole) => {
    try {
        if (role.id) await RolesApi.update(role);
        else await RolesApi.create(role);
    } catch (e) {
        if (e instanceof ApiError) {
            return await e.response.text();
        }
    }
};
