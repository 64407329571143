import React from "react";
import { FileApi } from "../../../core/api";
import { GridActionIcon } from "..";
import { FileService } from "../../../common";

interface IProps {
    fileId: number;
}

export const ViewFileButton = (props: IProps) => {
    const viewFile = async () => {
        const file = await FileApi.get(props.fileId);
        FileService.viewPDF(file.data, file.name + file.extension);
    };

    return <GridActionIcon onClick={viewFile} icon="eye" tooltip="View" />;
};
