import React from "react";
import { MDBIcon, MDBContainer, MDBRow } from "mdbreact";
import { Styled } from "../components/myAccount";
import { useUser } from "../stores";
import { DefaultParagraph } from "../components/UI";
import { ResetPasswordButton } from "../components/myAccount";

export const MyAccountPage = () => {
    const { user } = useUser();

    return (
        <MDBContainer>
            <DefaultParagraph text="My Account" />
            <MDBRow style={Styled.FieldContainer}>
                <MDBIcon icon="user" style={Styled.Icon} /> {user.fullName}
            </MDBRow>
            <MDBRow style={Styled.FieldContainer}>
                <MDBIcon icon="envelope" style={Styled.Icon} /> {user.email}
            </MDBRow>
            <MDBRow>
                <ResetPasswordButton />
            </MDBRow>
        </MDBContainer>
    );
};
