import React, { useState } from "react";
import { useUsers } from "../stores/usersContext";
import { UsersApi } from "../../../core/api";
import { DefaultModal, Notifications, NotificationMessages, IconWithTooltip } from "../../UI";
// @ts-ignore
import { store } from "react-notifications-component";
import { Styled } from "../styled/styled";

interface IProps {
    userId: number | undefined;
}

export const DeleteUserButton = (props: IProps) => {
    const [deleteUserModalIsOpen, setDeleteUserModalIsOpen] = useState(false);
    const { users, setUsers } = useUsers();

    const deleteUser = async () => {
        await UsersApi.deleteUser(props.userId);
        setUsers(users.filter(u => u.id !== props.userId));
        setDeleteUserModalIsOpen(false);
        store.addNotification(Notifications.Successfull(NotificationMessages.UserAccountHasBeenSuccessfullyDeleted));
    };

    return (
        <>
            <IconWithTooltip
                style={Styled.ControlButtons}
                tooltip="Delete"
                icon="trash-alt"
                onClick={() => setDeleteUserModalIsOpen(true)}
            />
            <DefaultModal
                isOpen={deleteUserModalIsOpen}
                onCancel={() => setDeleteUserModalIsOpen(false)}
                onСonfirm={() => deleteUser()}
                cancelButtonText="No, cancel"
                bodyText="Do you want to delete the selected user account?"
                confirmButtonText="Yes, delete"
            />
        </>
    );
};
