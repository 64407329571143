export const apiConstants = {
    headers: {
        errorCode: "X-ERROR-CODE",
        errorId: "X-ERROR-ID",
        expirationCookieTime: "X-COOKIE-TIME",
        payloadError: "X-ERROR-PAYLOAD"
    },
    contentTypes: {
        plainText: "text/plain",
        json: "application/json"
    },
    baseUrl: "https://query.wardsintelligence.informa.com/api/",
    tokenKeys: {
        accessToken: "access-token"
    }
};
