import React from 'react';
import { DefaultModal } from '../../UI/modals/defaultModal';

interface IProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ReplaceQueryModal = (props: IProps) => {
    return (
        <DefaultModal
            isOpen={props.isOpen}
            onCancel={props.onCancel}
            onСonfirm={props.onConfirm}
            bodyText="Do you want to save over existing query?"
            confirmButtonText="YES"
            cancelButtonText="NO"
        />
    );
}
