import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { ICalculation } from "../../interfaces/ICalculation";
import { CalculationCheckBox } from "./calculationCheckBox";
import { Styled } from "../../styled";
import { dateRanges } from "../../../../typings";

interface IProps {
    calculations: Array<ICalculation> | undefined;
    dateRange: dateRanges;
    label: string;
    dataSetName: string | undefined;
}

export const CalculationList = (props: IProps) => (
    <MDBRow className="ml-1 calculation-row" style={Styled.RowsInCalc}>
        <p style={Styled.CalculationLabel}>{props.label}</p>
        {props.calculations?.map((c: ICalculation, index: number) => (
            <MDBCol key={index} md="2" style={Styled.CheckBox}>
                <CalculationCheckBox calculation={c} dateRange={props.dateRange} dataSetName={props.dataSetName} />
            </MDBCol>
        ))}
    </MDBRow>
);
