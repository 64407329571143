import { IDataSet } from "../../../../typings";
import { IQuery } from "../../interfaces/IQuery";
import { DataSetNames } from "../../../../common/dataSetNames";

const getSelected = (dataSets?: Array<IDataSet>) => (dataSets ? dataSets.filter(d => d.isSelected) : []);

const anySelected = (dataSets?: Array<IDataSet>) => (dataSets ? dataSets.some(d => d.isSelected) : false);

const isOnlyOneSelected = (dataSets?: Array<IDataSet>) => (dataSets ? getSelected(dataSets).length === 1 : false);

const getFirstSelected = (dataSets: Array<IDataSet> | undefined): IDataSet => dataSets?.find(d => d.isSelected) ?? {};

const getSecondSelected = (dataSets: Array<IDataSet> | undefined): IDataSet => {
    const firstSelected = getFirstSelected(dataSets);
    return dataSets?.find(d => d.isSelected && d.dataSetName !== firstSelected?.dataSetName) ?? {};
};

const IsSalesSelected = (dataSets?: Array<IDataSet>) =>
    dataSets?.some(d => d.dataSetName === DataSetNames.Sales && d.isSelected);

const isRateSelected = (dataSets?: Array<IDataSet>) =>
    dataSets?.some(d => d.isSelected && isRate(d.dataSetName));

const isRate = (dataSetName: string | undefined) =>
    dataSetName === DataSetNames.EngineRate ||
    dataSetName === DataSetNames.EquipmentRate ||
    dataSetName === DataSetNames.TransmissionRate;

const createSorts = (dataSets: Array<IDataSet>, query: IQuery) => {
    dataSets.forEach(d => {
        d.isShowTotalOnly = query.isShowTotalOnly;
        d.sorts = query.isShowTotalOnly ? [] : query.sorts;
    });
}

export const DataSetService = {
    getSelected,
    anySelected,
    isOnlyOneSelected,
    getFirstSelected,
    getSecondSelected,
    IsSalesSelected,
    isRateSelected,
    isRate,
    createSorts
};
