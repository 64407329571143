import React from "react";
import { FileApi } from "../../../core/api";
import { GridDeleteAction, useFiles } from "..";
import { IFile } from "../../../typings";

interface IProps {
    file: IFile;
}
export const DeleteFileAction = (props: IProps) => {
    const { files, setFiles } = useFiles();

    const Delete = async () => {
        await FileApi.delete(props.file.id);
        setFiles(files.filter(f => f.id !== props.file.id));
    };

    return <GridDeleteAction onDelete={Delete} isFile />;
};
