export const TableColumns = [
    {
        label: "Order",
        field: "order",
        sort: "disabled"
    },
    {
        label: "File",
        field: "file",
        sort: "disabled"
    },
    {
        label: "Title",
        field: "title",
        sort: "disabled"
    },
    {
        label: "Date",
        field: "date",
        sort: "disabled"
    },
    {
        label: "Actions",
        field: "actions",
        sort: "disabled"
    }
];
