import React from "react";
import { MDBInput } from "mdbreact";

interface IProps {
    id: string;
    label: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    checked: boolean;
}

export const RememberMeCheckbox = (props: IProps) => {
    return (
        <MDBInput
            type="checkbox"
            id={props.id}
            label={props.label}
            //className=""
            onChange={props.onChange}
            checked={props.checked}
        />
    );
};
