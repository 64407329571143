import React, { useState } from "react";
import { PasswordField, DefaultFormButton, Preloader } from "../UI";
import { ISignUp } from "../../typings";
import { Styled } from "./styled/styled";
import { useRedirect } from "../../common/hooks/useRedirect";
import { Routes } from "../../common";
import { signUpActions } from "../signUp/actions/signUpAction";

interface IProps {
    user?: ISignUp;
    isUpdate?: boolean;
}

export const UpdatePasswordForm = (props: IProps) => {
    const [usersData, setUsersData] = useState<ISignUp | undefined>(props.user);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [redirectAfterEdit] = useRedirect(props.isUpdate !== undefined, "user account", Routes.UsersManagement);
    const isFormDataCorrect = () => {
        const unsubmittedFormErrors = document.getElementsByClassName("error").length;
        const submittedFormErrors = document.getElementsByClassName("is-invalid").length;
        return !(unsubmittedFormErrors || submittedFormErrors);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setFormSubmitted(true);
            if (isFormDataCorrect()) {
                const response = await signUpActions.updatePassword(usersData);
                if (response) {
                    
                } else redirectAfterEdit();
            }
    };

    return (
        <form className="w-75" onSubmit={onSubmit} noValidate style={Styled.PwdStyle}>
            {props.isUpdate && (
                <PasswordField
                    label="Password"
                    isIconPaddingNeeded
                    isSubmitted={formSubmitted}
                    value={usersData?.password}
                    getValue={value =>
                        setUsersData({
                            ...usersData,
                            password: value.toString()
                        })
                    }
                />
             )}
            <Preloader />
            <DefaultFormButton text={"UPDATE PASSWORD"} />
        </form>
    );
};
