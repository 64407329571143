const Quarters = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];

const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const DatePeriodConstants = {
    Current: "Current",
    Prior: "Prior",
    Prior2: "2Prior",
    MonthsCount: 12,
    QuartersCount: 4,
    Months,
    Quarters
};
