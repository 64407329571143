import React from "react";
import { datePeriod } from "../../../../../typings";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatesService } from "../services/datesService";
import { IPeriodsRanges } from "../../../interfaces/IPeriodsRanges";
import { useQuery } from "../../../store/queryContext";
import { QueryMode } from "../../..";
import { Styled } from "../../styled";
import { DatePeriodConstants } from "../constants/datePeriodConstants";
import { QueryErrorService } from "../../../common";

interface IProps {
    from?: string;
    to?: string;
    isStart?: boolean;
    period: datePeriod | undefined;
    setter: (year: string | undefined) => void;
    value?: string;
    ranges: IPeriodsRanges | undefined;
    isModelYear?: boolean;
}

export const YearsSelector = (props: IProps) => {
    let options = new Array<string>();
    const { errors, setErrors, queryMode } = useQuery();
    const ranges = props.ranges;
    if (!ranges) return <> </>;
    const currentYear = ranges.currentYear;
    let startYear = DatesService.getYear(props.from, props.ranges);
    let endYear = DatesService.getYear(props.to, props.ranges);
    switch (props.from) {
        case currentYear.toString():
        case DatePeriodConstants.Current:
            options.push(DatePeriodConstants.Current);
            break;
        default:
            options.push(DatePeriodConstants.Current);
            options.push(DatePeriodConstants.Prior);
    }
    if (props.to !== ranges.endYear) options = [];
    switch (props.to) {
        case currentYear.toString():
        case DatePeriodConstants.Current:
            options.push(DatePeriodConstants.Current);
            options.push(DatePeriodConstants.Prior);
            break;
        case (+currentYear - 1).toString():
        case DatePeriodConstants.Prior:
            options.push(DatePeriodConstants.Prior);
            break;
    }
    for (let i = endYear; i >= startYear; i--) options.push(i.toString());

    return (
        <Autocomplete
            key={`year-selector-${props.value}`}
            id={`year-selector-${new Date().getMilliseconds()}`}
            size="small"
            options={options}
            value={props.value}
            getOptionLabel={option => option}
            onChange={(_event: object, value: string | null) => {
                setErrors(QueryErrorService.filterStepErrors(errors));
                props.isStart
                    ? DatesService.setStartYearOfPeriod(value ?? undefined, props.setter, props.period)
                    : DatesService.setEndYearOfPeriod(value ?? undefined, props.setter, props.period);
            }}
            disabled={queryMode === QueryMode.View}
            style={Styled.PeriodSelector}
            renderInput={params => <TextField {...params} label="Year" variant="outlined" />}
        />
    );
};
