import { formatNumberToPercent } from "../../../../common";
import { IExcelTableColumn } from "../../interfaces/IExcelTableColumn";

const CalculationsWithoutDecimalPart = ["Avg", "DSR", "DS", "DPR", "Options Volume", "Sales", "Sales Forecast"];

export const changeColumnLetters = (columns: Array<IExcelTableColumn>) => {
    columns.push({ name: " " });
    for (let i = columns.length - 1; i !== 0; i--) {
        columns[i].name = columns[i - 1].name;
    }
    columns.first().name = " ";
};

export const formatRows = (rows: Array<Array<string | number>>, isPivot: boolean | undefined) => {
    for (let i = 0; i < rows.length; i++)
        for (let j = 0; j < rows[i].length; j++) {
            if (typeof rows[i][j] === "number") {
                rows[i][j] = isSomeCalculation(rows, isPivot, i, j, "%")
                    ? formatNumberToPercent(rows[i][j] as number)
                    : rows[i][j].toLocaleString(
                          "en-US",
                          CalculationsWithoutDecimalPart.some(c => isSomeCalculation(rows, isPivot, i, j, c))
                              ? { maximumFractionDigits: 0 }
                              : { maximumFractionDigits: 2 }
                      );
            }
        }
    let index = rows.length - 1;
    while (!rows[index].any()) {
        rows.pop();
        index--;
    }
};

export const removeUnusedColumns = (columns: Array<IExcelTableColumn>, rows: Array<Array<object>>) =>
    columns.slice(0, rows.maxArrayLength());

const isSomeCalculation = (
    rows: Array<Array<string | number>>,
    isPivot: boolean | undefined,
    i: number,
    j: number,
    calculation: string
) => {
    return isPivot
        ? rows[i][2]?.toString().includes(calculation) || rows[i][3]?.toString().includes(calculation)
        : rows[2][j]?.toString().includes(calculation) || rows[3][j]?.toString().includes(calculation);
};
