import React from "react";
import { MDBCardHeader } from "mdbreact";
import { Styled } from "./Styles/styled";

interface IProps {
    text: string;
}

export const DefaultFormHeader = (props: IProps) => (
    <MDBCardHeader style={Styled.DefaultFormHeader}>
        <h3 className="text-center">{props.text}</h3>
    </MDBCardHeader>
);
