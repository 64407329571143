import React, { useState } from "react";
import { DefaultModal } from "../../UI/modals/defaultModal";
import { DefaultButton } from "../../UI/buttons/defaultButton";
import { ButtonStyled } from "../../UI/buttons/buttonStyled";
import { useQuery } from "../store/queryContext";
import { QueryCreationSteps } from "../../UI/stepper/queryCreationSteps";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../common";

interface IProps {
    setActiveStep: (step: QueryCreationSteps) => void;
}

export const NewQueryButton = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {setSaveQueryRequest, dropQuery} = useQuery();
    const history = useHistory();

    const saveQuery = () => {
        setSaveQueryRequest({
            isRequire: true,
            afterSave: () => {
                dropQuery();
                history.push(Routes.EditQuery);
            },
        });
        setIsModalOpen(false);
    }

    const cancelSave = () => {
        setIsModalOpen(false);
        dropQuery();
        props.setActiveStep(QueryCreationSteps.SelectMarketAndDataset);
        history.push(Routes.EditQuery);
    }

    return (
        <>
            <DefaultButton
                size="sm"
                icon="plus-circle"
                text="START A NEW QUERY"
                onClick={() => setIsModalOpen(true)}
                style={ButtonStyled.PrimaryButton}
            />
            <DefaultModal
                isOpen={isModalOpen}
                onСonfirm={saveQuery}
                onCancel={cancelSave}
                bodyText="Do you want to save query?"
                confirmButtonText="YES"
                cancelButtonText="NO"
            />
        </>
    );
}
