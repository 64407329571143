import React, { useState } from "react";
import { IFile } from "../../typings";
import { InputCell, useFiles, useGrid } from "../UI";
import { useUser } from "../../stores";

interface IProps {
    file: IFile;
    index: number;
}

export const DocumentInputCell = (props: IProps) => {
    const { files } = useFiles();
    const [inputValue, setInputValue] = useState(props.file.name);
    const { user } = useUser();
    const { setIsSave } = useGrid();

    const Update = (value: string) => {
        const file = files[props.index];
        setInputValue(value);
        file.newName = value;
        file.isUpdated = true;
        setIsSave(false);
    };

    return (
        <InputCell
            isDisabled={!user.isAdmin}
            defaultValue={props.file.name}
            getValue={Update}
            isFile
            value={inputValue}
            label="Title"
        />
    );
};
