import React, { useState, useEffect } from "react";
import { IFile, AttachmentType } from "../../../typings";
import { FileApi } from "../../../core/api";
import { FileService } from "../../../common";
import { MDBNavItem } from "mdbreact";
import { Styled } from "./style/styled";
import { Tooltip } from "@material-ui/core";
import { IconWithTooltip, Spinner, Preloader } from "../../UI";

export const DocumentList = () => {
    const [files, setFiles] = useState<Array<IFile>>([]);

    const uploadDocumentList = async () => setFiles(await FileApi.getAll(AttachmentType.Documentation));

    useEffect(() => {
        uploadDocumentList();
    }, []);

    const dowloadFile = async (id: number) => {
        const file = await FileApi.get(id);
        FileService.downloadFile(file.data, file.name + file.extension);
    };

    return (
        <>
            <MDBNavItem style={Styled.DocumentList}>
                <ul className="sideBarDocumentList">
                    {files.map(f => (
                        <li key={f.name} onClick={e => dowloadFile(f.id)}>
                            <Tooltip title={f.name}>
                                <span>{f.name}</span>
                            </Tooltip>
                        </li>
                    ))}
                </ul>
            </MDBNavItem>
            {files.length > 0 && (
                <>
                    <div style={Styled.PreloaderWrapper}>
                        <Preloader />
                    </div>
                    <div style={Styled.RefreshButtonWrapper}>
                        <IconWithTooltip
                            className="refreshDocumentButton"
                            icon="redo-alt"
                            onClick={uploadDocumentList}
                            tooltip="Refresh document list"
                        />
                    </div>
                </>
            )}
        </>
    );
};
