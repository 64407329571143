import React, { PropsWithChildren, useState } from "react";
import { notificationContext } from "../..";
;

export const NotificationProvider = (props: PropsWithChildren<{}>) => {
    const [notificationId, setNotificationId] = useState("");

    const value: any = {
        notificationId,
        setNotificationId
    };

    return (
        <notificationContext.Provider value={value}>
            {props.children}
        </notificationContext.Provider>
    );
};
