import { ISignUp } from "../../../typings";
import { UsersApi, ApiError } from "../../../core/api";

export const signUpActions = {
    createUser: async (usersData: ISignUp | undefined) => {
        try {
            await UsersApi.createUser(usersData);
        } catch (e) {
            if (e instanceof ApiError) return await e.response.text();
        }
    },

    updateUser: async (usersData: ISignUp | undefined) => {
        try {
            await UsersApi.updateUser(usersData);
        } catch (e) {
            if (e instanceof ApiError) return await e.response.text();
        }
    },

    updatePassword: async (usersData: ISignUp | undefined) => {
        try {
            await UsersApi.updatePassword(usersData);
        } catch (e) {
            if (e instanceof ApiError) return await e.response.text();
        }
    }
};
