import { httpGet } from "./request/requestApi";
import { ICountry } from "../../components/queries";

const controllerEndPoint = "country";

export class CountryApi {
    static async getAll(): Promise<Array<ICountry>> {
        return await httpGet(`${controllerEndPoint}/get-all`);
    }
}
