import React from "react";
import { FeiaCheckbox } from "./feiaCheckbox";
import { CorrelationCheckbox } from "./correlationCheckbox";
import { SaarCheckbox } from "./saarCheckbox";
import { FeiuCheckbox } from "./feiuCheckbox";

export const SpecificCalculations = () => (
    <>
        <CorrelationCheckbox />
        <SaarCheckbox />
        <FeiaCheckbox />
        <FeiuCheckbox />
    </>
);
