import React, { useState, PropsWithChildren } from "react";
import { MDBInput } from "mdbreact";

interface IProps {
    getValue: (value: string) => void;
    getError?: (value: string) => void;
    label: string;
    type?: string;
    value?: string;
    maxLength: number;
    icon?: string;
    mask?: string;
    maskError?: string;
    isSubmitted?: boolean;
    isRequired?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    isErrorNeeded?: boolean;
    setIsValueValid?: (isValid: boolean) => void;
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}

export const CustomInputField = (props: PropsWithChildren<IProps>) => {
    const [maskIsValid, setMaskIsValid] = useState(true);
    const maskValidation = (value: string) => {
        const mask = new RegExp(props.mask!);
        setMaskIsValid(mask.test(value));
    };

    const getErrorMessage = (): string | undefined => {
        let error: string | undefined;
        if (!props.value?.trim().length) {
            error = `Please fill in ${props.label} field.`;
        }
        if (!maskIsValid) {
            error = props.maskError;
        }
        if (error && props.getError) props.getError(error);
        return error;
    };

    const isValueValid = () => (maskIsValid && props.value?.length && props.value.trim().length > 0) as boolean;

    if (props.setIsValueValid) {
        props.setIsValueValid(isValueValid());
    }
    const getClassName = () =>
        isValueValid() ? (props.isSubmitted ? "is-valid" : "") : props.isSubmitted ? "is-invalid" : "error";

    return (
        <MDBInput
            disabled={props.isDisabled}
            label={props.placeholder}
            className={getClassName()}
            required={props.isRequired}
            type={props.type}
            icon={props.icon}
            value={props.value}
            maxLength={props.maxLength}
            onChange={props.onChange}
            getValue={value => {
                let stringValue = value.toString();
                props.getValue(stringValue);
                if (props.mask) {
                    maskValidation(stringValue);
                }
            }}
        >
            {props.children}
            {(props.isSubmitted || props.isErrorNeeded) && (
                <div className="invalid-feedback d-block">{getErrorMessage()}</div>
            )}
        </MDBInput>
    );
};
