import React from "react";
import { ICalculation } from "../../interfaces/ICalculation";
import { dateRanges } from "../../../../typings";
import { CalculationList } from "./calculationList";
import { CalculationNames, DataSetService } from "../../common";
import { useQuery } from "../../store/queryContext";

interface IProps {
    dateRange: dateRanges;
    dataSetName: string | undefined;
}

export const Calculations = (props: IProps) => {
    const { query } = useQuery();

    const getCommonCalculations = () => {
        return props.dateRange.calculations?.filter((c: ICalculation) => c.isCommon);
    };

    const getNonCommonCalculations = () => {
        return props.dateRange.calculations?.filter(
            (c: ICalculation) => !c.isCommon && (c.calculationName !== CalculationNames.SAAR || query.isSAAR)
        );
    };

    return (
        <>
            {props.dateRange?.calculations?.some(c => c.isCommon) && (
                <CalculationList
                    dataSetName={props.dataSetName}
                    label="Must select one"
                    dateRange={props.dateRange}
                    calculations={getCommonCalculations()}
                />
            )}
            {props.dateRange?.calculations?.some(c => !c.isCommon) && (
                <CalculationList
                    dataSetName={props.dataSetName}
                    label={DataSetService.isRate(props.dataSetName) ? "Must select one" : "Optional"}
                    dateRange={props.dateRange}
                    calculations={getNonCommonCalculations()}
                />
            )}
        </>
    );
};
