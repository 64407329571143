import { httpPost, httpGet, httpDelete, httpPut } from "./request/requestApi";
import { IFile, AttachmentType } from "../../typings";

const controllerEndPoint = "file";

export class FileApi {
    static async create(formData: FormData, isForce: boolean = false): Promise<IFile> {
        formData.append("isForce", isForce.toString());
        return await httpPost(`${controllerEndPoint}/create`, { body: formData });
    }

    static async get(id: number): Promise<IFile> {
        return await httpGet(`${controllerEndPoint}/get?id=${id}`);
    }

    static async getAll(attachmentType: AttachmentType): Promise<Array<IFile>> {
        return await httpGet(`${controllerEndPoint}/get-all?attachmentType=${attachmentType}`);
    }

    static async delete(id: number): Promise<void> {
        return await httpDelete(`${controllerEndPoint}/delete`, { body: { id: id } });
    }

    static async update(file: IFile): Promise<void> {
        await httpPut(`${controllerEndPoint}/update`, { body: file });
    }

    static async updateFilePosition(fileId: number, position: number) {
        await httpPut(`${controllerEndPoint}/updatePosition`, { body: { id: fileId, position: position } });
    }
}
