import React, { useState, useEffect } from "react";
import { MarketSelector } from "./marketSelector";
import { ISelectOption } from "../../interfaces/ISelectOption";
import { RegionApi } from "../../../../core/api";
import { useQuery } from "../../store/queryContext";

export const RegionSelector = () => {
    const { query } = useQuery();
    const [regions, setRegions] = useState<Array<ISelectOption>>([]);

    useEffect(() => {
        (async () => {
            const regions = await RegionApi.getAll();
            const options = new Array<ISelectOption>();
            regions.forEach(r => options.push({ value: r.region_ID, text: r.description, checked: query.region === r.region_ID }));
            setRegions(options);
        })();
    }, []);

    return (
        <MarketSelector title="Region" options={regions} />
    );
};
