import React from "react";
import { ISort } from "../../../../typings";
import { FontPropertySelector } from "./fontPropertySelector";
import { MDBFormInline } from "mdbreact";
import { Styled } from "../styled";

interface IProps {
    sort: ISort;
}

export const FontProperties = (props: IProps) => (
    <MDBFormInline>
        <FontPropertySelector
            style={Styled.FontStyleSelector}
            sort={props.sort}
            propertyName="fontStyle"
            options={[
                { text: "Normal", value: "Normal", checked: true },
                { text: "Bold", value: "Bold" },
                { text: "Italic", value: "Italic" },
                { text: "Bold Italic", value: "BoldItalic" }
            ]}
        />
        <FontPropertySelector
            style={Styled.FontSizeSelector}
            sort={props.sort}
            propertyName="fontSize"
            options={[
                { text: "8", value: "8" },
                { text: "9", value: "9" },
                { text: "10", value: "10", checked: true },
                { text: "12", value: "12" },
                { text: "14", value: "14" },
                { text: "16", value: "16" }
            ]}
        />
    </MDBFormInline>
);
