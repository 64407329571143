import React from "react";
import { DefaultButton, useArticle, Notifications } from "../../..";
import { ArticleApi } from "../../../../../core/api";
import { ArticleTypes } from "../../../../../typings";
//@ts-ignore
import { store } from "react-notifications-component";
import { NotificationMessages } from "../../../notifications/messages";
import { ButtonStyled } from "../../../buttons/buttonStyled";
import { getHtmlTextLength } from "../../../../../common";
import { useEditableArticle } from "../stores/editableArticleContext";

interface IProps {
    isHeaderExists?: boolean;
    articleType: ArticleTypes;
    bodyMaxLength: number;
    titleMaxLength?: number;
}

export const EditorSaveButton = (props: IProps) => {
    const { articles } = useArticle(props.articleType);
    const { setIsSubmited, editableArticle, setEditableArticle } = useEditableArticle(props.articleType);

    const save = async () => {
        var base64 = require('base-64');
        var utf8 = require('utf8');
        if (!isArticleValid()) {
            setIsSubmited(true);
            setEditableArticle({ ...editableArticle });
            return;
        }
        if (editableArticle?.articleId) {
            // Added to encode data to base64
            var headerBytes = utf8.encode(editableArticle.articleHeader ? editableArticle.articleHeader : "");
            var bodyBytes = utf8.encode(editableArticle.articleBody ? editableArticle.articleBody : "");
            editableArticle.articleHeader = base64.encode(headerBytes);
            editableArticle.articleBody = base64.encode(bodyBytes);            
            ///
            await ArticleApi.update(editableArticle);
            const article = articles.find(a => a.articleId === editableArticle.articleId);
            if (article) {
                article.articleBody = editableArticle.articleBody;
                article.articleHeader = editableArticle.articleHeader;
            }
        } else if (editableArticle) {
            editableArticle.articleTypeId = props.articleType;            
            articles.push(editableArticle);
            // Added to encode data to base64
            var headerByte = utf8.encode(editableArticle.articleHeader ? editableArticle.articleHeader : "");
            var bodyByte = utf8.encode(editableArticle.articleBody ? editableArticle.articleBody : "");
            editableArticle.articleHeader = base64.encode(headerByte);
            editableArticle.articleBody = base64.encode(bodyByte);            
            ///
            await ArticleApi.create(editableArticle);
        }
        setIsSubmited(false);
        store.addNotification(Notifications.Successfull(NotificationMessages.ChangeHasBeenSuccessfullySaved));
    };

    const isArticleValid = () =>
        editableArticle?.articleBody?.length &&
        getHtmlTextLength(editableArticle.articleBody) > 0 &&
        getHtmlTextLength(editableArticle.articleBody) <= props.bodyMaxLength &&
        (!props.isHeaderExists ||
            (editableArticle.articleHeader?.length &&
                props.titleMaxLength &&
                getHtmlTextLength(editableArticle.articleHeader) <= props.titleMaxLength));

    return <DefaultButton style={ButtonStyled.PrimaryButton} onClick={save} icon="save" text="Save" />;
};
