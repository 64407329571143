import React from "react";
import { MDBBtn, MDBIcon } from "mdbreact";

interface IProps {
    onClick?: () => void;
    style?: {};
    size?: "sm" | "lg";
    text: string | undefined;
    icon?: string;
    type?: "button" | "reset" | "submit";
    disabled?: boolean;
    className?: string;
    iconStyle?: {};
}

export const DefaultButton = (props: IProps) => (
    <MDBBtn
        flat
        type={props.type}
        onClick={props.onClick}
        style={props.style}
        disabled={props.disabled}
        size={props.size}
        className={props.className}
    >
        {props.icon && <MDBIcon icon={props.icon} className="mr-2" style={props.iconStyle} />}
        {props.text}
    </MDBBtn>
);
