import { MDBRow, MDBCol } from "mdbreact";
import React from "react";
import { Styled } from "../styled";
import { Dataset } from "./dataset";
import { useQuery } from "../store/queryContext";
import { IDataSet } from "../../../typings";
import { QueryCreationSteps, StepError } from "../../UI";

export const Datasets = () => {
    const { query } = useQuery();
    return (
        <>
            {query.dataSets?.any() && (
                <>
                    <h5 style={Styled.Headers}> Dataset </h5>
                    <MDBRow>
                        <MDBCol className="text-justify">
                            Multiple datasets can be selected to display in a single report; additional optional datasets
                            will be highlighted.
                        </MDBCol>
                    </MDBRow>
                </>
            )}
            <MDBRow>
                {query.dataSets.splitIntoTwoGroups().map((d: IDataSet, index: number) => (
                    <MDBCol size="5" key={index}>
                        <Dataset dataSet={d} />
                    </MDBCol>
                ))}
            </MDBRow>
            <StepError step={QueryCreationSteps.SelectMarketAndDataset} />
        </>
    );
};
