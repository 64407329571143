export const CalculationNames = {
    SAAR: "SAAR",
    Change: "Change",
    FEIA: "FEIA",
    FEIU: "FEIU",
    SumPercent: "Sum %",
    RatesOptionsVolume: "Rates/Options Volume",
    RatesSales: "Rates/Sales",
    RatesSalesForecast: "Rates/Sales Forecast",
    SumUnits: "Sum Units",
    DetailUnits: "Detail Units"
};
