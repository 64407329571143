import { httpPost, httpGet, httpDelete, httpPut } from "./request/requestApi";
import { IQuery, IRenameQuery } from "../../components/queries/interfaces";
import { DataSetApi } from ".";
import { DataSetService } from "../../components/queries/common";


const controllerEndPoint = "query";

export class QueryApi {
    static async getAll(userId?: number): Promise<Array<IQuery>> {
        return await httpGet(`${controllerEndPoint}/get-all?userId=${userId}`);
    }

    static async get(queryId: number | undefined): Promise<IQuery> {
        const query = (await httpGet(`${controllerEndPoint}/get?queryId=${queryId}`)) as IQuery;
        const selectedDatasets = DataSetService.getSelected(query.dataSets);
        query.sorts = selectedDatasets.first().sorts;
        for (const dataSet of selectedDatasets) {
            const periodsRange = await DataSetApi.getDateRanges(dataSet, query);
            dataSet.ranges = periodsRange;
        }
        return query;
    }

    static async delete(queryId: number | undefined): Promise<void> {
        await httpDelete(`${controllerEndPoint}/delete?queryId=${queryId}`);
    }

    static async create(query: IQuery): Promise<number> {
        const dataSets = DataSetService.getSelected(query.dataSets);
        DataSetService.createSorts(dataSets, query);
        return await httpPost(`${controllerEndPoint}/create`, {
            body: { ...query, dataSets: dataSets, queryId: undefined }
        });
    }

    static async update(queries: Array<IQuery>): Promise<void> {
        await httpPut(`${controllerEndPoint}/update`, { body: { queries } });
    }

    static async rename(query: IRenameQuery): Promise<void> {
        await httpPost(`${controllerEndPoint}/rename`, { body: query });
    }
}
