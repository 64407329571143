import { IQuery } from "../../components/queries/interfaces/IQuery";
import { IPeriodsRanges } from "../../components/queries/interfaces/IPeriodsRanges";
import { AttributeApi } from ".";
import { httpGet, httpPost } from "./request/requestApi";
import { IDataSet } from "../../typings";

const controllerEndPoint = "dataSet";

export class DataSetApi {
    static async getAll(propertyName?: string, value?: string): Promise<Array<IDataSet>> {
        if (!propertyName) {
            return await httpGet(`${controllerEndPoint}/get-all`);
        }
        const queryParam = propertyName === "Country" ? `country=${value}` : `region=${value}`;
        return await httpGet(`${controllerEndPoint}/get-all?${queryParam}`);
    }

    static async getDateRanges(dataSet: IDataSet, query: IQuery): Promise<IPeriodsRanges> {
        const dataSetName = encodeURIComponent(dataSet.dataSetName ?? "");
        return query.country || query.region
            ? await httpGet(
                  `${controllerEndPoint}/get-date-ranges?${AttributeApi.getQueryParam(query)}&volume=${dataSetName}`
              )
            : await httpGet(`${controllerEndPoint}/get-date-ranges?volume=${dataSetName}`);
    }

    static async getDataSetRelations(selectedDataSet: string | undefined): Promise<Array<string>> {
        return await httpPost(`${controllerEndPoint}/get-dataset-relations`, {
            body: { selectedDataSet: selectedDataSet }
        });
    }
}
