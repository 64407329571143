import { CSSProperties } from "react";

const Headers = {
    color: "#1F1F1F",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center"
};

const Selector = {
    marginBottom: -16,
    width: "115%"
} as CSSProperties;

const TextInCard = {
    marginLeft: "15px",
    marginTop: "5px"
};
const RowsInCalc = {
    width: "100%"
};
const BtnGroups = {
    width: "94%"
};
const CheckBox = {
    paddingLeft: "0px"
};

const VsLabel = {
    marginLeft: "1.25rem",
    marginTop: "auto",
    marginBottom: "auto",
    textAlign: "center",
    color: "var(--gray)"
} as CSSProperties;

const CalculationLabel = {
    width: 125,
    marginBottom: 0,
    fontWeight: 600,
    lineHeight: "1.2rem"
};

export const Styled = {
    Headers,
    Selector,
    TextInCard,
    BtnGroups,
    RowsInCalc,
    CheckBox,
    VsLabel,
    CalculationLabel
};
