import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ButtonStyled } from "./buttonStyled";
import { DefaultButton } from "..";

interface IProps {
    text: string;
    route: string;
    size?: "sm" | "lg";
}

export const RedirectButton = (props: IProps) => {
    const history = useHistory();

    return (
        <Link
            to={props.route}
            onClick={() => {
                if (history.location.pathname === props.route) document.location.reload();
            }}
        >
            <DefaultButton text={props.text} style={ButtonStyled.PrimaryButton} size={props.size} icon="plus-circle" />
        </Link>
    );
};
