const CardHeader = {
    color: "#009a9a"
};

const LoginHeader = {
    marginLeft: "40px",
    color: "white",
    padding: "10px"
};

const WelcomeText = {
    paddingBottom: "30px",
    fontSize: "30px"
};

const Information = {
    fontSize: 16,
    paddingTop: "20px"
};

const ErrorText = {
    paddingBottom: 15
};

const ForgotPassword = {
    fontSize: 12
};

const Logo = {
    marginTop: "13px",
    width: "20%"
};

export const Styled = {
    CardHeader,
    LoginHeader,
    Information,
    WelcomeText,
    Logo,
    ForgotPassword,
    ErrorText
};
