export { };

declare global {
    interface Array<T> {
        first(): T;
        last(): T;
        second(): T;
        max(): T;
        min(): T;
        any(): boolean;
        maxArrayLength(): number;
        splitIntoTwoGroups(): Array<T>;
    }
}

Array.prototype.first = function <T>(): T {
    return this[0];
};

Array.prototype.last = function <T>(): T {
    return this[this.length - 1];
};

Array.prototype.second = function <T>(): T {
    return this[1];
};

Array.prototype.max = function () {
    return Math.max.apply(null, this);
};

Array.prototype.min = function () {
    return Math.min.apply(null, this);
};

Array.prototype.any = function () {
    return this.length > 0;
};

Array.prototype.maxArrayLength = function () {
    return this.reduce((longest, current) => (current.length > longest.length ? current : longest)).length;
};

Array.prototype.splitIntoTwoGroups = function () {
    const half = Math.round(this.length / 2);
    const newArray = [];
    for (let i = 0; i < half; i++) {
        newArray.push(this[i]);
        let index = half + i;
        if (index === 0) index++;
        this[index] && newArray.push(this[index]);
    }
    return newArray;
};
