import React, { useState } from "react";
import { MDBCard, MDBCardBody, MDBContainer } from "mdbreact";
import { accountActions } from "./actions/loginAction";
import { InformationBlock } from "./informationBlock";
import { loginResponseType } from "./types/loginResponseType";
import { LoginError } from "./loginError";
import { useHistory, Link } from "react-router-dom";
import { useUser, ReloadUserContext } from "../../stores";
import { Styled } from "./style/styled";
import { ILoginResponse } from "./types/loginResponse";
import { Captcha } from "./captcha";
import { DefaultFormButton, PasswordField, EmailField, RememberMeCheckbox, Preloader, useNotification, DefaultModal } from "../UI";
import { ILogin } from "../../typings";
import { Constants, useLocalStorage, Routes } from "../../common";
// @ts-ignore
import { store } from "react-notifications-component";
const CryptoJS = require("crypto-js");

export const LoginForm = () => {
    const [rememberEmail, setEmail] = useLocalStorage("email", "");
    const [rememberPwd, setPwd] = useLocalStorage("pwd", "");
    const emailBytes = CryptoJS.AES.decrypt(rememberEmail, 'info-Ward');
    const decEmail = emailBytes.toString(CryptoJS.enc.Utf8) === "" ? "" : emailBytes.toString(CryptoJS.enc.Utf8);
    const pwdBytes = CryptoJS.AES.decrypt(rememberPwd, 'info-Ward');
    const decPwd = emailBytes.toString(CryptoJS.enc.Utf8) === "" ? "" : pwdBytes.toString(CryptoJS.enc.Utf8);
           
    
    const [loginData, setLoginData] = useState<ILogin>({
        email: decEmail,
        password: decPwd,
        rememberme: decEmail !== "" ? true : false
    });
    const [loginAttempts, setLoginAttempts] = useLocalStorage(Constants.AttemptsKey, 0);
    const [firstLoginModal, setFirstLoginModal] = useState(false);
    const [changeCaptchaWarning, setChangeCaptchaWarning] = useState(false);
    const [responseType, setResponseType] = useState<loginResponseType>();
    const [resetToken, setResetToken] = useState("");
    const { setUser } = useUser();
    const history = useHistory();
    const { notificationId } = useNotification();

    const encEmail = CryptoJS.AES.encrypt(loginData.email, 'info-Ward');    
    const encPwd = CryptoJS.AES.encrypt(loginData.password, 'info-Ward');
    
    

    const successfulLogin = () => {
        ReloadUserContext(setUser);
        setLoginAttempts(0);        
        history.push(Routes.Home);
    };

    const setRememberMe = () => {
        setEmail(encEmail.toString());
        setPwd(encPwd.toString());          
    };
    const clearRememberMe = () =>{
        setEmail("");
        setPwd(""); 
    }    
    
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        store.removeNotification(notificationId);
        event.preventDefault();
        if (loginAttempts >= Constants.AttemptsMaxCount) {
            setChangeCaptchaWarning(true);
        } else {
            const response = await accountActions.login(loginData);
            const successfulResponse = response as ILoginResponse;
            if (successfulResponse.isFirstLogin !== undefined) {
                if(loginData.rememberme)
                {
                    setRememberMe();
                }
                else
                {
                    clearRememberMe();
                }
                if (successfulResponse.isFirstLogin) {
                    setFirstLoginModal(true);
                    setResetToken(successfulResponse.token);
                } else {
                    successfulLogin();
                }
            } else {
                setResponseType(response as loginResponseType);
                setLoginAttempts(loginAttempts + 1);
                setLoginData({ ...loginData, password: "" });
            }
        }
    };
    return (
        <MDBContainer className="d-flex justify-content-center">
            <MDBCard className="w-50">
                <MDBCardBody>
                    <form onSubmit={onSubmit} className="pt-4">
                        {responseType !== undefined && <LoginError errorType={responseType} />}
                        <EmailField
                            value={loginData.email}
                            getValue={(email: string) =>
                                setLoginData({
                                    ...loginData,
                                    email: email
                                })
                            }
                        />
                        <PasswordField
                            value={loginData.password}
                            label={"Password"}
                            getValue={(password: string) =>
                                setLoginData({
                                    ...loginData,
                                    password: password
                                })
                            }
                        />
                        <div className="remeberme-forgotpassword">
                        <RememberMeCheckbox
                            id="chkrememberme"
                            label="Remember me"
                            onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                                    setLoginData({
                                    ...loginData,
                                    rememberme: e.currentTarget.checked
                                })
                            }}
                            checked={loginData.rememberme}
                        />
                        <p className="ml-auto mb-0" style={Styled.ForgotPassword}>
                            <Link to={Routes.ForgotPassword}>Forgot password?</Link>
                        </p>
                        </div>
                        

                        <Preloader />
                        
                        {loginAttempts >= Constants.AttemptsMaxCount && (
                            <Captcha
                                clicked={changeCaptchaWarning}
                                verifyCallback={() => {
                                    setLoginAttempts(0);
                                    setChangeCaptchaWarning(false);
                                }}
                            />
                        )}
                        <DefaultFormButton text="SIGN IN" />
                        <DefaultModal
                            isOpen={firstLoginModal}
                            onCancel={successfulLogin}
                            onСonfirm={() =>
                                history.push(Routes.ResetPassword, {
                                    token: resetToken,
                                    email: loginData.email
                                })
                            }
                            cancelButtonText="Cancel"
                            bodyText="Your account has been activated. Please reset your password."
                            confirmButtonText="Reset Password"
                        />
                    </form>
                    <InformationBlock />
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};
