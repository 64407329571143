import React, { useState } from "react";
import { MDBContainer, MDBCard, MDBCardBody, MDBAlert } from "mdbreact";
import { IResetPassword } from "../../typings";
import { ValidatePasswordFormat, ValidatePasswords } from "./validators/passwordsValidator";
import { AccountApi } from "../../core/api";
import { useHistory } from "react-router-dom";
import { Routes } from "../../common";
import { Styled } from "./styles/styled";
import { PasswordField, DefaultFormButton, Notifications, NotificationMessages } from "../UI";
import { DefaultFormHeader } from "../baseNavigation";
import { useUser } from "../../stores";
// @ts-ignore
import { store } from "react-notifications-component";

interface IState {
    token: string;
    email: string;
}

export const ResetPasswordForm = () => {
    const [resetPasswordData, setResetPasswordData] = useState<IResetPassword>({});
    const { setUser } = useUser();
    const [error, setError] = useState<string | undefined>(undefined);
    const history = useHistory();
    const [pwderror, setPwdError] = useState<string | undefined>(undefined);
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const errors = ValidatePasswords(resetPasswordData.password, resetPasswordData.confirmPassword);
        setError(errors);
        if (errors !== undefined) {
            setResetPasswordData({
                ...resetPasswordData,
                password: "",
                confirmPassword: ""
            });
            return;
        }
        updateResetPasswordData(resetPasswordData);
        await AccountApi.resetPassword(resetPasswordData);
        setUser({ isLoaded: true });
        store.addNotification(Notifications.Successfull(NotificationMessages.PasswordSuccessfullyChanged));
        history.push(Routes.Login);
    };

    const updateResetPasswordData = (resetPasswordData: IResetPassword) => {
        const state = history.location.state as IState;
        if (state !== undefined) {
            resetPasswordData.token = state.token;
            resetPasswordData.email = state.email;
        } else {
            const params = new URLSearchParams(window.location.search);
            resetPasswordData.email = params.get("userEmail")?.toString();
            resetPasswordData.token = params
                .get("resetToken")
                ?.toString()
                .split(" ")
                .join("+");
        }
    };

    return (
        <MDBContainer className="d-flex justify-content-center" style={Styled.ResetPasswordFormContainer}>
            <MDBCard className="w-50">
                <DefaultFormHeader text="Create new password" />
                <MDBCardBody>
                    <form onSubmit={onSubmit}>
                        <PasswordField
                            value={resetPasswordData.password}
                            label="New Password"
                            getValue={(password: string) =>
                                setResetPasswordData({
                                    ...resetPasswordData,
                                    password: password
                                })
                            }
                            onChange={async (e: React.FormEvent<HTMLInputElement>) => {                                 
                                const pwdFormatError = ValidatePasswordFormat(e.currentTarget.value);
                                setPwdError(pwdFormatError);
                            }}                        
                        />
                        {pwderror && <MDBAlert color="info">{
                            <><b>Password Requirements</b>
                            <ul style={Styled.ListPadding}>
                                <li>Password must be at least 8 characters</li>
                                <li>Must contain a numeric</li>
                                <li>Must contain a capital letter</li>
                                <li>Can include symbols !, #, $, %</li>
                            </ul></>}
                            </MDBAlert>}
                        <PasswordField
                            label="Confirm New Password"
                            value={resetPasswordData.confirmPassword}
                            getValue={(password: string) =>
                                setResetPasswordData({
                                    ...resetPasswordData,
                                    confirmPassword: password
                                })
                            }
                        />
                        {error && <MDBAlert color="danger">{error}</MDBAlert>}
                        <DefaultFormButton text="Reset password" />
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};
