import { MDBRow, MDBCol } from "mdbreact";
import { SortingOrder } from "./sortingOrder";
import React, { useState } from "react";

export const StandartSortAndDisplayOptions = () => {
    const [collapseId, setCollapseId] = useState<number | undefined>(0);

    return (
        <>
            <MDBRow>
                <MDBCol>
                    <span>
                        Choose Show Total Only or choose attribute(s) to display in report by selecting one or more
                        buttons and choose sort order using right icon to move up and down.
                    </span>
                </MDBCol>
            </MDBRow>
            <SortingOrder collapseId={collapseId} setCollapseId={setCollapseId} />
        </>
    );
};
