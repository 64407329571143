import { DataSetService, Messages, QueryErrorService } from "..";
import { QueryCreationSteps } from "../../../UI";
import { IQuery, IQueryError } from "../../interfaces";

const validate = (query: IQuery, errors: Array<IQueryError>) => {
    if (!DataSetService.anySelected(query.dataSets)) {
        QueryErrorService.unshiftError(errors, QueryCreationSteps.SelectMarketAndDataset, Messages.PleaseChooseADataset);
    }
};

export const DataSetValidationService = {
    validate
}