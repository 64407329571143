import React from "react";
import { QueryMode } from "../..";
import { useQuery } from "../../store/queryContext";
import { ICalculation } from "../../interfaces/ICalculation";
import { MDBInput } from "mdbreact";
import { dateRanges } from "../../../../typings";
import { CalculationService } from "./calculationService";
import { QueryErrorService } from "../../common";
import "./style.css";

interface IProps {
    calculation: ICalculation;
    dateRange: dateRanges;
    dataSetName: string | undefined;
}

export const CalculationCheckBox = (props: IProps) => {
    const { queryMode, query, setQuery, errors, setErrors } = useQuery();
    return (
        <MDBInput
            containerClass="calculation-check-box"
            size="sm"
            label={props.calculation.calculationName}
            type="checkbox"
            id={Math.random().toString(6)}
            onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                props.calculation.isSelected = e.currentTarget.checked;
                setErrors(QueryErrorService.filterStepErrors(errors));
                await CalculationService.updateDateRangeCalculations(props.dateRange, props.dataSetName);
                setQuery({ ...query });
            }}
            disabled={queryMode === QueryMode.View || !props.calculation.isSelectedAllowed}
            checked={props.calculation.isSelected}
        />
    );
};
