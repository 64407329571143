import React from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { useQuery } from "../../store/queryContext";
import { QueryService } from "../../common";

export const CurrentDatesTable = () => {
    const { query } = useQuery();
    const columns = query.dataSets
        ?.filter(d => d.isSelected)
        .map((d, index) => {
            const currentQuarter = Math.ceil(d.ranges?.currentPeriod ? +d.ranges?.currentPeriod / 3 : 0);
            return (
                <tr key={index}>
                    <th>{QueryService.getMarket(query)}</th>
                    <th>{d.ranges?.volume}</th>
                    <th>{d.ranges?.currentYear}</th>
                    <th>{d.ranges?.currentYear ? +d.ranges?.currentYear - 1 : 0}</th>
                    <th>{d.ranges?.currentModelYear}</th>
                    <th>{d.ranges?.currentModelYear ? +d.ranges?.currentModelYear - 1 : 0}</th>
                    <th>{currentQuarter}</th>
                    <th>{currentQuarter !== 1 ? currentQuarter - 1 : 4}</th>
                    <th>{d.ranges?.currentPeriod}</th>
                    <th>
                        {d.ranges?.currentPeriod
                            ? +d.ranges?.currentPeriod !== 1
                                ? +d.ranges?.currentPeriod - 1
                                : 12
                            : 0}
                    </th>
                </tr>
            );
        });
    return (
        <MDBTable>
            <MDBTableHead>
                <tr>
                    <th>Market</th>
                    <th>Dataset</th>
                    <th>Current Year</th>
                    <th>Prior Year</th>
                    <th>Current Model Year</th>
                    <th>Prior Model Year</th>
                    <th>Current Quarter</th>
                    <th>Prior Quarter</th>
                    <th>Current Month</th>
                    <th>Prior Month</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>{columns}</MDBTableBody>
        </MDBTable>
    );
};
