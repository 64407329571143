const DefaultParagraph = {
    fontSize: "30px",
    color: "#108393",
    fontWeight: 900,
    margin: "30px 0 30px 0"
};

export const Styled = {
    DefaultParagraph
};
