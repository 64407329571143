import React from "react";
import {
    UsersProvider,
    UserManagementTable
} from "../components/usersManagement";
import { MDBContainer } from "mdbreact";
import { RedirectButton, Spinner, ButtonStyled } from "../components/UI";
import { Routes } from "../common";

export const UserManagementPage = () => (
    <UsersProvider>
        <Spinner />
        <MDBContainer>
            <MDBContainer style={ButtonStyled.ButtonContainer}>
                <RedirectButton
                    text="ADD A NEW USER"
                    route={Routes.CreateUser}
                />
            </MDBContainer>
            <UserManagementTable />
        </MDBContainer>
    </UsersProvider>
);
