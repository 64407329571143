import React from "react";
import { MDBNav } from "mdbreact";
import { SideBarLink } from "./sideBarLink";
import { SideBarLogo } from "./sideBarLogo";
import { Routes } from "../../../common";
import { Styled } from "./style/styled";
import { SideBarCollapse } from "./collapse/sideBarCollapse";

interface IProps {
    isSideBarOpened: boolean;
}

export const AdminSideBar = (props: IProps) => {
    return (
        <MDBNav className="flex-column" style={Styled.SideBarContainer(props.isSideBarOpened)}>
            <SideBarLink
                url={Routes.UsersManagement}
                text="User Management"
                icon="users"
                isSideBarOpened={props.isSideBarOpened}
            />
            <SideBarLink
                url={Routes.RolesManagement}
                text="Role Management"
                icon="key"
                isSideBarOpened={props.isSideBarOpened}
            />
            <SideBarCollapse isSideBarOpen={props.isSideBarOpened} header="Report Management">
                <SideBarLink
                    url={Routes.NewQuery}
                    text="New Query"
                    icon="plus-circle"
                    isSideBarOpened={props.isSideBarOpened}
                />
                <SideBarLink
                    url={Routes.MyQueries}
                    text="My Queries"
                    icon="archive"
                    isSideBarOpened={props.isSideBarOpened}
                />
            </SideBarCollapse>
            <SideBarCollapse isSideBarOpen={props.isSideBarOpened} header="Content Management">
                <SideBarLink
                    url={Routes.LandingPageManagement}
                    text="Landing Page"
                    icon="home"
                    isSideBarOpened={props.isSideBarOpened}
                />
                <SideBarLink
                    url={Routes.DocumentManagement}
                    text="Documentation"
                    icon="file"
                    isSideBarOpened={props.isSideBarOpened}
                />
                <SideBarLink
                    url={Routes.HelpManagement}
                    text="Help"
                    icon="question-circle"
                    isSideBarOpened={props.isSideBarOpened}
                />
                <SideBarLink
                    url={Routes.ContactUsManagement}
                    text="Contact Us"
                    icon="envelope"
                    isSideBarOpened={props.isSideBarOpened}
                />
                <SideBarLink
                    url={Routes.FooterManagement}
                    text="Footer"
                    icon="copyright"
                    isSideBarOpened={props.isSideBarOpened}
                />
            </SideBarCollapse>
            <hr style={Styled.Hr} />
            <SideBarLogo isSideBarOpened={props.isSideBarOpened} />
        </MDBNav>
    );
};
