import React from "react";
import { zIndex } from "..";
import { QueryCreationSteps } from "./queryCreationSteps";
import { SaveQueryButton } from "../../queries/buttons/saveQueryButton";
import { CurrentDatesPopper } from "../../queries";
import { ResetStepButton } from "./resetStepButton";

interface IProps {
    step: QueryCreationSteps;
}

export const StepperMenu = (props: IProps) => {
    return (
        <div
            style={{ zIndex: zIndex.Content, top: "54px" }}
            className="sticky-top navbar navbar-expand-lg navbar-dark d-flex justify-content-between white"
        >
            <SaveQueryButton />
            <ResetStepButton step={props.step} />
            <CurrentDatesPopper />
        </div>
    );
};
