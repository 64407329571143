import React, { PropsWithChildren, useEffect, useState } from "react";
import { IFile, AttachmentType } from "../../../../typings";
import { FileApi } from "../../../../core/api";
import { filesContext } from "../..";

interface IProps {
    attachmentType: AttachmentType
}

export const FilesProvider = (props: PropsWithChildren<IProps>) => {
    const [files, setFiles] = useState<Array<IFile>>([]);

    const value: any = {
        files,
        setFiles,
        attachmentType: props.attachmentType
    };

    useEffect(() => {
        (async function loadFiles(): Promise<void> {
            setFiles(await FileApi.getAll(props.attachmentType));
        })();
    }, []);

    return (
        <filesContext.Provider value={value}>
            {props.children}
        </filesContext.Provider>
    );
};