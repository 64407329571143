import React, { useState } from "react";
import { IconWithTooltip, useGrid } from "../../UI";
import { QueryApi } from "../../../core/api/queryApi";
import { IQuery } from "../interfaces/IQuery";
import { ReplaceQueryModal } from "../queryNameInput/replaceQueryModal";
import { useQuery } from "../store/queryContext";
import { useUser } from "../../../stores";
import { Styled } from "./styled";

interface IProps {
    query?: IQuery;
}

export const RenameQueryButton = (props: IProps) => {
    const { user } = useUser();
    const { setUserQueries, userQueries } = useQuery();
    const [isSaveRequireConfirmation, setIsSaveRequireConfirmation] = useState<boolean>(false);
    const { setIsSave } = useGrid();

    const rename = async (query: IQuery, isForce: boolean = false) => {
        const trimQueryName = query.queryName?.trim() as string;
        setIsSave(true);
        if (query.isUpdated && trimQueryName !== "") {
            if (isForce || !userQueries.find(q => q.queryName === trimQueryName && q.queryId !== query.queryId)) {
                try {
                    await QueryApi.rename({
                        queryId: query.queryId as number,
                        queryName: trimQueryName,
                        userId: query.userId as number,
                        isForce
                    });
                    setUserQueries(await QueryApi.getAll(user.id));
                } catch {
                    setIsSaveRequireConfirmation(true);
                }
            } else {
                setIsSaveRequireConfirmation(true);
            }
        }
    };
    
    return (
        <>
            <IconWithTooltip
                tooltip="Save"
                icon="save"
                onClick={() => rename(props.query as IQuery)}
                style={Styled.RenameQueryButtonIcon}
            />
            <ReplaceQueryModal
                isOpen={isSaveRequireConfirmation}
                onCancel={async () => {
                    setIsSaveRequireConfirmation(false);
                    setUserQueries(await QueryApi.getAll(user.id));
                }}
                onConfirm={async () => {
                    await rename(props.query as IQuery, true);
                    setIsSaveRequireConfirmation(false);
                }}
            />
        </>
    );
};
