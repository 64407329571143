import React, { useState } from "react";
import { MDBContainer, MDBCard, MDBCardBody } from "mdbreact";
import { AccountApi, ApiError } from "../../core/api";
import { Styled } from "./styles/styled";
import {
    EmailField,
    DefaultFormButton,
    Notifications,
    NotificationMessages
} from "../UI";
// @ts-ignore
import { store } from "react-notifications-component";
import { DefaultFormHeader } from "../baseNavigation";
import { useHistory } from "react-router-dom";
import { Routes } from "../../common";

export const ForgotPasswordForm = () => {
    const [email, setEmail] = useState<string>();
    const history = useHistory();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState<boolean>();
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitted(true);
        if (!isEmailValid) {
            return;
        }
        try {
            await AccountApi.forgotPassword(email);
        } catch (e) {
            if (e instanceof ApiError && e.response.status === 406) {
                store.addNotification(
                    Notifications.Warning(
                        NotificationMessages.UserAccountHasBeenDisabled
                    )
                );
                history.push(Routes.Login);
                return;
            }
        }
        store.addNotification(
            Notifications.Successfull(NotificationMessages.ResetLinkWasSent)
        );
        history.push(Routes.Login);
    };

    return (
        <MDBContainer
            className="d-flex justify-content-center"
            style={Styled.ForgotPasswordFormContainer}
        >
            <MDBCard className="w-50">
                <DefaultFormHeader text="Write your email" />
                <MDBCardBody>
                    <form onSubmit={onSubmit} noValidate>
                        <EmailField
                            isSubmited={isSubmitted}
                            value={email}
                            getValue={setEmail}
                            setIsValueValid={setIsEmailValid}
                        />
                        <DefaultFormButton text="Send reset link" />
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};
