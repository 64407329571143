import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import React, { useState } from "react";
import { DateRangesContainer } from "./dateRangesContainer";
import { useQuery } from "../../store/queryContext";
import { IDataSet } from "../../../../typings";
import { DataSetService } from "../../common";

export const ChooseDatesAndCalculations = () => {
    const { query } = useQuery();
    const [collapseId, setCollapseId] = useState<number | undefined>(0);

    const getDataSets = () => {
        return query.isCorrelation
            ? [DataSetService.getFirstSelected(query.dataSets)]
            : DataSetService.getSelected(query.dataSets);
    };

    return (
        <MDBContainer>
            <MDBRow id="Daterange">
                <MDBCol className="mt-3 mb-1">
                    {getDataSets()?.map((d: IDataSet, index: number) => (
                        <DateRangesContainer
                            dataSet={d}
                            index={index}
                            setCollapseId={setCollapseId}
                            collapseId={collapseId}
                            key={d.dataSetName}
                        />
                    ))}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};
