import React, { PropsWithChildren, useState } from "react";
import { gridContext } from "./gridContext";

export const GridProvider = (props: PropsWithChildren<{}>) => {
    const [isValid, setIsValid] = useState(true);
    const [isSave, setIsSave] = useState(false);

    const value: any = {
        isValid,
        setIsValid,
        isSave,
        setIsSave
    };

    return (
        <gridContext.Provider value={value}>
            {props.children}
        </gridContext.Provider>
    );
};
