export const TableColumns = [
    {
        label: "Name",
        field: "queryName",
        sort: "disabled"
    },
    {
        label: "Last modified date",
        field: "lastModifiedDate",
        sort: "disabled"
    },
    {
        label: "Copy",
        field: "copy",
        sort: "disabled"
    },
    {
        label: "Actions",
        field: "actions",
        sort: "disabled"
    }
];
