import { IQuery } from "../../queries/interfaces/IQuery";
import { DataSetFilterService, DataSetService, SortService, CorrelationConstants } from "../../queries/common";
import { DatesService } from "../../queries";

const resetNarrowSearchStep = (query: IQuery, originQuery: IQuery) => {
        DataSetService.getSelected(query.dataSets).forEach(d => {
            d.isTotalIndustry = true;
            d.attributes?.forEach(a => {
                DataSetFilterService.deleteFilter(d, a.description);
                a.isSelected = false;
                a.options = [];
            });
        });
};

const resetChooseSortAndDisplayOptionsStep = (query: IQuery, originQuery: IQuery) => {
    query.sorts = SortService.getSorts(query.dataSets);
    query.isShowTotalOnly = true;
};

const resetChooseDatesAndCalculationsStep = async (
    query: IQuery,
    originQuery: IQuery,
    setQuery: (query: IQuery) => void
) => {
        query.correlationStep = CorrelationConstants.MinCorrelationStep;
        for (const dataSet of DataSetService.getSelected(query.dataSets)) {
            dataSet.dateRanges = [await DatesService.getDateRange(dataSet, query.country)];
        }
        setQuery({ ...query });    
};

const resetPreviewAndExportStep = async (query: IQuery, originQuery: IQuery) => {
    query.isPivot = false;
    query.isVisualization = false;
};

export const ResetStepService = {
    resetNarrowSearchStep,
    resetChooseSortAndDisplayOptionsStep,
    resetChooseDatesAndCalculationsStep,
    resetPreviewAndExportStep
};
