import React from "react";
import { loginResponseType } from "./types/loginResponseType";
import { errorMessages } from "./messages/messages";
import { MDBAlert } from "mdbreact";
import { Styled } from "./style/styled";

interface IProps {
    errorType: loginResponseType | undefined;
}

export const LoginError = (props: IProps) => {
    const GetErrorMessage = () => {
        switch (props.errorType) {
            case loginResponseType.disabled:
                return errorMessages.accountIsDisabled;
            case loginResponseType.wrongCredentials:
                return errorMessages.wrongCredentials;
        }
    };
    return (
        <div style={Styled.ErrorText}>
            <MDBAlert color="danger">{GetErrorMessage()}</MDBAlert>
        </div>
    );
};
