import { getFieldLongName, getFieldName } from "../fieldNamesCreator";
import { SortDescriptionTypes } from "../../sortDisplay/sortDescriptionTypes";
import { ISort, IDataSet } from "../../../../typings";
import { IQuery } from "../../interfaces/IQuery";

const getSorts = (dataSets: Array<IDataSet>) => {
    const attributes = dataSets.filter(d => d.isSelected).map(d => d.attributes);
    const commonAttributes = attributes.any()
        ? attributes.reduce((a, b) => a?.filter(c => b?.some(d => d.description == c.description)))
        : [];
    return commonAttributes?.map(a => ({
        criterium: getFieldLongName(getFieldName(a.description)),
        description: a.description,
        descriptionType: SortDescriptionTypes.Long
    }));
};

const updateFirstSelected = (
    isSelected: boolean,
    sort: ISort,
    setDescriptionType: (sortDescriptionTypes: SortDescriptionTypes | undefined) => void,
    setQuery: (query: IQuery) => void,
    query: IQuery
) => {
    if (isSelected) {
        const newIsSelectedSort =  query.sorts?.filter(el => el.isSelected);
        const newIsFirstSelected = newIsSelectedSort?.last(); 

        if (query.sorts?.some(s => s.isFirstSelected)) {
            const isFirstSelected = query.sorts?.find(s => s.isFirstSelected);
            if (isFirstSelected) {
                isFirstSelected.isFirstSelected = false;
                if (isFirstSelected.descriptionType == SortDescriptionTypes.No)
                    isFirstSelected.descriptionType = SortDescriptionTypes.Long;
                setQuery({ ...query });
            }
        }
        if(newIsFirstSelected){
            newIsFirstSelected.isFirstSelected = true;                    
                if (newIsFirstSelected?.isFirstSelected && newIsFirstSelected?.descriptionType === SortDescriptionTypes.No)
                    newIsFirstSelected.descriptionType = SortDescriptionTypes.Long;
            setQuery({ ...query });
        }
        setDescriptionType(sort.descriptionType);
    } else {
        sort.isFirstSelected = false;
        sort.descriptionType = SortDescriptionTypes.Long;
        setQuery({ ...query });
        const newIsSelectedSort =  query.sorts?.filter(el => el.isSelected);
        const newIsFirstSelected = newIsSelectedSort?.last(); 
        if(newIsFirstSelected){
            newIsFirstSelected.isFirstSelected = true;                    
                if (newIsFirstSelected?.isFirstSelected && newIsFirstSelected?.descriptionType === SortDescriptionTypes.No)
                    newIsFirstSelected.descriptionType = SortDescriptionTypes.Long;
            setQuery({ ...query });
        }
    }
};

export const SortService = {
    getSorts,
    updateFirstSelected
};
