import { DataSetNames } from "../../../../common/dataSetNames";
import { IQuery } from "../../interfaces/IQuery";

const isSpecificationsSelected = (query: IQuery): boolean =>
    query.dataSets?.some(d => d.dataSetName === DataSetNames.Specifications && d.isSelected);

const isSpecificSpecificationReport = (query: IQuery): boolean =>
    isSpecificationsSelected(query) && !query.isFEIA && !query.isFEIU;

export const SpecificationService = {
    isSpecificationsSelected,
    isSpecificSpecificationReport
};
