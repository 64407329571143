import { IDataSet } from "../../../../typings";
import { IQuery } from "../../interfaces";
import { DataSetService } from "..";

const updateCorrelationCheckbox = (query: IQuery) => {
    query.isCorrelation = query.isCorrelation && query.dataSets?.some(d => d.isSelected);
};

const updateCorrelationRelatedDataSetsAvailability = (query: IQuery) => {
    const selectedDataSets = DataSetService.getSelected(query.dataSets);
    if (query.isCorrelation && selectedDataSets.length > 1) {
        const allowSelectedDataSets = query.dataSets?.filter(d => d.isSelectAllowed);
        allowSelectedDataSets?.forEach(
            d => (d.isSelectAllowed = selectedDataSets.find(sd => sd.dataSetName === d.dataSetName) !== undefined)
        );
    }
};

const updateCorrelationRelatedDataSetDateRanges = (dataSets?: Array<IDataSet>) => {
    DataSetService.getSecondSelected(dataSets).dateRanges = DataSetService.getFirstSelected(dataSets).dateRanges;
};


export const CorrelationService = {
    updateCorrelationCheckbox,
    updateCorrelationRelatedDataSetsAvailability,
    updateCorrelationRelatedDataSetDateRanges
};
