import React from "react";
import { MDBInput } from "mdbreact";
import { useQuery } from "../store/queryContext";
import { chartType } from "./types/chartTypes";
import { VisualizationAvailabilityService } from "./services/visualizationAvailabilityService ";

export const ChartTypeSelect = () => {
    const { query, setQuery } = useQuery();

    const getSpecialTypeLabel = () =>
        `${
            query.isCorrelation
                ? "Scatter"
                : VisualizationAvailabilityService.isCombinedChartAvailable(query)
                ? "Combined"
                : "Combination"
        } chart`;

    const shouldSpecialChartBeEnabled = () =>
        query.isCorrelation ||
        VisualizationAvailabilityService.isCombinedChartAvailable(query) ||
        VisualizationAvailabilityService.isCombinationChartAvailable(query);

    return (
        <>
            <MDBInput
                onClick={() => setQuery({ ...query, chartType: chartType.bar })}
                checked={query.chartType === chartType.bar}
                disabled={VisualizationAvailabilityService.isCombinedChartAvailable(query)}
                label="Bar chart"
                type="radio"
                id={`barChartRadio`}
                containerClass="mr-5"
            />
            <MDBInput
                onClick={() => setQuery({ ...query, chartType: chartType.line })}
                disabled={VisualizationAvailabilityService.isCombinedChartAvailable(query)}
                checked={query.chartType === chartType.line}
                label="Line chart"
                type="radio"
                id={`lineChartRadio`}
                containerClass="mr-5"
            />
            <MDBInput
                onClick={() => setQuery({ ...query, chartType: chartType.special })}
                disabled={!shouldSpecialChartBeEnabled()}
                checked={query.chartType === chartType.special}
                label={getSpecialTypeLabel()}
                type="radio"
                id={`combinedChartRadio`}
                containerClass="mr-5"
            />
        </>
    );
};
