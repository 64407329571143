import React from "react";
import { Styled } from "./styled";
import { QueryCreationSteps } from "./queryCreationSteps";
import { useQuery } from "../../queries/store/queryContext";

interface IProps {
    activeStep: QueryCreationSteps;
    index: number;
    label: string;
}

export const Label = (props: IProps) => {
    const { errors } = useQuery();
    return (
        <h1 style={Styled.PrimaryHeader(props.activeStep === props.index)}>
            <div className={errors?.some(e => e.step === props.index) ? "text-danger" : ""}>{props.label}</div>
        </h1>
    );
};
