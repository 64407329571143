import React from "react";
import { MDBContainer } from "mdbreact";
import { RedirectButton, Article, ButtonStyled } from "../components/UI";
import { Routes } from "../common";
import { ArticleTypes } from "../typings";

export const HomePage = () => (
    <MDBContainer>
        <MDBContainer style={ButtonStyled.ButtonContainer}>
            <RedirectButton text="START A NEW QUERY" route={Routes.NewQuery} />
        </MDBContainer>
        <Article articleType={ArticleTypes.News} isHeaderNeeded />
    </MDBContainer>
);
