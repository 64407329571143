import React from "react";
import { IRoleContext } from "./IRoleContext";

export const roleContext = React.createContext<IRoleContext>({
    roles: [],
    setRoles: (): void => {},
    role: {},
    setRole: (): void => {},
    countries: [],
    setCountries: (): void => {},
    regions: [],
    setRegions: (): void => {}
});

export const useRole = () => {
    const context = React.useContext(roleContext);
    if (!context) throw new Error("Roles context has not been initialized.");
    return context;
};
