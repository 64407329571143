import { httpGet, httpPost, httpPut } from "./request/requestApi";
import { IArticle } from "../../typings";

const controllerEndPoint = "articles";

export class ArticleApi {
    static async getAll(): Promise<Array<IArticle>> {
        return await httpGet(`${controllerEndPoint}/get-all`);
    }

    static async update(article: IArticle): Promise<IArticle> {
        return await httpPut(`${controllerEndPoint}/update/`, {
            body: article
        });
    }

    static async create(article: IArticle): Promise<IArticle> {
        return await httpPost(`${controllerEndPoint}/create/`, {
            body: article
        });
    }
}
