import { CorrelationValidationService, DataSetValidationService } from "..";
import { QueryCreationSteps } from "../../../UI";
import { IQueryError } from "../../interfaces";
import { IQuery } from "../../interfaces/IQuery";
import { DateRangesValidationService, DataSetService } from "..";

const validate = (query: IQuery, errors: Array<IQueryError>, step?: QueryCreationSteps) => {
    DataSetValidationService.validate(query, errors);
    CorrelationValidationService.validate(query, errors, step);
    DateRangesValidationService.validate(query, errors, step);
}

const isValid = (query: IQuery) => {
    let selectedDataSets = DataSetService.getSelected(query.dataSets);
    if (!selectedDataSets || !selectedDataSets.any()) return false;
    if (query.isCorrelation) selectedDataSets = [DataSetService.getFirstSelected(selectedDataSets)];
    return selectedDataSets.every(d => d.dateRanges?.every(dr => DateRangesValidationService.isValid(dr, query)));
};

export const QueryValidationService = {
    validate,
    isValid
}