import React from "react";
import { MDBInput } from "mdbreact";

interface IProps {
    id: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    checked?: boolean;
}

export const QueryCheckbox = (props: IProps) => {
    return (
        <MDBInput
            type="checkbox"
            id={props.id}
            onChange={props.onChange}
            checked={props.checked}
        />
    );
};