import React from "react";
import { datePeriod } from "../../../../../typings";
import { MDBIcon } from "mdbreact";
import { IPeriodsRanges } from "../../../interfaces/IPeriodsRanges";
import { YearsSelector } from "../selectors/yearsSelector";

interface IProps {
    text: string;
    from: string | undefined;
    to: string | undefined;
    isStart?: boolean;
    period: datePeriod | undefined;
    setter: (year: string | undefined) => void;
    value?: string;
    ranges?: IPeriodsRanges;
    isModelYear?: boolean;
}

export const YearsPicker = (props: IProps) => (
    <YearsSelector
        from={props.from}
        to={props.to}
        isStart={props.isStart}
        isModelYear={props.isModelYear}
        period={props.period}
        setter={props.setter}
        value={props.value}
        ranges={props.ranges}
    />
);
