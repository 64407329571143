import React from "react";
import { DefaultPageHeader } from "../components/baseNavigation";
import { ForgotPasswordForm } from "../components/passwordManagement";
import { Footer } from "../components/footer";

export const ForgotPasswordPage = () => (
    <>
        <DefaultPageHeader />
        <ForgotPasswordForm />
        <Footer />
    </>
);
