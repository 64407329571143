import React, { PropsWithChildren, useEffect, useState } from "react";
import { contactsContext } from "./contactsContext";
import { IContact } from "../../../typings";
import { ContactApi } from "../../../core/api";

export const ContactsProvider = (props: PropsWithChildren<{}>) => {
    const [contacts, setContacts] = useState<Array<IContact>>([]);

    const value: any = {
        contacts,
        setContacts
    };

    useEffect(() => {
        (async function loadContacts(): Promise<void> {
            setContacts(await ContactApi.getAll());
        })();
    }, []);

    return (
        <contactsContext.Provider value={value}>
            {props.children}
        </contactsContext.Provider>
    );
};
