import { Quarters, Months } from "..";

export class datePeriod {
    startYear?: string;
    startMonth?: Months;
    startQuarter?: Quarters;
    endYear?: string;
    endMonth?: Months;
    endQuarter?: Quarters;
}
