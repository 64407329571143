const ActionsFieldIcon = {
    color: "#999999",
    marginRight: "10px"
};

const Row = (index: number) => {
    return {
        padding: "0 15px 0 15px",
        background: index % 2 === 0 ? "white" : "#F2F2F2",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#DEE2E6"
    };
};

const EmailLink = {
    color: "blue"
};

const GridHeader = {
    border: "1px solid #DEE2E6",
    color: "black",
    background: "#F2F2F2"
};

export const Styled = {
    GridHeader,
    ActionsFieldIcon,
    EmailLink,
    Row
};
