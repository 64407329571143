import React from "react";
import { MDBContainer } from "mdbreact";
import { RoleManagementForm } from "../components/roleManagement";
import { RoleProvider } from "../components/roleManagement/store/roleProvider";

export const CreateRolePage = () => (
    <RoleProvider>
        <MDBContainer>
            <RoleManagementForm />
        </MDBContainer>
    </RoleProvider>
);
