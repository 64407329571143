const Warning = (message: string) => ({
    message: message,
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"]
})

const Successfull = (message: string) => ({
    message: message,
    type: "success",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
        duration: 4000,
        pauseOnHover: true
    }
})

export const Notifications = {
    Warning,
    Successfull
}