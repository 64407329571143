import React from "react";
import { LoginLogo } from "./loginLogo";
import { Styled } from "./style/styled";
import { MDBContainer } from "mdbreact";

export const WelcomeBlock = () => (
    <MDBContainer>
        <LoginLogo />
        <h1 className="text-center" style={Styled.WelcomeText}>
            Data Query
        </h1>
    </MDBContainer>
);
