import React, { useState } from "react";
import {
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBNavbarNav,
    MDBNavItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBIcon
} from "mdbreact";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../stores";
import { Routes, localStorageService } from "../../../common";
import { AccountApi } from "../../../core/api";
import { Styled } from "./Styles/styled";
import { DefaultModal } from "../../UI";
import { Constants } from "../../queries/common/constants";
import "./Styles/headerStyles.css";

export const HeaderUserMenu = () => {
    const { user, setUser } = useUser();
    const [logoutModalIsOpen, setLogoutModalIsOpen] = useState(false);
    const history = useHistory();

    const logout = async () => {
        await AccountApi.logout();
        setUser({ isLoaded: true });
        localStorageService.clearValue(Constants.QueryLocalStorageKey);
        history.push(Routes.Login);
    };

    return (
        <>
            <MDBNavbarNav right style={Styled.HeaderMenuContainer}>
                <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret className="user-name">
                            <MDBIcon icon="user" />
                            &nbsp;&nbsp;{user.userName}&nbsp;
                        </MDBDropdownToggle>
                        <MDBDropdownMenu right>
                            <MDBDropdownItem href={Routes.MyAccount}>My Account</MDBDropdownItem>
                            <MDBDropdownItem divider />
                            <MDBDropdownItem
                                onClick={e => {
                                    e.preventDefault();
                                    setLogoutModalIsOpen(true);
                                }}
                                href="/#"
                            >
                                Log out
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
            </MDBNavbarNav>
            <DefaultModal
                isOpen={logoutModalIsOpen}
                onCancel={() => setLogoutModalIsOpen(false)}
                onСonfirm={logout}
                cancelButtonText="No"
                bodyText="Do you want to log out?"
                confirmButtonText="Yes"
            />
        </>
    );
};
