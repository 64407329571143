import React from "react";
import { DefaultButton, ButtonStyled } from "../UI";
import { useRole } from "./store/roleContext";
import { Constants } from "../queries/common/constants";

interface IProps {
    property: string;
    isEntityProperty: string;
    text: string;
}

export const MarketPermission = (props: IProps) => {
    const { role, setRole, countries, setCountries } = useRole();
    return (
        <DefaultButton
            className="mr-1"
            text={props.text}
            style={role[props.property] ? ButtonStyled.ChoiseSelectedButton : ButtonStyled.ChoiseBaseButton}
            onClick={async () => {
                if (props.property === "isWorldViewAllowed") {
                    role.entityDatasets?.push({
                        datasets: [],
                        entity: props.text,
                        description: props.text,
                        isWorld: true
                    });
                }
                if (
                    props.property === "isUsViewAllowed" &&
                    !role.entityDatasets?.some(ed => ed.entity === Constants.UsCountry)
                ) {
                    if (role.isCountryViewAllowed) {
                        const country = countries.find(c => c.value === Constants.UsCountry);
                        if (country) country.checked = true;
                        setCountries([...countries]);
                    }
                    role.entityDatasets?.push({
                        datasets: [],
                        entity: Constants.UsCountry,
                        description: "U.S.",
                        isCountry: true
                    });
                }
                if (role[props.property] && (props.property !== "isUsViewAllowed" || !role.isCountryViewAllowed)) {
                    role.entityDatasets = role.entityDatasets?.filter(
                        ed =>
                            !ed[props.isEntityProperty] ||
                            (ed.entity === Constants.UsCountry && role.isUsViewAllowed && role.isCountryViewAllowed)
                    );
                }
                role[props.property] = !role[props.property];
                setRole({ ...role });
            }}
        />
    );
};
