import React from "react";
import { IQueryContext } from "./IQueryContext";

export const queryContext = React.createContext<IQueryContext>({
    query: { dataSets: [], sorts: [] },
    setQuery: (): void => { },
    userQueries: [],
    setUserQueries: (): void => { },
    queryMode: 1,
    userId: 0,
    errors: [],
    setErrors: (): void => { },
    updateQuery: (): void => { },
    isSubmitted: false,
    setIsSubmitted: (): void => { },
    saveQueryRequest: { isRequire: false },
    setSaveQueryRequest: (): void => { },
    dropQuery: (): void => { },
});

export const useQuery = () => {
    const context = React.useContext(queryContext);
    if (!context) {
        throw new Error("Query context has not been initialized.");
    }
    return context;
};
