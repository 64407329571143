import React from "react";
import { CustomInputField } from "./customInputField ";
import { EmailPattern, EmailFailedMessage } from "../../../common";

interface IProps {
    getValue: (value: string) => void;
    isSubmited?: boolean;
    isEmailUsed?: boolean;
    value?: string;
    isErrorNeeded?: boolean;
    setIsValueValid?: (isValid: boolean) => void;
}

export const EmailField = (props: IProps) => (
    <div>
        <CustomInputField
            placeholder="Email"
            maxLength={254}
            label="Email"
            type="email"
            icon="user"
            mask={EmailPattern}
            isRequired
            maskError={EmailFailedMessage}
            isSubmitted={props.isSubmited}
            getValue={props.getValue}
            value={props.value}
            setIsValueValid={props.setIsValueValid}
        />
        {props.isEmailUsed && (
            <div className="invalid-feedback d-block mb-3">
                This Email is already used. Please fill in a different Email.
            </div>
        )}
    </div>
);
