import { Styled } from "./styles/styled";
import { MDBSelect, MDBCol, MDBFormInline } from "mdbreact";
import React from "react";
import { DefaultButton } from "../../UI";
import { IAttribute } from "../interfaces/IAttribute";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "../enums/queryMode";
import { IDataSet } from "../../../typings";
import { AttributeService } from "./attributeService";
import { DataSetFilterService, getFieldName } from "../common";
import "./styles/style.css";

interface IProps {
    attribute: IAttribute;
    dataSet: IDataSet;
}

export const Attribute = (props: IProps) => {
    const { queryMode, query, setQuery } = useQuery();

    const getValue = (values: Array<string>) => {
        const filter = DataSetFilterService.getFilter(props.dataSet, props.attribute.description);
        if (values.length) {
            if (filter) filter.values = values;
            else
                props.dataSet?.filters?.push({
                    propertyName: getFieldName(props.attribute.description),
                    values: values
                });
        } else if (filter) DataSetFilterService.deleteFilter(props.dataSet, props.attribute.description);
    };

    const unselectAttribute = () => {
        if (!props.attribute.isSelected) {
            DataSetFilterService.deleteFilter(props.dataSet, props.attribute.description);
            props.attribute.options = [];
        }
    };

    return (
        <MDBCol size="6" className="mb-2">
            <MDBFormInline>
                <DefaultButton
                    className="mt-0 mb-0 ml-0 pt-0 pb-0"
                    disabled={queryMode === QueryMode.View}
                    text={props.attribute.description}
                    style={props.attribute.isSelected ? Styled.FixChoiseSelectedButton : Styled.FixChoiseBaseButton}
                    size="sm"
                    onClick={() => {
                        props.attribute.isSelected = !props.attribute.isSelected;
                        unselectAttribute();
                        setQuery({ ...query });
                    }}
                />
                <MDBSelect
                    className="subattribute-selector"
                    onClick={async () => {
                        await AttributeService.loadSubAttributes(props.dataSet, props.attribute);
                        setQuery({ ...query });
                    }}
                    multiple
                    selectAll
                    selectAllValue="-1"
                    style={
                        props.attribute.isSelected && queryMode !== QueryMode.View
                            ? Styled.Select
                            : Styled.SelectDisabled
                    }
                    options={props.attribute.options}
                    getValue={getValue}
                />
            </MDBFormInline>
        </MDBCol>
    );
};
