import React, { useEffect, useState } from "react";
import { useQuery } from "../../store/queryContext";
import { Chart } from "chart.js";
import { GraphDataReceiver } from "../services/graphDataReceiver";
import { DataSetService } from "../../common";
import "./plugins/chartjs-plugin-watermark";

export const CombinationChart = () => {
    const { query } = useQuery();
    const [chart, setChart] = useState<Chart>();

    useEffect(() => {
        if (chart) {
            chart.destroy();
        }
        var context = (document.getElementById("combinationChart") as HTMLCanvasElement).getContext("2d");
        var chartData = GraphDataReceiver.getCombinationChartData(
            DataSetService.getFirstSelected(query.dataSets).dataSetName,
            query.chartData?.labels,
            query.chartData?.data.last(),
            query.chartData?.yearOverYearPercentChangeData,
            query.title
        );
        if (context && chartData) setChart(new Chart(context, chartData));
    }, [query.chartData]);

    return <canvas id="combinationChart" />;
};
