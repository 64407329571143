import React from "react";
import { BaseNavigation } from "../components/baseNavigation";
import { Main } from "../components/main";
import { Footer } from "../components/footer";
import { Constants } from "../common";

export const LandingPage = () => (
    <>
        <BaseNavigation />
        <div id={Constants.ContentId} className="sidebar-opened">
            <div style={{ top: "0px", height: "50px" }}></div>
            <Main />
            <Footer isContentPage />
        </div>
    </>
);
