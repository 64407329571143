import { DataSetService, Messages, QueryErrorService, RateService, SpecificationService } from "..";
import { dateRanges, periods, datePeriod } from "../../../../typings";
import { QueryCreationSteps } from "../../../UI";
import { CalculationService } from "../../datesCalculations/calculations/calculationService";
import { ICalculation, IQuery, IQueryError } from "../../interfaces";

const validate = (query: IQuery, errors: Array<IQueryError>, step?: QueryCreationSteps) => {    
    if ((step === undefined || step === QueryCreationSteps.PreviewAndExport)) {
        const selectedDataSets = query.isCorrelation
            ? [DataSetService.getFirstSelected(query.dataSets)]
            : DataSetService.getSelected(query.dataSets);
        selectedDataSets.forEach(dataSet => {
            dataSet.dateRanges?.forEach((dateRange, index) => {
                if (DataSetService.isRateSelected(query.dataSets)) {
                    if (!RateService.isRateDateTypeCalculationSelected(dateRange.calculations)) {
                        pushError(errors, Messages.SelectDateType, index);
                    }
                    else if (!RateService.isRateVolumeTypeCalculationSelected(dateRange.calculations)) {
                        pushError(errors, Messages.SelectVolumeType, index);
                    }
                    else if (!isDateValid(dateRange, query.isCorrelation)) {
                        pushError(errors, Messages.PleaseSelectDateRanges, index);
                    }
                }
                else if (!isValid(dateRange, query)) {
                    pushError(errors, Messages.PleaseSelectDateRangesAndCalculations, index);
                }
            });
        });
    }
};

const isValid = (dateRange: dateRanges | undefined, query: IQuery) =>
    dateRange && isDateValid(dateRange, query.isCorrelation) && isCalculationsValid(query, dateRange);

const isCalculationsValid = (query: IQuery, dateRange: dateRanges) =>
    SpecificationService.isSpecificSpecificationReport(query) ||
    query.isCorrelation ||
    isRequiredCalculationsSelected(query, dateRange.calculations);

const isRequiredCalculationsSelected = (query: IQuery, calculations: ICalculation[] | undefined) => {
    return DataSetService.isRateSelected(query.dataSets)
        ? RateService.IsRequiredRateCalculationsSelected(calculations)
        : calculations?.some(c => c.isSelected && c.isCommon);
}

const isDateValid = (dateRange: dateRanges, isCorrelation: boolean | undefined) =>
    isDatePeriodValid(dateRange.firstRange, dateRange.period) &&
    ((!CalculationService.isChange(dateRange.calculations) && !isCorrelation) ||
        isDatePeriodValid(dateRange.secondRange, dateRange.period));

const isDatePeriodValid = (datePeriod: datePeriod | undefined, period: periods | undefined) => {
    const isYearFilled = datePeriod?.startYear && datePeriod.endYear;
    switch (period) {
        case periods.modelYear:
        case periods.year:
            return isYearFilled;
        case periods.quarter:
            return isYearFilled && datePeriod?.startQuarter && datePeriod.endQuarter;
        case periods.month:
            return isYearFilled && datePeriod?.startMonth && datePeriod.endMonth;
    }
};

const pushError = (errors: Array<IQueryError>, message: string, index: number) => {
    QueryErrorService.pushError(errors, QueryCreationSteps.ChooseDatesAndCalculations, message, index);
}

export const DateRangesValidationService = {
    validate,
    isValid
}