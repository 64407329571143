import { CSSProperties } from "react";

const ScrollTable = (maxHeight?: string) =>
    ({
        maxHeight: maxHeight,
        overflowY: "auto",
        width: "100%",
        overflowX: "hidden"
    } as CSSProperties);

export const Styled = {
    ScrollTable
};
