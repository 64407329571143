import React from "react";
import { Styled } from "./style/styled";
import { Routes } from "../../../common";
import { Link } from "react-router-dom";
import smallLogoUri from "../sideBar/img/smallLogo.png";
import logoUri from "../../../common/img/logo.png";

interface IProps {
    isSideBarOpened: boolean | undefined;
}

export const SideBarLogo = (props: IProps) => {
    const GetImage = (src: string, style: {}) => {
        return <img src={src} className="img-fluid" style={style} alt="logo" />;
    };
    return (
        <Link to={Routes.Home}>
            {props.isSideBarOpened ? GetImage(logoUri, Styled.LogoBig) : GetImage(smallLogoUri, Styled.LogoSmall)}
        </Link>
    );
};
