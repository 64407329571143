import React from "react";
import { QueryService } from "../common";
import { useQuery } from "../store/queryContext";
import { VisualizationApi } from "../../../core/api";
import { Styled } from "./styled";

export const RefreshVisualizationButton = () => {
    const { query, setQuery } = useQuery();
    const onClick = async () => {
        setQuery({ ...query, chartData: await VisualizationApi.get(query) });
    };
    return (
        <>
            {QueryService.isReportAvailable(query) && (
                <span style={Styled.ActionButton(true)} className="cursor-pointer" onClick={onClick}>
                    Refresh chart
                </span>
            )}
        </>
    );
};
