import React from "react";
import { DefaultButton, ButtonStyled } from "../../UI";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "../enums/queryMode";

interface IProps {
    marketName: string;
    isMarketAllowed: boolean | undefined;
    onClick: () => void;
}

export const Market = (props: IProps) => {
    const { query, queryMode } = useQuery();

    const getStyle = (isSelected: boolean) => {
        return isSelected ? ButtonStyled.ChoiseSelectedButton : ButtonStyled.ChoiseBaseButton;
    };

    return (
        <>
            {props.isMarketAllowed && (
                <DefaultButton
                    disabled={queryMode === QueryMode.View}
                    className="mr-1"
                    size="sm"
                    text={props.marketName}
                    style={getStyle(query.market === props.marketName)}
                    onClick={() => {
                        if (query.market !== props.marketName) props.onClick();
                    }}
                />
            )}
        </>
    );
};
