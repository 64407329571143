import { DataReceiver } from "../components/queries/previewExport/services/dataReceiver";

const convertToMB = (size: number) => size / 1048576;

const downloadFile = (data: string, fileName: string): void => {
    const byteArray = DataReceiver.getByteArray(data);
    if (!byteArray) return;
    const contentType = getContentType(fileName);
    const file = new Blob([byteArray], {
        type: contentType
    });
    var downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    downloadLink.href = (window.webkitURL || window.URL).createObjectURL(file);
    downloadLink.dataset.downloadurl = [contentType, downloadLink.download, downloadLink.href].join(":");
    downloadLink.click();
};

const viewPDF = async (data: string, name: string) => {
    const base64js = require("base64-js");
    const blob = new Blob([base64js.toByteArray(data)], {
        type: "application/pdf"
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) window.navigator.msSaveOrOpenBlob(blob, name);
    else window.open(URL.createObjectURL(blob));
};

const isValidFormat = (fileName: string) => {
    const extension = getFileExtension(fileName);
    return fileFormats.some(f => f === extension);
};

const getFileExtension = (fileName: string) => {
    return fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;
};

const getFileName = (fileName: string) => {
    return fileName.substring(0, fileName.lastIndexOf("."))
}

const excelFormats = ["xls", "xlsx", "xlsm", "xlsb", "xltx", "xltm", "xlt", "xlam", "xla", "xlw", "xlr"];

const pdfFormats = ["pdf"];

const fileFormats = [...excelFormats, ...pdfFormats];

const getContentType = (fileName: string): string => {
    const extension = getFileExtension(fileName);
    return pdfFormats.some(f => f === extension)
        ? "application/pdf"
        : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
};

export const FileService = {
    convertToMB,
    downloadFile,
    isValidFormat,
    viewPDF,
    getFileName
};
