import { IQuery } from "../../interfaces/IQuery";
import { IDataSet } from "../../../../typings";
import { DataSetApi, QueryApi } from "../../../../core/api";
import { CalculationService } from "../../datesCalculations/calculations/calculationService";
import { AttributeService } from "../../chooseAttributes/attributeService";
import { CorrelationService, DataSetFilterService, DataSetService, MarketNames, CorrelationConstants, QueryValidationService, SaarService } from "..";

const getDefaultQuery = (): IQuery => {
    return {
        dataSets: [],
        sorts: [],
        isShowTotalOnly: true,
        correlationStep: CorrelationConstants.MinCorrelationStep
    };
};

const setQueryTitle = (query: IQuery) => {
    if (query.market === MarketNames.Country) query.title = query.countryDescription;
    else if (query.market === MarketNames.Region) query.title = query.regionDescription;
    else query.title = query.market;
    const datasets = DataSetService.getSelected(query.dataSets);
    datasets.forEach((d, index) => {
        query.title =
            index === datasets.length - 1
                ? query.title + " " + d.dataSetName
                : query.title + " " + d.dataSetName + " and";
    });
    return query;
};

const isReportAvailable = (query: IQuery): boolean => QueryValidationService.isValid(query) && query.title!.trim().length > 0;

const updateQueryDatasetsAvailability = async (query: IQuery) => {
    if (query.isSAAR) {
        SaarService.updateSaarRelatedDataSets(query);
    } else {
        const selectedDataSets = DataSetService.getSelected(query.dataSets);
        if (selectedDataSets.length) {
            const dataSetName = selectedDataSets.first().dataSetName;
            const relations = await DataSetApi.getDataSetRelations(dataSetName);
            query.dataSets?.forEach(
                d =>
                (d.isSelectAllowed =
                    d.dataSetName === dataSetName ||
                    (d.dataSetName?.length !== undefined && relations.includes(d.dataSetName)))
            );
        } else query.dataSets?.forEach(d => (d.isSelectAllowed = true));
    }
    CorrelationService.updateCorrelationRelatedDataSetsAvailability(query);
};

const getQuery = async (queryId: number | undefined) => {
    const query = await QueryApi.get(queryId);
    for (const dataSet of query.dataSets.filter(d => d.isSelected && !d.isTotalIndustry)) {
        await loadSubAttributes(dataSet);
        dataSet.attributes?.forEach(
            a => (a.isSelected = DataSetFilterService.getFilter(dataSet, a.description) !== undefined)
        );
    }
    loadSorts(query);
    await updateQueryDatasetsAvailability(query);
    await CalculationService.updateDateRangesCalculations(query);
    return query;
};

const loadSorts = (query: IQuery) => {
    const selectedDataSet = DataSetService.getSelected(query.dataSets).first();
    query.isShowTotalOnly = selectedDataSet.isShowTotalOnly;
    query.sorts = selectedDataSet.sorts;
};

const loadSubAttributes = async (dataSet: IDataSet) => {
    if (!dataSet.attributes) return;
    for (const attribute of dataSet.attributes.filter(
        at => DataSetFilterService.getFilter(dataSet, at.description) !== undefined
    )) {
        await AttributeService.loadSubAttributes(dataSet, attribute);
    }
};

const getMarket = (query: IQuery): string => {
    switch (query.market) {
        case MarketNames.Us:
            return MarketNames.Us;
        case MarketNames.Country:
            return query.countryDescription!;
        case MarketNames.Region:
            return query.regionDescription!;
        case MarketNames.World:
            return MarketNames.World;
        default:
            throw new Error();
    }
};

export const QueryService = {
    updateQueryDatasetsAvailability,
    setQueryTitle,
    getDefaultQuery,
    getQuery,
    getMarket,
    isReportAvailable
};
