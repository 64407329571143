const getCanvasData = (canvas: HTMLCanvasElement) => {
    const canvasCopy = changeCanvasBackground(canvas);
    return canvasCopy.toDataURL("image/png", 1.0);
};

const changeCanvasBackground = (canvas: HTMLCanvasElement) => {
    const canvasCopy = cloneCanvas(canvas);
    const context = canvasCopy.getContext("2d");
    if (context) {
        context.globalCompositeOperation = "destination-over";
        context.fillStyle = "#fff";
        context.fillRect(0, 0, canvasCopy.width, canvasCopy.height);
    }
    return canvasCopy;
};

const getCanvasBlob = (canvas: { toBlob: (arg0: (blob: any) => void) => void }) => {
    return new Promise(function(resolve) {
        canvas.toBlob(function(blob) {
            resolve(blob);
        });
    });
};

if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
        value: function(callback: (arg0: Blob) => void, type: any, quality: any) {
            var canvas = this;
            setTimeout(function() {
                var binStr = atob(canvas.toDataURL(type, quality).split(",")[1]),
                    len = binStr.length,
                    arr = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }
                callback(new Blob([arr], { type: type || "image/png" }));
            });
        }
    });
}

const cloneCanvas = (oldCanvas: HTMLCanvasElement) => {
    var newCanvas = document.createElement("canvas");
    var context = newCanvas.getContext("2d");
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;
    if (context) context.drawImage(oldCanvas, 0, 0);
    return newCanvas;
};

export const ChartSavingService = {
    getCanvasData,
    getCanvasBlob,
    changeCanvasBackground
};
