import React, { useState } from "react";
import { CurrentDatesTable } from "./currentDatesTable";
import { MDBBtn } from "mdbreact";
import { ButtonStyled } from "../../../UI";
import { Popper, Paper } from "@material-ui/core";
import { Styled } from "../styled";

export const CurrentDatesPopper = () => {
    const [
        anchorElement,
        setAnchorElement
    ] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorElement);
    return (
        <>
            <MDBBtn
                flat
                style={ButtonStyled.SecondaryButton}
                size="lg"
                onClick={e =>
                    setAnchorElement(anchorElement ? null : e.currentTarget)
                }
            >
                CURRENT DATES
            </MDBBtn>
            <Popper
                id={open ? "current-dates-popper" : undefined}
                open={open}
                anchorEl={anchorElement}
                style={Styled.CurrentDatesPopper}
            >
                <Paper>
                    <CurrentDatesTable />
                </Paper>
            </Popper>
        </>
    );
};
