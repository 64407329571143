import React from "react";
import { DateRangeCard } from "./dateRangeCard";
import { dateRanges, IDataSet } from "../../../../typings";
import { Accordion } from "../../../UI";
import { useQuery } from "../../store/queryContext";
import { AddDateRangeButton } from "./addDateRangeButton";
import { DataSetService } from "../../common";

interface IProps {
    dataSet: IDataSet;
    index: number;
    collapseId: number | undefined;
    setCollapseId: (collapseId: number | undefined) => void;
}

export const DateRangesContainer = (props: IProps) => {
    const { query } = useQuery();
    const getLabel = () =>
        `Correlation ${DataSetService.getFirstSelected(query.dataSets).dataSetName} vs ${
            DataSetService.getSecondSelected(query.dataSets).dataSetName
        }`;

    return (
        <Accordion
            label={query.isCorrelation ? getLabel() : props.dataSet.dataSetName}
            collapseId={props.collapseId}
            index={props.index}
            setCollapseId={props.setCollapseId}
        >
            <div id="children-pane">
                {props.dataSet.dateRanges?.map((dateRange: dateRanges, index: number) => {
                    if (!dateRange.key) {
                        dateRange.key = dateRanges.nextKey += 1;
                    }
                    return (
                        <DateRangeCard
                            dateRange={dateRange}
                            key={dateRange.key}
                            index={index}
                            ranges={props.dataSet.ranges}
                            dataSet={props.dataSet}
                        />
                    );
                })}
            </div>
            <AddDateRangeButton dataSet={props.dataSet} />
        </Accordion>
    );
};
