import React from "react";
import { Styled } from "./styled";

interface IProps {
    text: string;
}

export const DefaultParagraph = (props: IProps) => (
    <h1 style={Styled.DefaultParagraph}>{props.text}</h1>
);
