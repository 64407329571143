export const Routes = {
    Home: "/",
    NewQuery: "/new-query/",
    EditQuery: "/edit-query/",
    MyQueries: "/my-queries/",
    UserQueries: "/user-queries/",
    Documentation: "/documentation/",
    Help: "/help/",
    ContactUs: "/contact-us/",
    ContactUsPageUnauthorized: "/contact-us-page-unauthorized/",
    MyAccount: "/my-account/",
    Login: "/login/",
    ForgotPassword: "/forgot-password/",
    ResetPassword: "/reset-password/",
    UsersManagement: "/users-management/",
    RolesManagement: "/roles-management/",
    CreateUser: "/create-user/",
    ContactUsManagement: "/contact-us-management/",
    LandingPageManagement: "/landing-page-management/",
    HelpManagement: "/help-management/",
    UpdateUser: "/update-user/",
    DocumentManagement: "/document-management/",
    FooterManagement: "/footer-management/",
    CreateRole: "/create-role/",
    EditRole: "/edit-role/",
    ReportPreview: "/report-preview/"
};
