import { datePeriod } from "./datePeriod";
import { periods } from "./periods";
import { ICalculation } from "../../components/queries/interfaces/ICalculation";

export class dateRanges {
    firstRange?: datePeriod = new datePeriod();
    secondRange?: datePeriod = new datePeriod();
    isModelYear?: boolean = false;
    period?: periods = periods.month;
    calculations?: Array<ICalculation>;
    key?: number;

    static nextKey = 1;
}
