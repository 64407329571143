import React from "react";
import { MDBRow } from "mdbreact";
import "react-datepicker/dist/react-datepicker.css";
import { datePeriod, periods } from "../../../../../typings";
import { IPeriodsRanges } from "../../../interfaces/IPeriodsRanges";
import { PeriodPicker } from "../pickers/periodPicker";
import { DatesService } from "../services/datesService";
import { DatePeriodConstants } from "../constants/datePeriodConstants";
import { Styled } from "../styles/styled";

interface IProps {
    startYear: string | undefined;
    endYear: string | undefined;
    startPeriod: string | undefined;
    endPeriod: string | undefined;
    toYear?: string;
    toPeriod?: string;
    setStartYear: (year: string | undefined) => void;
    setEndYear: (year: string | undefined) => void;
    setStartPeriod: (period: string | undefined) => void;
    setEndPeriod: (period: string | undefined) => void;
    period: datePeriod | undefined;
    ranges: IPeriodsRanges | undefined;
    periodType: periods;
}

const getPeriod = (props: IProps, period: string | undefined) =>
    DatesService.getFullPeriod(period, props.startYear, props.periodType === periods.quarter, props.ranges);

export const PeriodsSelect = (props: IProps) => {
    const startPeriod = getPeriod(props, props.startPeriod);
    const endPeriod = DatesService.getFullPeriod(
        props.endPeriod,
        props.endYear,
        props.periodType === periods.quarter,
        props.ranges
    );
    if (DatesService.getYear(props.startYear, props.ranges) > DatesService.getYear(props.endYear, props.ranges))
        DatesService.setEndYearOfPeriod(undefined, props.setEndYear, props.period);
    const additionalOptions = [];
    if (startPeriod.period && startPeriod.year === endPeriod.year) {
        if (startPeriod.period > endPeriod.period)
            DatesService.setPeriod(undefined, props.setEndPeriod, props.periodType, props.period, false);
        const period = getPeriod(props, DatePeriodConstants.Current);
        if (period.period && period.period >= startPeriod.period)
            switch (startPeriod.period) {
                case getPeriod(props, DatePeriodConstants.Current).period:
                    additionalOptions.push(DatePeriodConstants.Current);
                    break;
                case getPeriod(props, DatePeriodConstants.Prior).period:
                    additionalOptions.push(DatePeriodConstants.Current);
                    additionalOptions.push(DatePeriodConstants.Prior);
                    break;
                default:
                    additionalOptions.push(DatePeriodConstants.Current);
                    additionalOptions.push(DatePeriodConstants.Prior);
                    additionalOptions.push(DatePeriodConstants.Prior2);
            }
    } else additionalOptions.push(DatePeriodConstants.Current, DatePeriodConstants.Prior, DatePeriodConstants.Prior2);

    return (
        <MDBRow style={Styled.RangeContainer}>
            <div className="mx-5">
                <PeriodPicker
                    isStart
                    year={props.startYear}
                    yearSetter={props.setStartYear}
                    datePeriod={props.startPeriod}
                    datePeriodSetter={props.setStartPeriod}
                    period={props.period}
                    periodType={props.periodType}
                    ranges={props.ranges}
                    toYear={props.toYear}
                />
            </div>
            <div style={Styled.ToLabel}>to</div>
            <div className="mx-5">
                <PeriodPicker
                    year={props.endYear}
                    yearSetter={props.setEndYear}
                    datePeriod={props.endPeriod}
                    datePeriodSetter={props.setEndPeriod}
                    period={props.period}
                    periodType={props.periodType}
                    ranges={props.ranges}
                    fromYear={props.startYear}
                    additionalPeriodOptions={additionalOptions}
                    fromPeriod={
                        startPeriod.year &&
                        endPeriod.year &&
                        startPeriod.year === endPeriod.year &&
                        DatesService.getYear(props.endYear, props.ranges) === endPeriod.year &&
                        startPeriod.period
                            ? startPeriod.period - 1
                            : undefined
                    }
                />
            </div>
        </MDBRow>
    );
};
