import {
    SelectMarketAndDataset,
    NarrowSearch,
    SortAndDisplayOptions,
    ChooseDatesAndCalculations,
    PreviewAndExport
} from "../../queries/index";
import { QueryCreationSteps } from "./queryCreationSteps";

export const getStepContent = (step: QueryCreationSteps, activeStep: QueryCreationSteps) => {
    switch (step) {
        case QueryCreationSteps.SelectMarketAndDataset:
            return SelectMarketAndDataset();
        case QueryCreationSteps.NarrowSearch:
            return NarrowSearch();
        case QueryCreationSteps.ChooseSortandDisplayOptions:
            return SortAndDisplayOptions();
        case QueryCreationSteps.ChooseDatesAndCalculations:
            return ChooseDatesAndCalculations();
        case QueryCreationSteps.PreviewAndExport:
            return PreviewAndExport(activeStep);
        default:
            return "Unknown step";
    }
};
