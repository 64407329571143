import { MDBCard, MDBInput, MDBFormInline, MDBIcon, MDBRow, MDBCol } from "mdbreact";
import React, { useState } from "react";
import { SortableHandle } from "react-sortable-hoc";
import { Styled } from "./styled";
import { SortDescriptionRadioButton } from "./sortDescriptionRadioButton";
import { SortDescriptionTypes } from "./sortDescriptionTypes";
import { getFieldName, getFieldLongName } from "../../queries/common/fieldNamesCreator";
import { ISort } from "../../../typings";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "../enums/queryMode";
import { FontProperties } from "./selectors/fontProperties";
import { SpecificationService, SortService } from "../common";

interface IProps {
    titleName?: string;
    id?: string;
    sort: ISort;
}

export const SortingCard = (props: IProps) => {
    const { queryMode, query, setQuery } = useQuery();

    const [isSelected, setIsSelected] = useState(props.sort.isSelected);

    const [descriptionType, setDescriptionType] = useState(props.sort.descriptionType);

    const [isSubtotal, setIsSubtotal] = useState(props.sort.isSubtotal);

    const handleIsSelected = () => {
        if (queryMode === QueryMode.EditAndDelete) {
            const newValue = !isSelected;
            props.sort.isSelected = newValue;
            SortService.updateFirstSelected(newValue, props.sort, setDescriptionType, setQuery, query);
            setIsSelected(newValue);
        }
    };

    const handleSubtotal = (e: React.FormEvent<HTMLInputElement>) => {
        props.sort.isSubtotal = e.currentTarget.checked;
        setIsSubtotal(e.currentTarget.checked);
    };

    const handleDescriptionType = (descriptionType: SortDescriptionTypes) => {
        setDescriptionType(descriptionType);
        const fieldName = getFieldName(props.titleName);
        props.sort.criterium = descriptionType === SortDescriptionTypes.Short ? fieldName : getFieldLongName(fieldName);
        props.sort.descriptionType = descriptionType;
    };
    const DragHandle = SortableHandle(() => <MDBIcon icon="sort" className="cursor-pointer" />);
    return (
        <MDBRow>
            <MDBCol size="9">
                <MDBCard style={isSelected ? Styled.Active : Styled.Base}>
                    <MDBRow style={{ padding: "12px" }} onClick={() => handleIsSelected()}>
                        <MDBCol size="4">{props.titleName}</MDBCol>
                        <MDBCol size="7">
                            <MDBFormInline
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                }}
                            >
                                <SortDescriptionRadioButton
                                    isFirstSelected={props.sort.isFirstSelected}
                                    isDisabled={queryMode === QueryMode.View}
                                    id={`inlineRadio${props.id}`}
                                    descriptionType={descriptionType}
                                    setDescriptionType={handleDescriptionType}
                                />
                                {!SpecificationService.isSpecificSpecificationReport(query) && (
                                    <MDBInput
                                        disabled={queryMode === QueryMode.View}
                                        label="Subtotal"
                                        checked={isSubtotal}
                                        type="checkbox"
                                        onChange={handleSubtotal}
                                        id={`checkbox${props.id}`}
                                    />
                                )}
                            </MDBFormInline>
                        </MDBCol>
                        <MDBCol size="1">{queryMode === QueryMode.EditAndDelete && <DragHandle />}</MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBCol>
            <MDBCol size="3">{isSubtotal && <FontProperties sort={props.sort} />}</MDBCol>
        </MDBRow>
    );
};
