import { ICalculation } from "../../components/queries/interfaces/ICalculation";
import { httpGet, httpPost } from "./request/requestApi";
import { IDataSet } from "../../typings";

const controllerEndPoint = "calculations";

export class CalculationsApi {
    static async getAll(dataSet: IDataSet, country: string | undefined): Promise<Array<ICalculation>> {
        const dataSetName = encodeURIComponent(dataSet.dataSetName ?? "");
        return await httpGet(`${controllerEndPoint}/get-all?DataSetName=${dataSetName}&Country=${country}`);
    }

    static async getAvailableCalculations(
        calculations: Array<ICalculation> | undefined,
        dataSetName: string | undefined
    ): Promise<Array<ICalculation>> {
        return await httpPost(`${controllerEndPoint}/get-available-calculations`, {
            body: { calculations, dataSetName }
        });
    }
}
