const Main = (screenSize: number) => {
    return {
        margin: "0 6%",
        paddingTop: "20px",
        minHeight: screenSize - 125 + "px"
    };
};

export const Styled = {
    Main
};
