import React from "react";
import { MDBCard, MDBCardHeader, MDBCardBody } from "mdbreact";
import { Styled } from "./styles/styled";
import "./styles/article.css";
import { useArticle } from "..";
import { ArticleTypes } from "../../../typings";

interface IProps {
    isHeaderNeeded?: boolean;
    articleType: ArticleTypes;
}

export const Article = (props: IProps) => {
    const { article } = useArticle(props.articleType);

    function createMarkup(text: string | undefined) {
        return { __html: text ?? "" };
    }

    return (
        <MDBCard style={Styled.ArticleContainer}>
            {props.isHeaderNeeded && (
                <MDBCardHeader
                    style={Styled.ArticleHeader}
                    className="articleHeader"
                    dangerouslySetInnerHTML={createMarkup(article?.articleHeader)}
                />
            )}
            <MDBCardBody dangerouslySetInnerHTML={createMarkup(article?.articleBody)} />
        </MDBCard>
    );
};
