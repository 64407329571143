import React from "react";
import { MDBBtnGroup } from "mdbreact";
import { useRole } from "./store/roleContext";
import { MarketPermission } from "./marketPermission";
import { Styled } from "./styled";
import { MarketNames } from "../queries/common/marketNames";

interface IProps {
    isSubmitted: boolean;
}

export const MarketPermissions = (props: IProps) => {
    const { role } = useRole();

    return (
        <div style={Styled.Form}>
            <MDBBtnGroup className="d-inline">
                <MarketPermission text={MarketNames.Us} property="isUsViewAllowed" isEntityProperty="isCountry" />
                <MarketPermission
                    text={MarketNames.Country}
                    property="isCountryViewAllowed"
                    isEntityProperty="isCountry"
                />
                <MarketPermission text={MarketNames.Region} property="isRegionViewAllowed" isEntityProperty="isRegion" />
                <MarketPermission text={MarketNames.World} property="isWorldViewAllowed" isEntityProperty="isWorld" />
            </MDBBtnGroup>
            {props.isSubmitted &&
                !role.isCountryViewAllowed &&
                !role.isRegionViewAllowed &&
                !role.isUsViewAllowed &&
                !role.isWorldViewAllowed && (
                    <p className="text-danger">Please select at least 1 item in the Market Access.</p>
                )}
        </div>
    );
};
