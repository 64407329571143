import React, { useState } from "react";
import { DefaultFormButton, Accordion } from "../UI";
import { useRole } from "./store/roleContext";
import { CountrySelector } from "./selectors/countrySelector";
import { RegionSelector } from "./selectors/regionSelector";
import { RoleName } from "./roleName";
import { useRedirect } from "../../common/hooks/useRedirect";
import { Routes } from "../../common";
import { RoleWithSameNameAlreadyExists } from "./messages/serverResponses";
import { SaveRole } from "./actions/roleManagementActions";
import { Styled } from "./styled";
import { DatasetList } from "./buttons/datasetList";
import { IEntityDatasets } from "../../typings/roleManagement/IEntityDatasets";
import { MarketPermissions } from "./marketPermissions";

export const RoleManagementForm = () => {
    const { role } = useRole();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isRoleNameAlreadyExists, setIsRoleNameAlreadyExists] = useState(false);
    const [redirectAfterEdit] = useRedirect(role.id !== undefined, "role", Routes.RolesManagement);
    const [collapseId, setCollapseId] = useState<number | undefined>(0);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitted(true);
        if (isFormInvalid()) {
            return;
        }
        const responseMessage = await SaveRole(role);
        if (responseMessage?.includes(RoleWithSameNameAlreadyExists)) {
            setIsRoleNameAlreadyExists(true);
            return;
        }
        redirectAfterEdit();
    };

    const isFormInvalid = () =>
        !role.name?.trim().length ||
        !role.entityDatasets?.length ||
        role.entityDatasets.some(cd => !cd.datasets.length) ||
        (role.isCountryViewAllowed && !role.entityDatasets.some(cd => cd.isCountry && cd.datasets.length)) ||
        (role.isRegionViewAllowed && !role.entityDatasets.some(cd => cd.isRegion && cd.datasets.length));

    return (
        <form className="w-75 mt-5" style={Styled.Form} onSubmit={onSubmit} noValidate>
            <RoleName isSubmitted={isSubmitted} isRoleNameAlreadyExists={isRoleNameAlreadyExists} />
            <MarketPermissions isSubmitted={isSubmitted} />
            {role.isCountryViewAllowed && <CountrySelector isSubmitted={isSubmitted} />}
            {role.isRegionViewAllowed && <RegionSelector isSubmitted={isSubmitted} />}
            {role.entityDatasets?.map((e: IEntityDatasets, index: number) => (
                <Accordion
                    isFailed={isSubmitted && !e.datasets.length}
                    key={e.description}
                    label={"Datasets for " + e.description}
                    index={index}
                    collapseId={collapseId}
                    setCollapseId={setCollapseId}
                >
                    <DatasetList isSubmited={isSubmitted} entityDatasets={e} />
                </Accordion>
            ))}
            <DefaultFormButton text={`${role.id ? "UPDATE" : "CREATE"} ROLE`} />
        </form>
    );
};
