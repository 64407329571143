import { ILogin } from "../../../typings";
import { AccountApi, ApiError } from "../../../core/api";
import { loginResponseType } from "../types/loginResponseType";
import { ILoginResponse } from "../types/loginResponse";

export const accountActions = {
    login: async (
        login: ILogin
    ): Promise<loginResponseType | ILoginResponse> => {
        try {
            return await AccountApi.loginWithCredentials(login);
        } catch (e) {
            if (e instanceof ApiError) {
                switch (e.response.status) {
                    case 400:
                        return loginResponseType.wrongCredentials;
                    case 406:
                        return loginResponseType.disabled;
                }
            }
        }
        return loginResponseType.unknownError;
    }
};
