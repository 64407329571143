import React from "react";
import { useRole } from "./store/roleContext";
import { EditRoleButton } from "./buttons/editRoleButton";
import { DeleteRoleButton } from "./buttons/deleteRoleButton";
import { RoleManagementTableColums } from "./roleManagementTableColums";
import { ScrollTable } from "../UI";
import { AdminRole } from "./constants";
import { Styled } from "./styled";

export const RoleManagementTable = () => {
    const { roles } = useRole();
    const rows = roles
        .filter(r => r.name !== AdminRole)
        .map(role => ({
            id: role.id,
            name: <div style={Styled.TableColumn}>{role.name}</div>,
            actions: (
                <div className="d-flex justify-content-around">
                    <EditRoleButton roleId={role.id} />
                    <DeleteRoleButton roleId={role.id} />
                </div>
            )
        }));

    return (
        <ScrollTable
            className="mt-4 actions-2"
            maxHeight="80vh"
            small
            data={{
                columns: RoleManagementTableColums,
                rows: rows
            }}
        />
    );
};
