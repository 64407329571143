import React, { useRef } from "react";
import JoditEditor from "jodit-react";
import { useArticle } from "../..";
import { ArticleTypes, IArticle } from "../../../../typings";
import { useEditableArticle } from "./stores/editableArticleContext";
import { ArticleError } from "./articleError";

interface IProps {
    propertyName: string;
    label: string;
    maxLength: number;
    articleType: ArticleTypes;
}

export const ArticleEditor = (props: IProps) => {
    const { articles } = useArticle(props.articleType);
    const { editableArticle } = useEditableArticle(props.articleType);
    const editor = useRef(null);

    const onChange = (value: string): void => {
        if (!editableArticle) {
            const newArticle = {
                articleTypeId: props.articleType,
                articleHeader: "",
                articleBody: ""
            } as IArticle;
            articles.push(newArticle);
        } else {
            editableArticle[props.propertyName] = value;
        }
    };

    return (
        <div className="mt-4">
            <JoditEditor
                ref={editor}
                value={editableArticle ? editableArticle[props.propertyName] : ""}
                //@ts-ignore
                config={{ askBeforePasteHTML: false, askBeforePasteFromWord: false }}
                onChange={onChange}
            />
            <ArticleError
                articleType={props.articleType}
                maxLength={props.maxLength}
                propertyName={props.propertyName}
                label={props.label}
            />
        </div>
    );
};
