import React from "react";
import { useUser } from "../../stores";
import { Routes } from "../routes";
import { BasePrivateRoute } from "./basePrivateRoute";

interface IProps {
    component: any;
    path: string;
}

export const AdminPrivateRoute = (props: IProps) => {
    const { user } = useUser();

    return (
        <BasePrivateRoute
            path={props.path}
            redirectTo={Routes.Home}
            component={props.component}
            isAccessAllow={user.isAdmin}
        />
    );
};
