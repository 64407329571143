import React from "react";
import { MDBSelect } from "mdbreact";
import { ISelectOption } from "../../queries/interfaces/ISelectOption";
import { useRole } from "../store/roleContext";
import { IEntityDatasets } from "../../../typings";

interface IProps {
    isSubmitted: boolean;
    options: Array<ISelectOption>;
    property: string;
    selected: string;
    getValue?: (values: any) => void;
    text: string;
}

export const Selector = (props: IProps) => {
    const { role, setRole } = useRole();
    return (
        <>
            <MDBSelect
                label={props.text}
                multiple
                selected={props.selected}
                options={props.options}
                getValue={(values: any) => {
                    role.entityDatasets = role.entityDatasets?.filter(
                        ed => !ed[props.property] || values.some((v: string) => ed.entity === v)
                    );
                    values.forEach((v: string) => {
                        if (!role.entityDatasets?.some(cd => cd.entity === v)) {
                            const entity: IEntityDatasets = {
                                entity: v,
                                datasets: [],
                                description: props.options.find(c => c.value === v)?.text ?? ""
                            };
                            entity[props.property] = true;
                            role.entityDatasets?.push(entity);
                        }
                    });
                    if (props.getValue) props.getValue(values);
                    else setRole({ ...role });
                }}
            />
            {props.isSubmitted && !props.options.some(o => o.checked) && (
                <div className="text-danger error-text-size mb-3">{`Please select at least 1 item in the ${props.text}.`}</div>
            )}
        </>
    );
};
