import { datePeriod, Quarters, Months, dateRanges, IDataSet } from "../../../../../typings";
import { periods } from "../../../../../typings/query/periods";
import { CalculationsApi } from "../../../../../core/api";
import { DataSetService, SpecificationService } from "../../../common";
import { IQuery, ICalculation, IPeriodsRanges } from "../../../interfaces";
import { CalculationService } from "../../calculations/calculationService";
import { DatePeriodConstants } from "../constants/datePeriodConstants";

const setPeriod = (
    date: string | undefined,
    setter: (date: string | undefined) => void,
    periodType: periods,
    period: datePeriod | undefined,
    isStart: boolean | undefined
) => {
    setter(date);
    periodType === periods.month
        ? setMonthPeriodFromDate(date, isStart, period)
        : setQuarterPeriodFromDate(date, isStart, period);
};

const setStartYearOfPeriod = (
    year: string | undefined,
    setter: (date: string | undefined) => void,
    period: datePeriod | undefined
) => {
    setter(year);
    if (period) period.startYear = year;
};

const setEndYearOfPeriod = (
    year: string | undefined,
    setter: (date: string | undefined) => void,
    period: datePeriod | undefined
) => {
    setter(year);
    if (period) period.endYear = year;
};

const getQuarterName = (quarters: Quarters | undefined) => {
    if (quarters !== undefined)
        switch (quarters) {
            case Quarters.Current:
                return DatePeriodConstants.Current;
            case Quarters.Prior:
                return DatePeriodConstants.Prior;
            case Quarters.Prior2:
                return DatePeriodConstants.Prior2;
            default:
                return DatePeriodConstants.Quarters[quarters - 1];
        }
};

const getMonthName = (months: Months | undefined) => {
    if (months !== undefined)
        switch (months) {
            case Months.Current:
                return DatePeriodConstants.Current;
            case Months.Prior:
                return DatePeriodConstants.Prior;
            case Months.Prior2:
                return DatePeriodConstants.Prior2;
            default:
                return DatePeriodConstants.Months[months - 1];
        }
};

const getYear = (year: string | undefined, ranges: IPeriodsRanges | undefined): number => {
    if (!ranges) return 0;
    switch (year) {
        case DatePeriodConstants.Current:
            return +ranges.currentYear;
        case DatePeriodConstants.Prior:
            return +ranges.currentYear - 1;
        default:
            return year ? +year : 0;
    }
};

const getMonth = (month: string | undefined, ranges: IPeriodsRanges | undefined): number => {
    if (!ranges) return 0;
    switch (month) {
        case DatePeriodConstants.Current:
            return +ranges.currentPeriod;
        case DatePeriodConstants.Prior:
            return +ranges.currentPeriod !== 1 ? +ranges.currentPeriod - 1 : 12;
        case DatePeriodConstants.Prior2:
            return +ranges.currentPeriod > 2 ? +ranges.currentPeriod - 2 : 10 + +ranges.currentPeriod;
        default:
            return DatePeriodConstants.Months.findIndex(v => v === month) + 1;
    }
};

const getQuarter = (quarter: string | undefined, ranges: IPeriodsRanges | undefined) => {
    if (!ranges) return 0;
    var quarterNumber = Math.ceil(+ranges.currentPeriod / 3);
    switch (quarter) {
        case DatePeriodConstants.Current:
            return quarterNumber;
        case DatePeriodConstants.Prior:
            return quarterNumber !== 1 ? quarterNumber - 1 : 4;
        case DatePeriodConstants.Prior2:
            return quarterNumber > 2 ? quarterNumber - 2 : 2 + quarterNumber;
        default:
            return DatePeriodConstants.Quarters.findIndex(v => v === quarter) + 1;
    }
};

const getYearsCount = (datePerion: datePeriod | undefined, periodRanges: IPeriodsRanges | undefined): number =>
    DatesService.getYear(datePerion?.endYear, periodRanges) - DatesService.getYear(datePerion?.startYear, periodRanges);

const getMonthsCount = (datePerion: datePeriod | undefined, periodRanges: IPeriodsRanges | undefined): number => {
    const startYear = DatesService.getYear(datePerion?.startYear, periodRanges);
    const endYear = DatesService.getYear(datePerion?.endYear, periodRanges);
    const startMonth = DatesService.getMonth(DatesService.getMonthName(datePerion?.startMonth), periodRanges);
    const endMonth = DatesService.getMonth(DatesService.getMonthName(datePerion?.endMonth), periodRanges);
    return startYear === endYear
        ? endMonth - startMonth + 1
        : getPeriodsCount(startYear, endYear, startMonth, endMonth, DatePeriodConstants.MonthsCount);
};

const getQuartersCount = (datePerion: datePeriod | undefined, periodRanges: IPeriodsRanges | undefined): number => {
    const startYear = DatesService.getYear(datePerion?.startYear, periodRanges);
    const endYear = DatesService.getYear(datePerion?.endYear, periodRanges);
    const startQuarter = DatesService.getQuarter(DatesService.getQuarterName(datePerion?.startQuarter), periodRanges);
    const endQuarter = DatesService.getQuarter(DatesService.getQuarterName(datePerion?.endQuarter), periodRanges);
    return startYear === endYear
        ? endQuarter - startQuarter + 1
        : getPeriodsCount(startYear, endYear, startQuarter, endQuarter, DatePeriodConstants.QuartersCount);
};

const getPeriodsCount = (
    startYear: number,
    endYear: number,
    startPerion: number,
    endPeriod: number,
    periodCount: number
): number => {
    let count = 0;
    for (let i = startYear; i <= endYear; i++) {
        if (i === startYear) count += periodCount - startPerion + 1;
        else if (i === endYear) count += endPeriod;
        else count += periodCount;
    }
    return count;
};

const getFullPeriod = (
    periodName: string | undefined,
    selectedYear: string | undefined,
    isQuarter: boolean,
    ranges: IPeriodsRanges | undefined
) => {
    if (!ranges) return {};
    const period = isQuarter ? getQuarter(periodName, ranges) : getMonth(periodName, ranges);
    let year = getYear(selectedYear, ranges);
    if (
        period > (isQuarter ? Math.ceil(+ranges.currentPeriod / 3) : +ranges.currentPeriod) &&
        (periodName === DatePeriodConstants.Prior || periodName === DatePeriodConstants.Prior2)
    ) {
        year--;
    }
    return { period, year };
};

const getDatasetsWithCastedDatePeriods = (dataSets: Array<IDataSet>) => {
    const dataSetsCopy = JSON.parse(JSON.stringify(DataSetService.getSelected(dataSets)));
    for (let i = 0; i < dataSetsCopy.length; i++) {
        for (let j = 0; j < dataSetsCopy[i].dateRanges.length; j++) {
            dataSetsCopy[i].dateRanges[j] = getDateRangeForRequest(
                dataSetsCopy[i].dateRanges[j],
                dataSetsCopy[i].ranges
            );
        }
    }
    return dataSetsCopy;
};

const getDateRangeForRequest = (dateRange: dateRanges, ranges: IPeriodsRanges) => {
    const newDateRange = { ...dateRange };
    if (dateRange.period === periods.month || dateRange.period === periods.quarter) {
        newDateRange.firstRange = getPeriod(dateRange.firstRange, ranges, dateRange.period === periods.month);
        if (dateRange.secondRange?.startYear) {
            newDateRange.secondRange = getPeriod(dateRange.secondRange, ranges, dateRange.period === periods.month);
        }
    } else {
        newDateRange.firstRange = getYearPeriod(dateRange.firstRange, ranges);
        if (dateRange.secondRange?.startYear) {
            newDateRange.secondRange = getYearPeriod(dateRange.secondRange, ranges);
        }
    }
    return newDateRange;
};

const getPeriod = (period: datePeriod | undefined, ranges: IPeriodsRanges, isMonth: boolean) => {
    const newPeriod = new datePeriod();
    const startPeriod = getFullPeriod(
        isMonth ? getMonthName(period?.startMonth) : getQuarterName(period?.startQuarter),
        period?.startYear,
        !isMonth,
        ranges
    );
    const endPeriod = getFullPeriod(
        isMonth ? getMonthName(period?.endMonth) : getQuarterName(period?.endQuarter),
        period?.endYear,
        !isMonth,
        ranges
    );
    if (isMonth) {
        newPeriod.startMonth = startPeriod.period;
        newPeriod.endMonth = endPeriod.period;
    } else {
        newPeriod.startQuarter = startPeriod.period;
        newPeriod.endQuarter = endPeriod.period;
    }
    newPeriod.startYear = startPeriod.year?.toString();
    newPeriod.endYear = endPeriod.year?.toString();
    return newPeriod;
};

const getYearPeriod = (period: datePeriod | undefined, ranges: IPeriodsRanges) => {
    const newPeriod = new datePeriod();
    newPeriod.startYear = getYear(period?.startYear, ranges).toString();
    newPeriod.endYear = getYear(period?.endYear, ranges).toString();
    return newPeriod;
};

const setMonthPeriodFromDate = (
    date: string | undefined,
    isStart: boolean | undefined,
    period: datePeriod | undefined
) => {
    if (period)
        isStart
            ? (period.startMonth = getMonthEnumValueFromDate(date))
            : (period.endMonth = getMonthEnumValueFromDate(date));
};

const getMonthEnumValueFromDate = (month: string | undefined) => {
    switch (month) {
        case DatePeriodConstants.Current:
            return Months.Current;
        case DatePeriodConstants.Prior:
            return Months.Prior;
        case DatePeriodConstants.Prior2:
            return Months.Prior2;
        default:
            return DatePeriodConstants.Months.findIndex(m => m === month) + 1;
    }
};

const setQuarterPeriodFromDate = (
    date: string | undefined,
    isStart: boolean | undefined,
    period: datePeriod | undefined
) => {
    if (period)
        isStart
            ? (period.startQuarter = getQuarterEnumValueFromDate(date))
            : (period.endQuarter = getQuarterEnumValueFromDate(date));
};

const getQuarterEnumValueFromDate = (quarter: string | undefined) => {
    switch (quarter) {
        case DatePeriodConstants.Current:
            return Quarters.Current;
        case DatePeriodConstants.Prior:
            return Quarters.Prior;
        case DatePeriodConstants.Prior2:
            return Quarters.Prior2;
        default:
            return DatePeriodConstants.Quarters.findIndex(m => m === quarter) + 1;
    }
};

const getDateRange = async (dataSet: IDataSet, country: string | undefined): Promise<dateRanges> => {
    var dateRange = new dateRanges();
    dateRange.calculations = await CalculationsApi.getAll(dataSet, country);
    return dateRange;
};

const isPeriodAvailable = (query: IQuery, period: periods, calculations?: Array<ICalculation>): boolean => {
    switch (period) {
        case periods.modelYear:
            return (
                !CalculationService.isRateSales(calculations) && !CalculationService.isRateSalesForecast(calculations)
            );
        default:
            return (
                !SpecificationService.isSpecificSpecificationReport(query) &&
                !CalculationService.isRateOptionVolume(calculations)
            );
    }
};

const updateDateRangePeriod = (query: IQuery, dateRange: dateRanges): void => {
    if (
        SpecificationService.isSpecificSpecificationReport(query) ||
        CalculationService.isRateOptionVolume(dateRange.calculations)
    ) {
        dateRange.period = periods.modelYear;
        dateRange.isModelYear = true;
    } else if (
        (CalculationService.isRateSales(dateRange.calculations) ||
            CalculationService.isRateSalesForecast(dateRange.calculations)) &&
        dateRange.period === periods.modelYear
    ) {
        dateRange.period = periods.quarter;
        dateRange.isModelYear = false;
    }
};

export const DatesService = {
    getDateRange,
    setPeriod,
    setStartYearOfPeriod,
    setEndYearOfPeriod,
    getQuarterName,
    getMonthName,
    getYear,
    getMonth,
    getQuarter,
    getFullPeriod,
    getDatasetsWithCastedDatePeriods,
    isPeriodAvailable,
    updateDateRangePeriod,
    getYearsCount,
    getMonthsCount,
    getQuartersCount
};
