import React from "react";
import { QueryProvider, MyQueriesTable, QueryMode } from "../components/queries";
import { MDBContainer } from "mdbreact";
import { DefaultParagraph } from "../components/UI";
import { PasteButton } from "../components/queries/buttons/pasteButton";
import { useHistory } from "react-router-dom";
import { IUser } from "../typings";

interface IState {
    user: IUser;
}

export const UserQueriesPage = () =>{
const history = useHistory();

const getUserName = () => {
    const userFromState = (history.location.state as IState)?.user;
    return userFromState ? userFromState.userName : "";
};
return (
    <QueryProvider isQueriesLoadNeeded queryMode={QueryMode.View}>
        <MDBContainer>
            <DefaultParagraph text="User Queries" />
            <div className="d-flex flex-wrap">
                <label className = "font-weight-bold userLabel mr-auto mt-n1">{getUserName()}</label>
                <div className = "mt-n3">
                    <PasteButton />
                </div>            
                <MyQueriesTable />
            </div>
        </MDBContainer>
    </QueryProvider>
    );
};