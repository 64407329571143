import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import "./styles/style.css";
import { Styled } from "./styles/styled";

interface IData {
    columns: {
        label?: string;
        field?: string;
        sort?: string;
        width?: number;
        searchable?: boolean;
        [rest: string]: any;
    }[];
    rows: {
        clickEvent?: () => void;
        [rest: string]: any;
    }[];
}

interface ISort {
    column?: string;
    direction: string;
}

interface IProps {
    data?: IData;
    small?: boolean;
    className?: string;
    maxHeight?: string;
    searching?: boolean;
}

export const ScrollTable = (props: IProps) => {
    const [sort, setSort] = useState<ISort>();
    const [sortData, setSortData] = useState<IData>();

    useEffect(() => {
        if (sort?.column) {
            const sortRows = props.data?.rows.sort((a, b) => {
                const firstString = (a[sort.column as string] as string);
                const secondString = (b[sort.column as string] as string);

                if (firstString > secondString) {
                    return sort.direction === "asc" ? 1 : -1;
                } else {
                    return sort.direction === "asc" ? -1 : 1;
                }
            });
            setSortData({ rows: sortRows, columns: props.data?.columns } as IData);
        } else {
            setSortData(props.data);
        }
    }, [sort, props.data]);

    return (
        <div
            className={`scroll-table ${props.searching ? "searching" : ""} ${props.className}`}
            style={Styled.ScrollTable(props.maxHeight)}
        >
            <MDBDataTable
                striped
                noBottomColumns
                searching={props.searching || false}
                bordered
                paging={false}
                data={sortData}
                small={props.small}
                onSort={sort => setSort(sort)}
            />
        </div>
    );
};
