import React from "react";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import { SortingCard } from "./sortingCard";
import { useQuery } from "../store/queryContext";
import { SaarService } from "../common";
import { PropertyNames } from "../../../common/propertyNames";
import { SortDescriptionTypes } from "./sortDescriptionTypes";

export const SortByAttributes = () => {
    const { query, setQuery } = useQuery();

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        if (query.sorts) {
            if (newIndex !== oldIndex && query.isSAAR) {
                oldIndex = query.sorts.findIndex(s => s.criterium?.includes(PropertyNames.VehicleType));
                newIndex = query.sorts.findIndex(s => s.criterium?.includes(PropertyNames.Source));
            }
            
            const newSorts = arrayMove(query.sorts, oldIndex, newIndex);

            // Added on Nov 16 to implement None logic on sorting drag
            const arrIndex = newSorts.map(el => el.isSelected).lastIndexOf(true);
            const newIsFirstSelected = newSorts[arrIndex]; 
            
            const isFirstSelected = newSorts.find(s => s.isFirstSelected);
            if (isFirstSelected) {
                isFirstSelected.isFirstSelected = false;
                if (isFirstSelected.descriptionType === SortDescriptionTypes.No)
                    isFirstSelected.descriptionType = SortDescriptionTypes.Long;

                newIsFirstSelected.isFirstSelected = true;
                if (newIsFirstSelected.isFirstSelected && newIsFirstSelected.descriptionType === SortDescriptionTypes.No)
                    newIsFirstSelected.descriptionType = SortDescriptionTypes.Long;
            }
            // till here.
            setQuery({ ...query, sorts: newSorts });
        }
    };
    const SortableItem = SortableElement(({ value }: any) => <>{value}</>);
    const SortableList = SortableContainer(({ items }: any) => (
        <ul className="pl-2">
            {items?.map((value: any, index: any) => (
                <SortableItem key={`item-${value + index}`} index={index} value={value} />
            ))}
        </ul>
    ));
    return (
        <div className="ml-3">
            <SortableList
                disableAutoscroll
                lockAxis="y"
                distance={10}
                items={SaarService.filterSorts(query.isSAAR, query.sorts)?.map(s => (
                    <SortingCard sort={s} titleName={s.description} id={`${s.description}`} />
                ))}
                onSortEnd={onSortEnd}
                useDragHandle
            />
        </div>
    );
};
