import { MDBCollapse, MDBCard } from "mdbreact";
import React, { PropsWithChildren } from "react";
import { Styled } from "./styled";
import { AccordionHeader } from "./accordionHeader";

interface IProps {
    label: string | undefined;
    index?: number;
    collapseId: number | undefined;
    cardText?: string;
    isFailed?: boolean;
    setCollapseId: (collapseId: number | undefined) => void;
}

export const Accordion = (props: PropsWithChildren<IProps>) => {
    const { index = 0 } = props;

    const isOpened = () => props.collapseId === index;

    return (
        <div className="mb-3">
            <AccordionHeader
                isFailed={props.isFailed}
                index={index}
                text={props.label}
                isOpen={isOpened()}
                setCollapseId={props.setCollapseId}
            />
            <MDBCollapse isOpen={isOpened()}>
                <MDBCard style={Styled.AccordionCard}>
                    {props.cardText && (
                        <p style={Styled.CardHeader} className="ml-3 mt-4 mb-1 font-weight-bold">
                            {props.cardText}
                        </p>
                    )}
                    {props.children}
                </MDBCard>
            </MDBCollapse>
        </div>
    );
};
