import React from "react";
import { IUserContext, IUser } from "../../typings";
import { UserContextActions } from "./userContextActions";

export const userContext = React.createContext<IUserContext>({
    user: {},
    setUser: (): void => {}
});

export const useUser = () => {
    const { user, setUser } = React.useContext(userContext);
    if (!user) {
        throw new Error("User context has not been initialized.");
    }
    return { user, setUser };
};

export const ReloadUserContext = async (setUser: (user: IUser) => void) => {
    let user = await UserContextActions.load();
    user.isLoaded = true;
    setUser(user);
};
