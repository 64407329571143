export {};

declare global {
    interface Number {
        formatDecimal(options?: Intl.NumberFormatOptions | undefined): string;
    }
}

Number.prototype.formatDecimal = function(options?: Intl.NumberFormatOptions | undefined): string {
    return this.toLocaleString("en-US", options);
};
