import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { MDBContainer, MDBRow } from "mdbreact";
import { DefaultButton, Spinner } from "..";
import { Styled } from "./styled";
import { getStepContent } from "./getStepContent";
import { QuerySteps } from "./querySteps";
import { QueryCreationSteps } from "./queryCreationSteps";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../queries/store/queryContext";
import { ButtonStyled } from "../buttons/buttonStyled";
import { StepperMenu } from "./stepperMenu";
import "./styles.css";
import { DependOnStepButton } from "./dependOnStepButton";
import { Label } from "./label";
import { StepperService } from "./stepperService";
import { QueryValidationService } from "../../queries/common";
import { QueryNameInput } from "../../queries/queryNameInput/queryNameInput";
import { SaveQueryButton } from "../../queries/buttons/saveQueryButton";
import { NewQueryButton } from "../../queries/previewExport/newQueryButton";

interface IState {
    step: QueryCreationSteps;
}

export const VerticalLinearStepper = () => {
    const history = useHistory();
    const step = (history.location.state as IState)?.step;
    const [activeStep, setActiveStep] = useState(step ?? QueryCreationSteps.SelectMarketAndDataset);
    const { query, errors, setErrors } = useQuery();

    return (
        <MDBContainer>
            <Spinner />
            <StepperMenu step={activeStep} />
            <Stepper activeStep={activeStep} orientation="vertical" style={Styled.Stepper}>
                {QuerySteps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            onClick={() => {
                                QueryValidationService.validate(query, errors, index);
                                setErrors([...errors]);
                                if (StepperService.isStepAllowed(index, errors)) setActiveStep(index);
                            }}
                        >
                            <Label index={index} label={label} activeStep={activeStep} />
                        </StepLabel>
                        <StepContent>
                            <div>{getStepContent(index, activeStep)}</div>
                            <MDBRow className="pt-4 pl-4">
                                <DependOnStepButton
                                    activeStep={activeStep}
                                    setActiveStep={setActiveStep}
                                    index={index}
                                />
                                {activeStep === QueryCreationSteps.PreviewAndExport && (
                                    <SaveQueryButton size="sm" />
                                )}
                                <DefaultButton
                                    disabled={activeStep === QueryCreationSteps.SelectMarketAndDataset}
                                    onClick={() => setActiveStep(activeStep - 1)}
                                    size="sm"
                                    text="PREVIOUS"
                                    style={ButtonStyled.SecondaryButton}
                                />
                                {activeStep === QueryCreationSteps.PreviewAndExport && (
                                    <NewQueryButton setActiveStep={setActiveStep} />
                                )}
                            </MDBRow>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            <QueryNameInput setActiveStep={setActiveStep} />
        </MDBContainer>
    );
};
