import React from "react";
import { IArticleContext } from "./IArticleContext";
import { ArticleTypes } from "../../../../../typings";

export const articleContext = React.createContext<IArticleContext>({
    articles: [],
    setArticles: () => {}
});

export const useArticle = (articleType?: ArticleTypes) => {
    const context = React.useContext(articleContext);
    if (!context) {
        throw new Error("Article context has not been initialized.");
    }
    return {
        ...context,
        article: context.articles.find(a => a.articleTypeId === articleType)
    };
};
