import React from "react";
import { MDBNav } from "mdbreact";
import { SideBarLink } from "./sideBarLink";
import { SideBarLogo } from "./sideBarLogo";
import { Routes } from "../../../common";
import { Styled } from "./style/styled";
import { DocumentList } from "./documentList";

interface IProps {
    isSideBarOpened: boolean;
}

export const UserSideBar = (props: IProps) => {
    return (
        <div style={Styled.SideBarContainer(props.isSideBarOpened)}>
            <MDBNav className="flex-column" style={Styled.SideBarList}>
                <SideBarLink url={Routes.Home} text="Home" icon="home" isSideBarOpened={props.isSideBarOpened} />
                <SideBarLink
                    url={Routes.NewQuery}
                    text="New Query"
                    icon="plus-circle"
                    isSideBarOpened={props.isSideBarOpened}
                />
                <SideBarLink
                    url={Routes.MyQueries}
                    text="My Queries"
                    icon="archive"
                    isSideBarOpened={props.isSideBarOpened}
                />
                {props.isSideBarOpened && (
                    <>
                        <hr style={Styled.Hr} />
                        <DocumentList />
                        <hr style={Styled.Hr} />
                    </>
                )}
                <SideBarLogo isSideBarOpened={props.isSideBarOpened} />
            </MDBNav>
        </div>
    );
};
