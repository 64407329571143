import React from "react";
import { ArticleTypes } from "../../../../../typings";
import { IEditableArticleContext } from "./IEditableArticleContext";
import { useArticle } from "./articleContext";

export const editableArticleContext = React.createContext<IEditableArticleContext>({
    isSubmited: false,
    setIsSubmited: () => {},
    editableArticle: undefined,
    setEditableArticle: () => {}
});

export const useEditableArticle = (articleType?: ArticleTypes) => {
    const context = React.useContext(editableArticleContext);
    const { articles } = useArticle(articleType);
    if (!context) {
        throw new Error("Article context has not been initialized.");
    }
    const originArticle = articles?.find(a => a.articleTypeId === articleType);
    if (!context.editableArticle) {
        context.editableArticle = originArticle ? { ...originArticle } : undefined;
        context.setEditableArticle(context.editableArticle);
    }
    return { ...context, originArticle };
};
