import React from "react";
import { Styled } from "./styles/styled";
import { MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";
import { useQuery } from "../store/queryContext";
import { Routes } from "../../../common";
import { Constants } from "../common/constants";

export const ViewOnNewTab = () => {
    const { query } = useQuery();
    return (
        <Link
            to={Routes.ReportPreview}
            target="_blank"
            onClick={() => {
                if (query.report) localStorage.setItem(Constants.ReportLocalSessionKey, query.report);
            }}
        >
            <MDBIcon icon="fas fa-external-link-alt" className="mr-2" style={Styled.Icon} />
            <span style={Styled.Span} className="cursor-pointer">
                View on New Tab
            </span>
        </Link>
    );
};
