import React, { PropsWithChildren } from "react";
import { Styled } from "./styled";

interface IProps {
    index: number;
}

export const GridRow = (props: PropsWithChildren<IProps>) => {
    return (
        <tr style={Styled.Row(props.index)}>
            {props.children}
        </tr>
    )
}
