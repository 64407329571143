import { MDBRow } from "mdbreact";
import React, { useState } from "react";
import { PeriodsSelect } from "./ranges/periodsSelect";
import { periods } from "../../../../typings/query/periods";
import { StepError, QueryCreationSteps } from "../../../UI";
import { DatesService } from "./services/datesService";
import { datePeriod } from "../../../../typings";
import { IDateRangeComponentProps } from "../../interfaces/IDateRangeComponentProps";
import { YearsRange } from "./ranges/yearsRange";
import { Calculations } from "../calculations/calculations";
import { Styled } from "../../styled";
import { useQuery } from "../../store/queryContext";
import { CalculationService } from "../calculations/calculationService";
import { CorrelationStep } from "./correlationStep";
import { DataSetService, DateRangesValidationService } from "../../common";
import { DataSetNames } from "../../../../common/dataSetNames";
import { SelectRangeType } from "./selectRangeType";

export const DateRange = (props: IDateRangeComponentProps) => {
    const dateRange = props.dateRange ?? {};
    const { query } = useQuery();
    DatesService.updateDateRangePeriod(query, dateRange);
    const [period, setPeriod] = useState<periods | undefined>(dateRange.period);

    const [startPeriod1, setStartPeriod1] = useState<string | undefined>(
        dateRange.period === periods.quarter
            ? DatesService.getQuarterName(dateRange.firstRange?.startQuarter)
            : DatesService.getMonthName(dateRange.firstRange?.startMonth)
    );
    const [endPeriod1, setEndPeriod1] = useState<string | undefined>(
        dateRange.period === periods.quarter
            ? DatesService.getQuarterName(dateRange.firstRange?.endQuarter)
            : DatesService.getMonthName(dateRange.firstRange?.endMonth)
    );
    const [startPeriod2, setStartPeriod2] = useState<string | undefined>(
        dateRange.period === periods.quarter
            ? DatesService.getQuarterName(dateRange.secondRange?.startQuarter)
            : DatesService.getMonthName(dateRange.secondRange?.startMonth)
    );
    const [endPeriod2, setEndPeriod2] = useState<string | undefined>(
        dateRange.period === periods.quarter
            ? DatesService.getQuarterName(dateRange.secondRange?.endQuarter)
            : DatesService.getMonthName(dateRange.secondRange?.endMonth)
    );

    const [startYear1, setStartYear1] = useState<string | undefined>(dateRange.firstRange?.startYear);
    const [endYear1, setEndYear1] = useState<string | undefined>(dateRange.firstRange?.endYear);
    const [startYear2, setStartYear2] = useState<string | undefined>(dateRange.secondRange?.startYear);
    const [endYear2, setEndYear2] = useState<string | undefined>(dateRange.secondRange?.endYear);
    const isRequireSecondRange = (CalculationService.isChange(dateRange.calculations) || query.isCorrelation);

    const switchPeriod = (period: periods) => {
        dateRange.firstRange = new datePeriod();
        dateRange.secondRange = new datePeriod();
        dateRange.period = period;
        dateRange.isModelYear = period === periods.modelYear;
        setPeriod(period);
        clearDateStates();
    };

    const clearDateStates = () => {
        setStartPeriod1(undefined);
        setEndPeriod1(undefined);
        setStartPeriod2(undefined);
        setEndPeriod2(undefined);
        setStartYear1(undefined);
        setEndYear1(undefined);
        setStartYear2(undefined);
        setEndYear2(undefined);
    };

    return (
        <>
            {!query.isCorrelation && props.dataSet?.dataSetName !== DataSetNames.Specifications && (
                <Calculations dateRange={dateRange} dataSetName={props.dataSet?.dataSetName} />
            )}
            <MDBRow>
                <div className="d-flex flex-column">
                    <SelectRangeType dateRange={dateRange} setPeriod={switchPeriod} />
                    {isRequireSecondRange && <div style={Styled.VsLabel}>vs</div>}
                </div>
                <div className="text-center flex-grow-1">
                    {(period === periods.quarter || period === periods.month) && (
                        <div>
                            <PeriodsSelect
                                startPeriod={startPeriod1}
                                endPeriod={endPeriod1}
                                setStartPeriod={setStartPeriod1}
                                setEndPeriod={setEndPeriod1}
                                startYear={startYear1}
                                endYear={endYear1}
                                setStartYear={setStartYear1}
                                setEndYear={setEndYear1}
                                periodType={period}
                                period={dateRange.firstRange}
                                ranges={props.ranges}
                            />
                            {isRequireSecondRange && (
                                <PeriodsSelect
                                    startPeriod={startPeriod2}
                                    endPeriod={endPeriod2}
                                    setStartPeriod={setStartPeriod2}
                                    setEndPeriod={setEndPeriod2}
                                    startYear={startYear2}
                                    endYear={endYear2}
                                    setStartYear={setStartYear2}
                                    setEndYear={setEndYear2}
                                    periodType={period}
                                    period={dateRange.secondRange}
                                    ranges={
                                        query.isCorrelation
                                            ? DataSetService.getSecondSelected(query.dataSets).ranges
                                            : props.ranges
                                    }
                                    toYear={query.isCorrelation ? undefined : startYear1}
                                />
                            )}
                        </div>
                    )}
                    {(period === periods.modelYear || period === periods.year) && (
                        <div>
                            <YearsRange
                                startYear={startYear1}
                                endYear={endYear1}
                                startYearSetter={setStartYear1}
                                endYearSetter={setEndYear1}
                                period={dateRange.firstRange}
                                ranges={props.ranges}
                            />
                            {isRequireSecondRange && (
                                <YearsRange
                                    startYear={startYear2}
                                    endYear={endYear2}
                                    startYearSetter={setStartYear2}
                                    endYearSetter={setEndYear2}
                                    period={dateRange.secondRange}
                                    ranges={
                                        query.isCorrelation
                                            ? DataSetService.getSecondSelected(query.dataSets).ranges
                                            : props.ranges
                                    }
                                    to={
                                        query.isCorrelation
                                            ? undefined
                                            : startYear1
                                                ? startYear1
                                                : props.ranges?.endYear
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            </MDBRow>
            <CorrelationStep />
            {(!DateRangesValidationService.isValid(props.dateRange, query) || query.isCorrelation) && (
                <StepError step={QueryCreationSteps.ChooseDatesAndCalculations} index={props.index} />
            )}
        </>
    );
};
