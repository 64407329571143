import React, { useState, useEffect } from "react";
import { MarketSelector } from "./marketSelector";
import { ISelectOption } from "../../interfaces/ISelectOption";
import { CountryApi } from "../../../../core/api";
import { useQuery } from "../../store/queryContext";

export const CountrySelector = () => {
    const { query } = useQuery();
    const [countries, setCountries] = useState<Array<ISelectOption>>([]);

    useEffect(() => {
        (async () => {
            const countries = await CountryApi.getAll();
            const options = new Array<ISelectOption>();
            countries.forEach(c =>
                options.push({
                    value: c.country_ID,
                    text: c.description,
                    checked: query.country === c.country_ID
                })
            );
            setCountries(options);
        })();
    }, []);

    return <MarketSelector title="Country" options={countries} />;
};
