import React, { useState, useEffect } from "react";
import { DataSetApi } from "../../../core/api";
import { DataSet } from "../../UI";
import { MDBRow, MDBCol, MDBAlert } from "mdbreact";
import { IEntityDatasets, IDataSet } from "../../../typings";
import { useRole } from "../store/roleContext";
import { PropertyNames } from "../../../common/propertyNames";

interface IProps {
    isSubmited: boolean;
    entityDatasets: IEntityDatasets;
}

export const DatasetList = (props: IProps) => {
    const [dataSets, setDataSets] = useState<Array<IDataSet>>([]);
    const { role, setRole } = useRole();
    useEffect(() => {
        (async () => {
            let dataSets: Array<IDataSet> = [];
            if (props.entityDatasets.isWorld) {
                dataSets = await DataSetApi.getAll();
            } else {
                const field = props.entityDatasets.isCountry ? PropertyNames.Country : PropertyNames.Region;
                dataSets = await DataSetApi.getAll(field, props.entityDatasets.entity);
            }
            dataSets.forEach(d => (d.isSelected = props.entityDatasets.datasets.some(ed => ed === d.dataSetName)));
            setDataSets(dataSets);
        })();
    }, []);

    return (
        <div className="mt-2 ml-1 mr-1">
            <MDBRow>
                {dataSets.map((d: IDataSet, index: number) => (
                    <MDBCol size="4" key={index}>
                        <DataSet
                            dataset={d}
                            onClick={() => {
                                if (d.isSelected) props.entityDatasets.datasets.push(d.dataSetName ?? "");
                                else
                                    props.entityDatasets.datasets = props.entityDatasets.datasets.filter(
                                        (cd: any) => cd !== d.dataSetName
                                    );
                                setRole({ ...role });
                            }}
                        />
                    </MDBCol>
                ))}
            </MDBRow>
            {props.isSubmited && !props.entityDatasets.datasets?.length && (
                <MDBAlert color="danger">Please select at least 1 item in the Dataset Access.</MDBAlert>
            )}
        </div>
    );
};
