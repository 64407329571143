import { httpPost } from "./request/requestApi";
import { IQuery } from "../../components/queries/interfaces/IQuery";
import { DatesService } from "../../components/queries";
import { DataSetService, CorrelationService } from "../../components/queries/common";

const controllerEndPoint = "report";

export class ReportApi {
    static async get(query: IQuery, isExportContext: boolean): Promise<string> {
        let dataSets = DataSetService.getSelected(query.dataSets);
        dataSets = DatesService.getDatasetsWithCastedDatePeriods(dataSets);
        if (query.isCorrelation) CorrelationService.updateCorrelationRelatedDataSetDateRanges(dataSets);
        DataSetService.createSorts(dataSets, query);
        const endPoint = isExportContext ? "export-to-excel" : "get";
        return await httpPost(`${controllerEndPoint}/${endPoint}`, {
            body: {
                ...query,
                dataSets: dataSets
            }
        });
    }
}