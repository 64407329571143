import React, { PropsWithChildren, useState } from "react";
import { IArticle } from "../../../../../typings";
import { editableArticleContext } from "./editableArticleContext";

export const EditableArticleProvider = (props: PropsWithChildren<{}>) => {
    const [editableArticle, setEditableArticle] = useState<IArticle | undefined>();
    const [isSubmited, setIsSubmited] = useState(false);

    const value: any = {
        isSubmited,
        setIsSubmited,
        editableArticle,
        setEditableArticle
    };

    return <editableArticleContext.Provider value={value}>{props.children}</editableArticleContext.Provider>;
};
