import React from "react";
import { MDBBtn } from "mdbreact";
import { ButtonStyled } from "./buttonStyled";

interface IProps {
    text: string;
    size?: "sm" | "lg";
}

export const DefaultFormButton = (props: IProps) => (
    <MDBBtn
        size={props.size}
        className="btn-block"
        type="submit"
        flat
        style={ButtonStyled.DefaultFormButton}
    >
        {props.text}
    </MDBBtn>
);
