import { IQuery } from "../../interfaces/IQuery";
import { DataSetNames } from "../../../../common/dataSetNames";
import { IDataSet } from "../../../../typings";
import { CalculationService } from "../../datesCalculations/calculations/calculationService";
import { DataSetService } from "../../common";

const isVisualizationAvailable = (dataSets: Array<IDataSet>, isCorrelation: boolean | undefined) =>
    (dataSets.some(
        d =>
            d.dataSetName === DataSetNames.Sales ||
            d.dataSetName === DataSetNames.Inventory ||
            d.dataSetName === DataSetNames.Production ||
            DataSetService.isRate(d.dataSetName)
    )) ||
    (dataSets.length === 2 && isCorrelation);

const isCombinedChartAvailable = (query: IQuery) =>
    query.isSAAR ||
    DataSetService.getSelected(query.dataSets).length == 1 &&
    query.dataSets?.some(
        d => d.isSelected &&
            (d.dateRanges?.some(dr => CalculationService.isChangePercentCalculation(dr.calculations))));

const isCombinationChartAvailable = (query: IQuery) =>
    query.chartData !== undefined && query.chartData.yearOverYearPercentChangeData?.any();

export const VisualizationAvailabilityService = {
    isVisualizationAvailable,
    isCombinedChartAvailable,
    isCombinationChartAvailable
};
