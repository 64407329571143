import React, { ReactElement } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { DatesService } from "./services/datesService";
import { useQuery } from "../../store/queryContext";
import { QueryMode } from "../../enums/queryMode";
import { periods, dateRanges } from "../../../../typings";
import { ButtonStyled } from "../../../UI";
import { Styled } from "./styles/styled";

const periodOrder = [
    {
        period: periods.month,
        locale: "Month"
    },
    {
        period: periods.quarter,
        locale: "Quarter"
    },
    {
        period: periods.year,
        locale: "Year"
    },
    {
        period: periods.modelYear,
        locale: "Model Year"
    }
];

interface IProps {
    dateRange: dateRanges | undefined;
    setPeriod: (period: periods) => void;
}

export const SelectRangeType = (props: IProps) => {
    const { query, queryMode } = useQuery();

    return (
        <MDBDropdown disabled={queryMode === QueryMode.View}>
            <MDBDropdownToggle caret style={{ ...ButtonStyled.ChoiseSelectedButton, ...Styled.Select }} color="none">
                {periodOrder.find(cur => cur.period === props.dateRange?.period)?.locale}
            </MDBDropdownToggle>
            <MDBDropdownMenu className="select-range-type">
                {periodOrder.reduce((accum, cur) => {
                    if (DatesService.isPeriodAvailable(query, cur.period, props.dateRange?.calculations)) {
                        return [
                            ...accum,
                            <MDBDropdownItem key={cur.period} onClick={() => props.setPeriod(cur.period)}>
                                {cur.locale}
                            </MDBDropdownItem>
                        ];
                    }
                    return accum;
                }, Array<ReactElement>())}
            </MDBDropdownMenu>
        </MDBDropdown>
    );
};
