import React, { useState } from "react";
import { MDBIcon } from "mdbreact";
import { CustomInputField } from "./customInputField ";
import { PasswordPattern } from "../../passwordManagement/passwordPattern";

interface IProps {
    label: string;
    value?: string;
    isSubmitted?: boolean;
    isIconPaddingNeeded?: boolean;
    getValue: (password: string) => void;
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}

export const PasswordField = (props: IProps) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <CustomInputField
            getValue={props.getValue}
            placeholder={props.label}
            label={props.label}
            type={showPassword ? "text" : "password"}
            icon="lock"
            value={props.value}
            isSubmitted={props.isSubmitted}
            maxLength={254}
            mask={PasswordPattern}
            isRequired
            onChange = {props.onChange}
            maskError="Please fill in Password that is at least 8 characters long with a numeric and capital letter."
        >
            <MDBIcon
                icon={showPassword ? "eye" : "eye-slash"}
                className={props.isIconPaddingNeeded ? "btn-toggle-pass pl-5" : "btn-toggle-pass"}
                fixed
                onClick={() => setShowPassword(!showPassword)}
            />
        </CustomInputField>
    );
};
