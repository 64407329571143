import { CSSProperties } from "react";

const CorrelationStepInput = {
    fontSize: "97%"
};

const Select = {
    width: "10rem",
    marginLeft: "1.25rem",
    marginTop: "0.5rem",
    padding: "0.6rem"
};

const RangeContainer = {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "0.5rem"
};

const ToLabel = {
    marginTop: "auto",
    marginBottom: "auto",
    color: "var(--gray)"
};

const RangeCardClose = {
    position: "absolute",
    right: "0.3rem",
    top: "0.15rem"
} as CSSProperties;

export const Styled = {
    CorrelationStepInput,
    Select,
    RangeContainer,
    ToLabel,
    RangeCardClose
};
