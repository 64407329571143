import React from "react";
import { useQuery } from "../store/queryContext";
import { MDBDataTable } from "mdbreact";
import { TableColumns } from "./queriesTableColumns";
import { DeleteQueryButton } from "../buttons/deleteQueryButton";
import { Styled } from "./styles/styled";
import { QueryMode } from "../enums/queryMode";
import { CopyButton } from "../buttons/copyButton";
import { RedirectToQueryButton } from "../buttons/redirectToQueryButton";
import { QueryCreationSteps, Spinner, ScrollTable } from "../../UI";
import { EditQueryNameField } from "./editQueryNameField";
import "./styles/style.css";
import { getDate } from "../../../common";
import { RenameQueryButton } from "../buttons/renameQueryButton";
import { useUser } from "../../../stores";
import { QueryCheckbox } from "../buttons/queryCheckBox";

export const MyQueriesTable = () => {
    const { queryMode, userQueries } = useQuery();
    const { user } = useUser();
    const arrQueryIds: (number | undefined)[] = [];
    
    // To hide the 'Copy' column for users other than Admin/SuperAdmin
    if(!user.isAdmin && !user.isSuperAdmin){ 
        const colCopyIndex = TableColumns.findIndex(object => {
            return object.field === 'copy';
        });
        if(colCopyIndex !== -1){
            TableColumns.splice(colCopyIndex, 1);}      
    }// 

    const rows = userQueries.map(query => ({
        queryName: <EditQueryNameField key={query.queryId} query={query} />,
        lastModifiedDate: getDate(query.lastModifiedDate, "/"),
        copy: <QueryCheckbox
            id={"queryChk" + query.queryId?.toString()}
            onChange={async (e: React.FormEvent<HTMLInputElement>) => { 
                if(e.currentTarget.checked){
                    arrQueryIds.push(query.queryId);
                }
                else{
                    var filterElement = arrQueryIds.indexOf(query.queryId);
                    arrQueryIds.splice(filterElement, 1);
                }
                query.isCopied = e.currentTarget.checked;
            }}
            checked={query.isCopied}
        />,
        actions: (
            <div style={Styled.ActionsColumn} className="d-flex">
                <RenameQueryButton query={query} />
                <RedirectToQueryButton
                    tooltip="Run"
                    queryId={query.queryId}
                    icon="file-export"
                    step={QueryCreationSteps.PreviewAndExport}
                />
                {queryMode === QueryMode.EditAndDelete && (
                    <>
                        <RedirectToQueryButton
                            tooltip="Edit"
                            queryId={query.queryId}
                            icon="pencil-alt"
                            step={QueryCreationSteps.SelectMarketAndDataset}
                        />
                        <DeleteQueryButton tooltip="Delete" queryId={query.queryId} />
                    </>
                )}
            </div>
        )
    }));

    return (
        <>
            <div className = "mt-n3 buttonCss">
                {(user.isAdmin || user.isSuperAdmin) && <CopyButton tooltip="Copy" query={arrQueryIds} />}
            </div>
            <Spinner />            
            <ScrollTable
                className="actions-3 nameColumn dateField mt-4 queryTable"
                maxHeight="calc(100vh - 60px)"
                data={{
                    columns: TableColumns,
                    rows: rows
                }}
            />
        </>
    );
};
