import React from "react";
import {
    QueryProvider,
    MyQueriesTable,
    QueryMode
} from "../components/queries";
import { MDBContainer } from "mdbreact";
import { DefaultParagraph, GridProvider } from "../components/UI";
import { PasteButton } from "../components/queries/buttons/pasteButton";
import { useUser } from "../stores";

export const MyQueriesPage = () => {
    const { user } = useUser();
    return (
        <GridProvider>
            <QueryProvider queryMode={QueryMode.EditAndDelete} isQueriesLoadNeeded>
                <MDBContainer>
                    <DefaultParagraph text="My Queries" />
                    <div className="d-flex flex-wrap">
                    <div className = "ml-3 mt-n3">
                        {(user.isAdmin || user.isSuperAdmin) && <PasteButton />}
                    </div>
                    <MyQueriesTable /> 
                    </div>
                </MDBContainer>
            </QueryProvider>
        </GridProvider>
    )
};
