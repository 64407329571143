import React from "react";
import { MDBNavbar, MDBNavItem } from "mdbreact";
import { Styled } from "./Styles/styled";
import { Link } from "react-router-dom";
import { Routes } from "../../../common";

export const DefaultPageHeader = () => (
    <MDBNavbar style={Styled.Header} expand="md">
        <MDBNavItem className="d-none d-md-inline" style={Styled.DefaultPageHeader}>
            <Link to={Routes.Login} style={Styled.DefaultPageHeaderLink}>
                Wards Intelligence Data Query
            </Link>
        </MDBNavItem>
    </MDBNavbar>
);
