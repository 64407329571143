import React from "react";
import { MDBRow } from "mdbreact";
import { IPeriodsRanges } from "../../../interfaces/IPeriodsRanges";
import { datePeriod } from "../../../../../typings";
import { DatesService } from "../services/datesService";
import { YearsPicker } from "../pickers/yearsPicker";
import { Styled } from "../styles/styled";

interface IProps {
    startYear: string | undefined;
    endYear: string | undefined;
    ranges: IPeriodsRanges | undefined;
    period: datePeriod | undefined;
    startYearSetter: (year: string | undefined) => void;
    endYearSetter: (year: string | undefined) => void;
    to?: string;
}

export const YearsRange = (props: IProps) => {
    if (DatesService.getYear(props.endYear, props.ranges) < DatesService.getYear(props.startYear, props.ranges)) {
        DatesService.setEndYearOfPeriod(undefined, props.endYearSetter, props.period);
    }
    return (
        <MDBRow style={Styled.RangeContainer}>
            <YearsPicker
                value={props.startYear}
                text="from"
                from={props.ranges?.beginYear}
                to={props.to ? props.to : props.ranges?.endYear}
                ranges={props.ranges}
                isStart
                period={props.period}
                setter={props.startYearSetter}
            />
            <div style={Styled.ToLabel}>to</div>
            <YearsPicker
                value={props.endYear}
                text="to"
                from={props.startYear ? props.startYear : props.ranges?.beginYear}
                ranges={props.ranges}
                to={props.ranges?.endYear}
                period={props.period}
                setter={props.endYearSetter}
            />
        </MDBRow>
    );
};
