import React from "react";
import { Accordion, ButtonGroup } from "../../UI";
import { Attributes } from "./attributes";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "..";
import { IDataSet } from "../../../typings";
import { DataSetFilterService } from "../common";

interface IProps {
    dataSet: IDataSet;
    index: number;
    collapseId: number | undefined;
    setCollapseId: (collapseId: number | undefined) => void;
}

export const AttributeSelector = (props: IProps) => {
    const { queryMode, query, setQuery } = useQuery();

    const changeAttributesOptions = (isTotalIndustry: boolean) => {
        props.dataSet.isTotalIndustry = isTotalIndustry;
        if (props.dataSet.isTotalIndustry) {
            unselectAttributes();
        }
        setQuery({ ...query });
    };

    const unselectAttributes = () => {
        props.dataSet.attributes?.forEach(a => {
            DataSetFilterService.deleteFilter(props.dataSet, a.description);
            a.isSelected = false;
            a.options = [];
        });
    };

    return (
        <Accordion
            label={props.dataSet.dataSetName}
            collapseId={props.collapseId}
            index={props.index}
            setCollapseId={props.setCollapseId}
        >
            <ButtonGroup
                isDisabled={queryMode === QueryMode.View}
                isLeftButtonSelected={props.dataSet.isTotalIndustry}
                onButtonClick={changeAttributesOptions}
                leftButtonText="Total Industry"
                rightButtonText="Part of Industry"
            />
            {!props.dataSet.isTotalIndustry && <Attributes dataSet={props.dataSet} />}
        </Accordion>
    );
};
