const AccordionSelected = {
    background: "linear-gradient(114.88deg, #28A5AD 2.45%, rgba(255, 255, 255, 0) 73.11%), #01688F",
    height: "52px",
    fontSize: "24px",
    color: "white",
    minWidth: "300px"
};

const Accordion = (isFailed: boolean | undefined) => {
    return {
        background: isFailed ? "#F8D7DA" : "",
        height: "52px",
        fontSize: "24px",
        color: "black",
        minWidth: "300px"
    };
};

const CardHeader = {
    fontSize: "20px"
};

const AccordionCard = {
    minWidth: "300px"
};

export const Styled = {
    AccordionSelected,
    Accordion,
    CardHeader,
    AccordionCard
};
