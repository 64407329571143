const FieldContainer = {
    margin: "5px 0px 0px 0px",
    padding: "5px",
    fontSize: "14px"
};

const Icon = {
    color: "#626262",
    margin: "3px 12px 0 0"
};

export const Styled = {
    FieldContainer,
    Icon
};
