import React from "react";
import { DocumentManagementGrid } from "../components/documentation";
import { MDBContainer } from "mdbreact";
import { DefaultParagraph, Preloader, FilesProvider } from "../components/UI";
import { AttachmentType } from "../typings";

export const DocumentationPage = () => (
    <FilesProvider attachmentType={AttachmentType.Documentation}>
        <MDBContainer>
            <DefaultParagraph text="Documentation" />
            <Preloader />
            <DocumentManagementGrid />
        </MDBContainer>
    </FilesProvider>
);
