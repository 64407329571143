import React, { useState } from "react";
import { MDBContainer } from "mdbreact";
// @ts-ignore
import { store } from "react-notifications-component";
import { useUser } from "../../stores/user/userContext";
import {
    Notifications,
    NotificationMessages,
    DefaultButton,
    ButtonStyled,
    DefaultModal
} from "../UI";
import { AccountApi } from "../../core/api";

export const ResetPasswordButton = () => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const { user } = useUser();

    const resetPassword = async () => {
        setIsModalOpened(!isModalOpened);
        store.addNotification(
            Notifications.Successfull(NotificationMessages.ResetLinkWasSent)
        );
        await AccountApi.forgotPassword(user.email);
    };

    return (
        <MDBContainer className="mt-4">
            <DefaultButton
                text="Reset Password"
                style={ButtonStyled.PrimaryButton}
                size="lg"
                onClick={() => setIsModalOpened(!isModalOpened)}
            />
            <DefaultModal
                isOpen={isModalOpened}
                onCancel={() => setIsModalOpened(false)}
                onСonfirm={resetPassword}
                cancelButtonText="Cancel"
                bodyText="Send email to reset password?"
                confirmButtonText="Reset Password"
            />
        </MDBContainer>
    );
};
