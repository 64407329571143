const ForgotPasswordFormContainer = {
    marginTop: "13%"
};

const ResetPasswordFormContainer = {
    marginTop: "10%"
};

const ListPadding = {
    padding: "0 15px"
};

export const Styled = {
    ForgotPasswordFormContainer,
    ResetPasswordFormContainer,
    ListPadding
};
