import { ICalculation } from "../../interfaces/ICalculation";
import { IQuery } from "../../interfaces/IQuery";
import { CalculationsApi } from "../../../../core/api";
import { dateRanges } from "../../../../typings";
import { CalculationNames } from "../../common/calculationNames";

const isChange = (calculations: Array<ICalculation> | undefined): boolean =>
    isCalculationSelected(calculations, CalculationNames.Change);

const isSaar = (calculations: Array<ICalculation> | undefined): boolean =>
    isCalculationSelected(calculations, CalculationNames.SAAR);

const isRateOptionVolume = (calculations: Array<ICalculation> | undefined): boolean =>
    isCalculationSelected(calculations, CalculationNames.RatesOptionsVolume);

const isRateSales = (calculations: Array<ICalculation> | undefined): boolean =>
    isCalculationSelected(calculations, CalculationNames.RatesSales);

const isRateSalesForecast = (calculations: Array<ICalculation> | undefined): boolean =>
    isCalculationSelected(calculations, CalculationNames.RatesSalesForecast);

const isSumPercent = (calculations: Array<ICalculation> | undefined): boolean =>
    isCalculationSelected(calculations, CalculationNames.SumPercent);

const isChangePercentCalculation = (calculations: Array<ICalculation> | undefined): boolean =>
    isSumPercent(calculations) && isChange(calculations);

const isCalculationSelected = (calculations: Array<ICalculation> | undefined, calculationName: string): boolean =>
    calculations !== undefined && calculations.some(c => c.calculationName === calculationName && c.isSelected);

const updateSpecificCalculations = async (
    isSelected: boolean,
    calculationName: string,
    dataSetName: string,
    query: IQuery
) => {
    const dataSet = query.dataSets?.find(d => d.dataSetName === dataSetName);
    dataSet?.dateRanges?.forEach(dr => updateSpecificCalculation(dr, isSelected, calculationName));
};

const updateSpecificCalculation = async (
    dateRange: dateRanges,
    isSelected: boolean | undefined,
    calculationName: string
) => {
    const calculation = dateRange.calculations?.find(c => c.calculationName === calculationName);
    if (calculation !== undefined) calculation.isSelected = isSelected;
};

const updateDateRangesCalculations = async (query: IQuery) => {
    const dataSets = query.dataSets?.filter(ds => ds.dateRanges !== null);
    if (!dataSets) return;
    for (const dataSet of dataSets) {
        if (dataSet.dateRanges) {
            for (const dateRange of dataSet.dateRanges) {
                await updateDateRangeCalculations(dateRange, dataSet.dataSetName);
            }
        }
    }
};

const updateDateRangeCalculations = async (dateRange: dateRanges, dataSetName: string | undefined) => {
    dateRange.calculations = await CalculationsApi.getAvailableCalculations(dateRange.calculations, dataSetName);
};

export const CalculationService = {
    updateSpecificCalculation,
    updateDateRangesCalculations,
    updateDateRangeCalculations,
    updateSpecificCalculations,
    isChange,
    isSaar,
    isRateOptionVolume,
    isRateSales,
    isRateSalesForecast,
    isChangePercentCalculation
};
