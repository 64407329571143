import React, { useEffect } from "react";
import { useRole } from "../store/roleContext";
import { ISelectOption } from "../../queries/interfaces/ISelectOption";
import { CountryApi } from "../../../core/api";
import { Selector } from "./selector";
import { Constants } from "../../queries/common/constants";

interface IProps {
    isSubmitted: boolean;
}

export const CountrySelector = (props: IProps) => {
    const { role, setRole, countries, setCountries } = useRole();

    useEffect(() => {
        (async () => {
            const countries = await CountryApi.getAll();
            const options = new Array<ISelectOption>();
            countries.forEach(c =>
                options.push({
                    value: c.country_ID,
                    text: c.description,
                    checked: role.entityDatasets?.some(rc => rc.isCountry && rc.entity === c.country_ID)
                })
            );
            setCountries(options);
        })();
    }, []);

    return (
        <Selector
            isSubmitted={props.isSubmitted}
            selected="Select countries"
            property="isCountry"
            options={countries}
            getValue={() => {
                if (role.isUsViewAllowed && !role.entityDatasets?.some(cd => cd.entity === Constants.UsCountry))
                    role.isUsViewAllowed = false;
                setRole({ ...role });
            }}
            text="Country Access"
        />
    );
};
