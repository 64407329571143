const YourSessionIsExpired = "Your session has expired. Please login.";
const PasswordSuccessfullyChanged = "Your password has been successfully changed.";
const ResetLinkWasSent = "Reset password email has been sent.";
const UserAccountHasBeenSuccessfullyDeleted = "The user account has been successfully deleted.";
const TheQueryHasBeenSuccessfullyCopied = "The query has been successfully copied.";
const TheQueryHasBeenSuccessfullyPasted = "The query has been successfully pasted.";
const TheQueryHasBeenSuccessfullyDeleted = "The query has been successfully deleted.";
const TheQueryHasBeenSuccessfullyCreated = "The query has been successfully created.";
const TheQueryHasBeenSuccessfullyUpdated = "The query has been successfully edited.";
const TheQueriesHasBeenSuccessfullySaved = "The queries have been successfully saved.";
const UserAccountHasBeenDisabled = "Your account has been disabled. Please contact the administrator.";
const ChangeHasBeenSuccessfullySaved = "Changes have been successfully saved";
const PleaseCopyQueryToPasteItToTheList = "Please copy a query to paste it to the list";
const ContactHasBeenSuccessfullyDeleted = "Contact has been successfully deleted!";
const PleaseSelectQueryToCopy = "Please select a query to copy it";

export const NotificationMessages = {
    PasswordSuccessfullyChanged,
    YourSessionIsExpired,
    ResetLinkWasSent,
    UserAccountHasBeenSuccessfullyDeleted,
    TheQueryHasBeenSuccessfullyCopied,
    TheQueryHasBeenSuccessfullyPasted,
    TheQueryHasBeenSuccessfullyDeleted,
    TheQueryHasBeenSuccessfullyCreated,
    TheQueriesHasBeenSuccessfullySaved,
    UserAccountHasBeenDisabled,
    ChangeHasBeenSuccessfullySaved,
    PleaseCopyQueryToPasteItToTheList,
    TheQueryHasBeenSuccessfullyUpdated,
    ContactHasBeenSuccessfullyDeleted,
    PleaseSelectQueryToCopy
};
