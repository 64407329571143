import React from "react";
import { useContacts } from "..";
import { DefaultButton, ButtonStyled } from "../../UI";

export const InsertContactButton = () => {
    const { contacts, setContacts } = useContacts();

    const insert = () => {
        const newArray = [...contacts];
        newArray.push({ isInserted: true });
        setContacts(newArray);
    };

    return (
        <DefaultButton
            onClick={insert}
            style={ButtonStyled.SecondaryButton}
            icon="plus"
            text="Create"
        />
    );
};
