import { MDBBtnGroup, MDBRow, MDBCol } from "mdbreact";
import React from "react";
import { DefaultButton, ButtonStyled } from "..";

interface IProps {
    leftButtonText: string;
    rightButtonText: string;
    isLeftButtonSelected: boolean | undefined;
    onButtonClick?: (value : boolean) => void;
    isDisabled?: boolean;
}

export const ButtonGroup = (props: IProps) => {
    const getButtonStyle = (isSelected: boolean | undefined) => {
        return isSelected ? ButtonStyled.ChoiseSelectedButton : ButtonStyled.ChoiseBaseButton;
    };
    return (
        <MDBRow className="ml-2 mt-3">
            <MDBCol size="8">
                <MDBBtnGroup>
                    <DefaultButton
                        disabled={props.isDisabled}
                        text={props.leftButtonText}
                        style={getButtonStyle(props.isLeftButtonSelected)}
                        size="sm"
                        onClick={() => {
                            if (props.onButtonClick) props.onButtonClick(true);
                        }}
                    />
                    <DefaultButton
                        disabled={props.isDisabled}
                        text={props.rightButtonText}
                        style={getButtonStyle(!props.isLeftButtonSelected)}
                        size="sm"
                        onClick={() => {
                            if (props.onButtonClick) props.onButtonClick(false);
                        }}
                    />
                </MDBBtnGroup>
            </MDBCol>
        </MDBRow>
    );
};
