const Base = {
    minWidth: "170px",
    marginTop: "0.6rem",
    background: "#EDF1F1",
    color: "#626262",
    padding: "0 0 0 0"
};

const Active = {
    ...Base,
    background: "#108393",
    color: "white"
};

const FontSelector = { marginRight: "5px", marginBottom: "0px" };

const FontStyleSelector = { ...FontSelector, maxWidth: "70px" };

const FontSizeSelector = { ...FontSelector, maxWidth: "50px" };

export const Styled = {
    Active,
    Base,
    FontStyleSelector,
    FontSizeSelector
};
