import React from "react";
import { IDateRangeComponentProps } from "../interfaces/IDateRangeComponentProps";
import { useQuery } from "../store/queryContext";
import { MDBCloseIcon } from "mdbreact";
import { QueryMode } from "../enums/queryMode";
import { QueryCreationSteps } from "../../UI";
import { DataSetService, DateRangesValidationService } from "../common";

export const RemoveDateRangeButton = (props: IDateRangeComponentProps) => {
    const { query, setQuery, queryMode, errors, setErrors } = useQuery();
    const onClick = () => {
        if (queryMode !== QueryMode.View && props.dataSet!.dateRanges!.length > 1) {
            const newDataSets = [...query.dataSets];
            newDataSets.find(d => d === props.dataSet)?.dateRanges?.splice(props.index, 1);
            setQuery({ ...query, dataSets: newDataSets });
            if (
                DataSetService.getSelected(query.dataSets).every(d =>
                    d.dateRanges?.every(dr => DateRangesValidationService.isValid(dr, query))
                )
            )
                setErrors([...errors.filter(e => e.step !== QueryCreationSteps.ChooseDatesAndCalculations)]);
        }
    };

    return <MDBCloseIcon onClick={onClick} />;
};
