import React from "react";
import { Styled } from "./styles/styled";
import { useQuery } from "../store/queryContext";
import { QueryService } from "../common";
import { DataReceiver } from "./services/dataReceiver";

export const RefreshTableButton = () => {
    const { query, setQuery } = useQuery();
    const onClick = async () => {
        const file = await DataReceiver.getReportFile(query, false);
        setQuery({ ...query, report: file });
    };
    return (
        <>
            {QueryService.isReportAvailable(query) && (
                <span style={Styled.Span} className="cursor-pointer pl-3 RefreshPreviewdiv" onClick={onClick}>
                    Refresh Preview
                </span>
            )}
        </>
    );
};
