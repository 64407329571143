import React from "react";
import { DefaultButton, Notifications, NotificationMessages, ButtonStyled } from "../../UI";
import { QueryApi } from "../../../core/api";
import { useQuery } from "../store/queryContext";
// @ts-ignore
import { store } from "react-notifications-component";
import { localStorageService } from "../../../common";
import { Constants } from "../common/constants";

export const PasteButton = () => {
    const { userId } = useQuery();
    const { setUserQueries } = useQuery();

    const paste = async () => {
        const queryId = localStorageService.getValue(Constants.QueryLocalStorageKey);
        if (queryId && queryId.length > 0) {
            for(var i=0; i<queryId.length; i++){
                const query = await QueryApi.get(queryId[i]);
                query.queryId = undefined;
                query.userId = userId;
                await QueryApi.create(query);
            }
            setUserQueries(await QueryApi.getAll(userId));
            store.addNotification(Notifications.Successfull(NotificationMessages.TheQueryHasBeenSuccessfullyPasted));
        } else store.addNotification(Notifications.Warning(NotificationMessages.PleaseCopyQueryToPasteItToTheList));
    };

    return (
        <DefaultButton
            className="ml-0"
            onClick={paste}
            icon="paste"
            style={ButtonStyled.SecondaryButton}
            size="lg"
            text="Paste"
        />
    );
};
