import React, { useEffect } from "react";
import { MDBSpinner, MDBContainer } from "mdbreact";
import { usePromiseTracker } from "react-promise-tracker";
import { Constants } from "./constants";

interface IProps {
    green?: boolean;
    red?: boolean;
    yellow?: boolean;
    multicolor?: boolean;
    small?: boolean;
    big?: boolean;
}

export const Spinner = (props: IProps) => {
    const { promiseInProgress } = usePromiseTracker();
    useEffect(() => {
        const root = document.getElementById("root");
        if (promiseInProgress) {
            root?.classList.add(Constants.DisablingClassName);
        } else root?.classList.remove(Constants.DisablingClassName);
    }, [promiseInProgress]);
    return (
        <MDBContainer className="spinner">
            {promiseInProgress && (
                <MDBSpinner
                    green={props.green}
                    red={props.red}
                    yellow={props.yellow}
                    multicolor={props.multicolor}
                    small={props.small}
                    big={props.big}
                />
            )}
        </MDBContainer>
    );
};
