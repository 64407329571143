import React from "react";
import { MDBTable } from "mdbreact";
import { ContactsManagementGridBody, ContactsProvider, GridHeaders } from ".";
import { GridHeader } from "../UI";

export const ContactUsTable = () =>
    <ContactsProvider>
        <MDBTable>
            <GridHeader headers={GridHeaders} />
            <ContactsManagementGridBody />
        </MDBTable>
    </ContactsProvider>