import React, { PropsWithChildren, useEffect, useState } from "react";
import { ArticleApi } from "../../../../../core/api";
import { IArticle } from "../../../../../typings";
import { articleContext } from "./articleContext";

export const ArticleProvider = (props: PropsWithChildren<{}>) => {
    const [articles, setArticles] = useState<Array<IArticle>>([]);
    useEffect(() => {
        (async () => {
            setArticles(await ArticleApi.getAll());
        })();
    }, []);

    const value: any = {
        articles,
        setArticles
    };

    return <articleContext.Provider value={value}>{props.children}</articleContext.Provider>;
};
