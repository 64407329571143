import React from "react";
import { MDBContainer } from "mdbreact";
import { ArticleTypes } from "../typings";
import { DefaultParagraph, EditorCancelButton, EditorSaveButton, Constants, ArticleEditor } from "../components/UI";
import { EditableArticleProvider } from "../components/UI/textEditors/articleEditor/stores/editableArticleProvider";

export const HelpManagementPage = () => {
    return (
        <EditableArticleProvider>
            <MDBContainer>
                <DefaultParagraph text="Help" />
                <EditorSaveButton articleType={ArticleTypes.Help} bodyMaxLength={Constants.HelpMaxLength} />
                <EditorCancelButton articleType={ArticleTypes.Help} />
                <ArticleEditor
                    articleType={ArticleTypes.Help}
                    maxLength={Constants.HelpMaxLength}
                    propertyName="articleBody"
                    label="Help"
                />
            </MDBContainer>
        </EditableArticleProvider>
    );
};
