export const ColorPalette = [
    "#00719C",
    "#7A2939",
    "#A88938",
    "#005A5B",
    "#4A194D",
    "#7A4429",
    "#00AC9D",
    "#C24B47",
    "#622E8A",
    "#00677C",
    "#C45E2F",
    "#A7AE34",
    "#34509D",
    "#A2336C",
    "#C18644",
    "#16411F",
    "#308691",
    "#F86154",
    "#F6A92C",
    "#18227B",
    "#7036F7",
    "#C8765B",
    "#1F5C51",
    "#6F2625",
    "#C1B044",
    "#14353D",
    "#822B75",
    "#5377C6",
];