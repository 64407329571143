import React from "react";
import { QueryCreationSteps } from "..";
import { useQuery } from "../../queries/store/queryContext";

interface IProps {
    step: QueryCreationSteps;
    index?: number;
}

export const StepError = (props: IProps) => {
    const { errors } = useQuery();

    const getError = () => {
        const error = errors.find(e => e.step === props.step && (e.index === props.index || !props.index));
        return error ? error.message : "";
    };

    return <div className="text-danger">{getError()}</div>;
};
