import React, { useState, useEffect } from "react";
import { MDBInput } from "mdbreact";
import { useGrid } from "..";
import { validateEmail, EmailFailedMessage } from "../../../common";

interface IProps {
    defaultValue: string | undefined;
    value: string | undefined;
    getValue: (value: string) => void;
    label: string;
    isEmail?: boolean;
    isFile?: boolean;
    className?: string;
    isDisabled?: boolean;
}

export const InputCell = (props: IProps) => {
    const { isSave } = useGrid();
    const [value, setValue] = useState<string>(props.defaultValue || props.value || "");

    useEffect(
        () => {
            setValue(props.value || "");
        },
        [props.value]
    );

    const getErrorMessage = () => {
        if (!value?.trim().length) {
            return `Please fill in ${props.label} ${props.isFile ? "of a file" : "field"}.`;
        }
        if (props.isEmail && !validateEmail(value)) return EmailFailedMessage;
    };

    return (
        <>
            <MDBInput
                className={props.className}
                disabled={props.isDisabled}
                maxLength={254}
                valueDefault={props.defaultValue}
                value={value}
                getValue={(value: any) => {
                    setValue(value.toString())
                    props.getValue(value.toString())
                }}
            />
            {isSave && <div className="text-danger">{getErrorMessage()}</div>}
        </>
    );
};
