import React from "react";
import { DefaultButton } from "..";
import { useQuery } from "../../queries/store/queryContext";
import { ButtonStyled } from "./buttonStyled";
import { QueryService } from "../../queries/common";
import { DataReceiver } from "../../queries/previewExport/services/dataReceiver";
import { FileService } from "../../../common";

export const ExportToExcelButton = () => {
    const { query } = useQuery();
    return (
        <DefaultButton
            text="EXPORT REPORT"
            size="sm"
            style={ButtonStyled.PrimaryButton}
            disabled={!QueryService.isReportAvailable(query)}
            onClick={async () => {
                const file = await DataReceiver.getReportFile(query, true);
                FileService.downloadFile(file? file : "", query.title + ".xlsx");
            }}
        />
    );
};
