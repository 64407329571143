import { MDBSelect } from "mdbreact";
import React, { useState, useEffect } from "react";
import { ISort } from "../../../../typings";
import { useQuery } from "../../store/queryContext";
import { QueryMode } from "../../enums/queryMode";
import { ISelectOption } from "../../interfaces/ISelectOption";

interface IProps {
    sort: ISort;
    options: Array<ISelectOption>;
    propertyName: string;
    style: {};
}

export const FontPropertySelector = (props: IProps) => {
    const { queryMode } = useQuery();

    const getOptions = () => {
        if (props.sort[props.propertyName]?.length)
            props.options.forEach(so => (so.checked = so.value === props.sort[props.propertyName]));
        return props.options;
    };

    useEffect(() => {
        if (!props.sort[props.propertyName]) props.sort[props.propertyName] = props.options.find(o => o.checked)?.value;
    }, []);

    const [styleOptions, setStyleOptions] = useState<Array<ISelectOption>>(getOptions());

    return (
        <MDBSelect
            className={queryMode === QueryMode.View ? "disabled" : ""}
            style={props.style}
            options={styleOptions}
            getValue={(values: any) => {
                if (values.length) props.sort[props.propertyName] = values[0];
                else {
                    props.sort[props.propertyName] = undefined;
                    setStyleOptions(getOptions());
                }
            }}
        />
    );
};
