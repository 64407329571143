import React, { useState } from "react";
import { IconWithTooltip, SaveOverExistedFileModal } from "../../UI";
import { IFile, AttachmentType } from "../../../typings";
import { FileApi } from "../../../core/api";
import { useFiles } from "../files/store/filesContext";
import { ButtonStyled } from "./buttonStyled";
import { Notifications, NotificationMessages } from "..";
//@ts-ignore
import { store } from "react-notifications-component";
import { useGrid } from "../grid/store/gridContext";

interface IProps {
    file: IFile;
}

export const SaveFileButton = (props: IProps) => {
    const { files, setFiles } = useFiles();
    const [saveOverExistedOpen, setSaveOverExistedOpen] = useState<boolean>(false);
    const { setIsSave } = useGrid();

    const updateFile = async (file: IFile) => {
        const newName = file.newName.trim();
        setIsSave(true);
        if (newName !== "") {
            file.name = newName;
            file.isUpdated = false;
            await FileApi.update(file);
            setFiles(await FileApi.getAll(AttachmentType.Documentation));
            store.addNotification(
                Notifications.Successfull(NotificationMessages.ChangeHasBeenSuccessfullySaved)
            );
        }
    };

    const save = async (saveOverExisted: boolean = false) => {
        if (props.file.isUpdated) {
            const fileWithSameName = files
                .filter(f => f.name === props.file.newName?.trim() && f.id !== props.file.id)
                .first();

            if (fileWithSameName) {
                if (saveOverExisted) {
                    await FileApi.delete(fileWithSameName.id);
                    await updateFile(props.file);
                } else {
                    setSaveOverExistedOpen(true);
                }
            } else {
                await updateFile(props.file);
            }
        }
    };

    const confirmSave = () => {
        save(true);
        setSaveOverExistedOpen(false);
    };

    return (
        <>
            <IconWithTooltip style={ButtonStyled.SaveButtonIcon} icon="save" onClick={() => save()} tooltip="Save" />
            <SaveOverExistedFileModal
                isOpen={saveOverExistedOpen}
                onConfirm={confirmSave}
                onCancel={() => setSaveOverExistedOpen(false)}
            />
        </>
    );
};
