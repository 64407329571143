import React, { useEffect } from "react";
import { Article, DefaultParagraph } from "../components/UI";
import { MDBContainer } from "mdbreact";
import { ArticleTypes } from "../typings";
import { UserActivityApi } from "../core/api/userActivityApi";
import { UserActivityActionNames } from "../components/usersManagement";

export const HelpPage = () => {
    useEffect(() => {
        (async () =>
            await UserActivityApi.create(
                UserActivityActionNames.ViewHelpPage
            ))();
    }, []);

    return (
        <MDBContainer>
            <DefaultParagraph text="Help" />
            <Article articleType={ArticleTypes.Help} />
        </MDBContainer>
    );
};
