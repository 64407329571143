import React from "react";
import Recaptcha from "react-recaptcha";
import { MDBAlert, MDBContainer } from "mdbreact";
import { Constants } from "../../common";

interface IProps {
    verifyCallback: () => void;
    clicked: boolean;
}

export const Captcha = (props: IProps) => (
    <MDBContainer className="d-flex justify-content-center">
        <MDBAlert color={props.clicked ? "danger" : "warning"} className="text-center ">
            <div className="d-flex justify-content-center">
                <Recaptcha
                    sitekey={Constants.CaptchaSitekey}
                    render="explicit"
                    size={window.innerWidth > 800 ? "normal" : "compact"}
                    verifyCallback={() => {
                        props.verifyCallback();
                        document.querySelectorAll("iframe[src*=recaptcha]").forEach(a => a.remove());
                    }}
                />
            </div>
            Please complete the captcha to proceed
        </MDBAlert>
    </MDBContainer>
);
