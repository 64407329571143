import React from "react";
import { Styled } from "./styled";
import { IconWithTooltip } from "../tooltips/iconWithTooltip";

interface IProps {
    onClick: () => void;
    icon: string;
    tooltip?: string;
}
export const GridActionIcon = (props: IProps) => {
    return (
        <IconWithTooltip
            style={Styled.ActionsFieldIcon}
            onClick={props.onClick}
            icon={props.icon}
            tooltip={props.tooltip}
        />
    );
};
