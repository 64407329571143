import { errorMessages } from "../messages/messages";
import { PasswordPattern } from "../passwordPattern";

export const ValidatePasswords = (password?: string, confirmPassword?: string) => {
    if (!password) return errorMessages.pleaseFillInNewPassword;
    if (!confirmPassword) return errorMessages.pleaseFillInConfirmNewPassword;
    if (!new RegExp(PasswordPattern).test(password)) return errorMessages.passwordDoesNotMatchThePattern;
    if (password !== confirmPassword) return errorMessages.passwordsDoNotMatch;
    return undefined;
};

export const ValidatePasswordFormat = (password?: string) => {
    if (password && password === "") return undefined;
    if (password && !new RegExp(PasswordPattern).test(password)) return errorMessages.passwordDoesNotMatchThePattern;    
    return undefined;
}
