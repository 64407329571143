declare global {
    interface String {
        isIn(...params: String[]): boolean;
    }
}

String.prototype.isIn = function (...params: String[]): boolean {
    return params.any() && params.includes(String(this));
};

export { };