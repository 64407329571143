import React from "react";
import { Accordion, ButtonGroup } from "../../UI";
import { SortByAttributes } from "./sortByAttributes";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "..";

interface IProps {
    collapseId: number | undefined;
    setCollapseId: (collapseId: number | undefined) => void;
}

export const SortingOrder = (props: IProps) => {
    const { query, queryMode, setQuery } = useQuery();

    const getLabel = () => {
        const dataSetNames = query.dataSets.filter(d => d.isSelected).map(d => d.dataSetName);
        return dataSetNames.join(" / ");
    };

    const changeSortingOptions = (isShowTotalOnly: boolean) => {
        setQuery({ ...query, isShowTotalOnly: isShowTotalOnly });
    };

    return (
        <Accordion label={getLabel()} collapseId={props.collapseId} setCollapseId={props.setCollapseId}>
            <ButtonGroup
                isDisabled={queryMode === QueryMode.View}
                isLeftButtonSelected={query.isShowTotalOnly}
                onButtonClick={changeSortingOptions}
                leftButtonText="Show Total Only"
                rightButtonText="Choose Display Options"
            />
            {!query.isShowTotalOnly && <SortByAttributes />}
        </Accordion>
    );
};
