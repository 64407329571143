import React, { useState } from "react";
import { RolesApi } from "../../../core/api";
import { DefaultModal, IconWithTooltip } from "../../UI";
import { Styled } from "./styled";
import { useRole } from "../store/roleContext";

interface IProps {
    roleId: number | undefined;
}

export const DeleteRoleButton = (props: IProps) => {
    const [deleteRoleModalIsOpen, setDeleteRoleModalIsOpen] = useState(false);
    const { roles, setRoles } = useRole();

    const deleteRole = async () => {
        var deletedIndex = roles.findIndex(r => r.id === props.roleId);
        if (deletedIndex !== -1) {
            await RolesApi.delete(props.roleId);
            var newArray = [...roles];
            newArray.splice(deletedIndex, 1);
            setRoles(newArray);
            setDeleteRoleModalIsOpen(false);
        }
    };

    return (
        <>
            <IconWithTooltip
                style={Styled.ControlButtons}
                tooltip="Delete"
                icon="trash-alt"
                onClick={() => setDeleteRoleModalIsOpen(true)}
            />
            <DefaultModal
                isOpen={deleteRoleModalIsOpen}
                onCancel={() => setDeleteRoleModalIsOpen(false)}
                onСonfirm={deleteRole}
                cancelButtonText="No, cancel"
                bodyText="Do you want to delete the selected role?"
                confirmButtonText="Yes, delete"
            />
        </>
    );
};
