import React from "react";
import { Styled } from "./style/styled";

export const InformationBlock = () => (
    <div className="text-center text-muted" style={Styled.Information}>
        <p>
            <a href="mailto:wardsdata@informa.com?subject=Wards Intelligence Data Query support"> Contact us for <b>Data Query</b> support</a>
        </p>
        <p>
        If you need assistance regarding your <b>Wards Intelligence</b> site subscription, please&nbsp;
        <a href="//wardsintelligence.informa.com/contact-us" target="_blank" rel="noopener noreferrer">
            click here. 
        </a>&nbsp;
        <b>Data Query</b> requires a separate subscription.
        </p>
        <p>
            Visit
            <a href="//wardsintelligence.informa.com" target="_blank" rel="noopener noreferrer">
                &nbsp;Wards Intelligence&nbsp;
            </a>
            for information <br/> about our products and services
        </p>
    </div>
);
