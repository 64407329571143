const BaseFooter = {
    fontSize: "15px",
    height: "60px"
};

const Footer = {
    ...BaseFooter,
    background: "linear-gradient(122.28deg, #28A5AD 2.45%, rgba(255, 255, 255, 0) 73.11%), #01688F",
    color: "white",
    position: "fixed",
    bottom: "0px",
    boxShadow: "0 0 5px rgba(0,0,0,0.5)"
};

const ContentFooter = {
    ...BaseFooter,
    color: "#626262"
};

export const Styled = {
    Footer,
    ContentFooter
};
