import React from "react";
import { MDBIcon } from "mdbreact";
import { Tooltip } from "../../UI";

interface IProps {
    icon: string;
    tooltip: string | undefined;
    onClick: () => void;
    style?: {};
    className?: string;
}

export const IconWithTooltip = (props: IProps) => (
    <Tooltip text={props.tooltip}>
        <MDBIcon
            className={`pr-1 cursor-pointer ${props.className}`}
            style={props.style}
            icon={props.icon}
            onClick={props.onClick}
        />
    </Tooltip>
);
