import { MDBContainer, MDBRow, MDBCol, MDBAlert } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Styled } from "./styles/styled";
import { ReportTitle } from "./reportTitle";
import { PivotCheckbox } from "./pivotCheckbox";
import { ReportPreview } from "./reportPreview";
import { ViewOnNewTab } from "./viewOnNewTab";
import { useQuery } from "../store/queryContext";
import { RefreshTableButton } from "./refreshTableButton";
import { QueryCreationSteps } from "../../UI";
import { VisualizationChart } from "../visualization/visualizationChart";
import { DataReceiver } from "./services/dataReceiver";
import { VisualizationApi } from "../../../core/api";
import { QueryValidationService } from "../common";
import { VisualizationCheckbox } from "../visualization/visualizationCheckbox";

export const PreviewAndExport = (activeStep: QueryCreationSteps) => {
    const { query, setQuery } = useQuery();
    const [visualizationError, setVisualizationError] = useState<boolean>(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isTimeOut, setIsTimeOut] = useState(false);

    useEffect(() => {
        (async () => {
            if (QueryValidationService.isValid(query)) {
                if (activeStep === QueryCreationSteps.PreviewAndExport) {
                    setIsDataLoaded(false);
                    setIsTimeOut(false);
                    const oldReportFile = query.report;
                    const newReportFile = await DataReceiver.getReportFile(query, false);
                    const chartData = query.isVisualization
                        ? oldReportFile == newReportFile
                            ? query.chartData
                            : await VisualizationApi.get(query)
                        : undefined;
                    if (!query.report?.length) {
                        setIsDataLoaded(true);
                    }
                    if(query.report === undefined)
                    {
                        setIsTimeOut(true);
                    }
                    setQuery({
                        ...query,
                        report: newReportFile,
                        chartData: chartData
                    });
                }
            } else query.report = undefined;
        })();
    }, [activeStep, query.isPivot]);
    return (
        <MDBContainer>
            <MDBRow className="mt-3">
                <MDBCol size="6">
                    <ReportTitle />
                </MDBCol>
                <MDBCol className="pl-0" style={Styled.CheckBoxWrapper}>
                    <VisualizationCheckbox setError={setVisualizationError} />
                </MDBCol>
            </MDBRow>
            <VisualizationChart error={visualizationError} setError={setVisualizationError} />
            <MDBRow>
                {query.report?.length && (
                    <MDBCol>
                    <div className="row mt-2 mb-2">
                        <div className="col-6">
                            <RefreshTableButton />
                            <ViewOnNewTab />
                        </div>
                        <div className="col pl-0 PivotCheckbox">
                            
                        <PivotCheckbox />
                        </div>
                        </div>
                    <ReportPreview isQueryPage file={query.report} isPivot={query.isPivot} />
                </MDBCol>
                )}
            </MDBRow>
            {isTimeOut && <MDBAlert color="danger">Query has timed out. Please narrow search by selecting a subset of data in Step 2 and/or choose shorter date range in Step 4.
            </MDBAlert>}
            {!isTimeOut && isDataLoaded && !query.report?.length && (
                <div>
                    <p className="text-danger">
                        No data was found for any of the periods specified. Try a different period or try changing your
                        Search or Sort criteria.
                    </p>
                    <RefreshTableButton />
                </div>
            )}
        </MDBContainer>
    );
};
