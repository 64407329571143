import React from "react";
import { InputCell } from "../../UI";
import { IQuery } from "../interfaces/IQuery";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "../enums/queryMode";

interface IProps {
    query: IQuery;
}

export const EditQueryNameField = (props: IProps) => {
    const { queryMode } = useQuery();

    return (
        <InputCell
            isDisabled={queryMode === QueryMode.View}
            className="mb-0"
            defaultValue={props.query.queryName}
            getValue={(value: any) => {
                props.query.queryName = value.toString();
                props.query.isUpdated = true;
            }}
            value={props.query.queryName}
            label="Name"
        />
    );
};
