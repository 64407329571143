const BaseStyles = {
    height: "55px",
    margin: "20px 0 20px 0"
};

const UploadFileForm = {
    ...BaseStyles,
    position: "relative",
    width: "100%",
    backgroundImage:
        "linear-gradient(135deg,rgba(0,0,0,.03)25%, transparent 25%, transparent 50%, rgba(0,0,0,.03)50%, rgba(0,0,0,.03)75%, transparent 75%, transparent)",
    backgroundColor: "#FAFCFD",
    backgroundSize: "16px 16px"
};

const UploadFileFormActive = {
    ...BaseStyles,
    backgroundColor: "#eee"
};

const UploadFileFormText = {
    paddingTop: "15px"
};

export const Styled = {
    UploadFileForm,
    UploadFileFormActive,
    UploadFileFormText
};
