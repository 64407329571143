export const tableColumns = [
    {
        label: "Id",
        field: "id",
        sort: "asc"
    },
    {
        label: "User Name",
        field: "userName",
        sort: "asc"
    },
    {
        label: "Full Name",
        field: "fullName",
        sort: "asc"
    },
    {
        label: "Description",
        field: "description",
        sort: "asc"
    },
    {
        label: "Email",
        field: "email",
        sort: "asc"
    },
    {
        label: "Roles",
        field: "roles",
        sort: "asc"
    },
    {
        label: "Disabled",
        field: "isDisabled",
        sort: "disabled"
    },
    {
        label: "Actions",
        field: "actions",
        sort: "disabled"
    }
];
