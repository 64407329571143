import React from "react";
import { DefaultPageHeader } from "../components/baseNavigation";
import { LoginForm, WelcomeBlock } from "../components/login";
import { Footer } from "../components/footer";
import { useUser } from "../stores";
import { Redirect } from "react-router-dom";
import { Routes } from "../common";

export const LoginPage = () => {
    const { user } = useUser();

    return user.id === undefined ? (
        <>
            <DefaultPageHeader />
            <WelcomeBlock />
            <LoginForm />
            <Footer />
        </>
    ) : (
            <Redirect to={Routes.Home} />
        );
};
