import { httpPost } from "./request/requestApi";
import { ILogin, IResetPassword } from "../../typings";
import { ILoginResponse } from "../../components/login/types/loginResponse";

const controllerEndPoint = "account";

export class AccountApi {
    static async loginWithCredentials(login: ILogin): Promise<ILoginResponse> {
        return await httpPost(`${controllerEndPoint}/login`, { body: login });
    }

    static async logout() {
        await httpPost(`${controllerEndPoint}/logout`);
    }

    static async forgotPassword(email?: string) {
        await httpPost(`${controllerEndPoint}/forgot-password`, {
            body: { email: email }
        });
    }

    static async resetPassword(resetPasswortRequest: IResetPassword) {
        await httpPost(`${controllerEndPoint}/reset-password`, {
            body: resetPasswortRequest
        });
    }
}
