import React, { useState, useEffect } from "react";
import { RolesApi } from "../../core/api";
import { MDBSelect } from "mdbreact";

interface IProps {
    getValue: (values: string[]) => void;
    isSubmitted?: boolean;
    selectedRoles?: number[];
}

interface IOption {
    text: string | undefined;
    value: string | undefined;
}

export const RolesSelect = (props: IProps) => {
    const [roles, setRoles] = useState<Array<IOption>>([]);
    useEffect(() => {
        (async function loadRoles(): Promise<void> {
            const roles = await RolesApi.getAll();
            setRoles(
                roles.map(role => ({
                    text: role.name,
                    checked: role.id ? props.selectedRoles?.includes(role.id) : false,
                    value: role.id?.toString()
                }))
            );
        })();
    }, []);

    const getClassName = () =>
        props.selectedRoles?.length ? (props.isSubmitted ? "is-valid" : "") : props.isSubmitted ? "is-invalid" : "error";
    return (
        <div>
            <MDBSelect
                options={roles}
                className={getClassName()}
                multiple
                selected="Set Role"
                getValue={values => {
                    props.getValue(values);
                }}
            />
            <div className="invalid-feedback">Please fill in Set Role drop-down.</div>
        </div>
    );
};
