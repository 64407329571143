export const Constants = {
    UsCountry: "US",
    QueryLocalStorageKey: "query",
    ReportLocalSessionKey: "report",
    CurrentQueryLocalSessionKey: "current-query",
    CombinedChartBarStepNumber: 100000,
    CombinedChartLineStepNumber: 1,
    CombinedChartLineMaxRoundValue: 100000,
    ReportAndChartHeight: 85,
    ReportHeightCoefficient: 3.3,
};
