import React from "react";
import { MDBContainer, MDBTable } from "mdbreact";
import {
    Preloader,
    DefaultParagraph,
    GridHeader,
    GridProvider
} from "../components/UI";
import {
    ContactsManagementGridBody,
    ContactsProvider,
    InsertContactButton,
    SaveContactsButton,
    GridHeaders
} from "../components/contactUs";

export const ContactUsManagementPage = () => (
    <GridProvider>
        <ContactsProvider>
            <MDBContainer>
                <DefaultParagraph text="Contacts" />
                <SaveContactsButton />
                <InsertContactButton />
                <Preloader />
                <MDBTable className="mt-4">
                    <GridHeader headers={[...GridHeaders, "Actions"]} />
                    <ContactsManagementGridBody />
                </MDBTable>
            </MDBContainer>
        </ContactsProvider>
    </GridProvider>
);
