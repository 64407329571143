import { IQuery } from "../../interfaces/IQuery";
import { ApiError, ReportApi } from "../../../../core/api";
import { Constants } from "../../common/constants";
import { UserActivityApi } from "../../../../core/api/userActivityApi";
import { NotificationMessages, Notifications } from "../../../UI";

const getByteArray = (str: string) => {
    if (str) {
        const byteCharacters = atob(str);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    }
};

const getReportFile = async (query: IQuery, isExportContext: boolean) => {
    const savedReport = sessionStorage.getItem(Constants.CurrentQueryLocalSessionKey);
    if (!query.report || savedReport !== JSON.stringify(query)) {
        try {
            query.report = await ReportApi.get(query, isExportContext);
        } catch (e) {
            //if (e instanceof ApiError && (e.response.status === 406 || e.response.status === null)) {                
                return undefined;
            //}
        };
        sessionStorage.setItem(Constants.CurrentQueryLocalSessionKey, JSON.stringify(query));
    } else await UserActivityApi.create("Export query to excel");
    return query.report;
};

export const DataReceiver = {
    getByteArray,
    getReportFile
};
