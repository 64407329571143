import { IDataSet, ISort } from "../../../../typings";
import { IAttribute, IQuery } from "../../interfaces";
import { AttributeNames } from "../../../../common/attributeNames";
import { DataSetNames } from "../../../../common/dataSetNames";
import { PropertyNames } from "../../../../common/propertyNames";
import { CalculationNames } from "../calculationNames";
import { CalculationService } from "../../datesCalculations/calculations/calculationService";

const filterSorts = (isSaar: boolean | undefined, sorts: Array<ISort> | undefined) =>
    sorts ? sorts.filter(s => isAvailable(isSaar, s.description)) : [];

const filterAttributes = (isSaar?: boolean, attributes?: Array<IAttribute>) =>
    attributes ? attributes.filter(a => isAvailable(isSaar, a.description)) : [];

const updateSaarRelatedDataSets = (query: IQuery) => {
    query.dataSets?.forEach(d => {
        d.isSelected = d.isSelected && d.dataSetName === DataSetNames.Sales;
        d.isSelectAllowed = d.dataSetName === DataSetNames.Sales;
    });
};

const updateSalesDataSet = async (query: IQuery): Promise<void> => {
    const salesDataSet = query.dataSets.find(d => d.dataSetName === DataSetNames.Sales);
    if (query.isSAAR && salesDataSet) {
        updateSalesAttributes(salesDataSet);
        updateSalesFilters(salesDataSet);
    }
    else {
        CalculationService.updateSpecificCalculations(false, CalculationNames.SAAR, DataSetNames.Sales, query);
        await CalculationService.updateDateRangesCalculations(query);
    }
};

const updateSalesAttributes = (salesDataSet: IDataSet) => {
    salesDataSet.attributes?.forEach(a => {
        a.isSelected = a.isSelected && isSalesAttribute(a.description);
        a.options = a.isSelected ? a.options : [];
    });
};

const updateSalesFilters = (salesDataSet: IDataSet) => {
    salesDataSet.filters = salesDataSet.filters?.filter(f => isSalesProperty(f.propertyName));
};

const isSalesAttribute = (description: string): boolean =>
    description.isIn(AttributeNames.VehicleType, AttributeNames.Source);

const isSalesProperty = (propertyName: string): boolean =>
    propertyName.isIn(PropertyNames.Source, PropertyNames.VehicleType, PropertyNames.Country);

const updateSaar = (query: IQuery) => {
    if (!query.dataSets?.find(ds => ds.dataSetName === DataSetNames.Sales && ds.isSelected)) {
        query.isSAAR = false;
    }
};

const isAvailable = (isSaar?: boolean, description?: string) =>
    !isSaar || description === AttributeNames.VehicleType || description === AttributeNames.Source;

export const SaarService = {
    filterSorts,
    filterAttributes,
    updateSaarRelatedDataSets,
    updateSaar,
    updateSalesDataSet
};
