import React from "react";
import { MDBModal, MDBModalBody, MDBModalFooter } from "mdbreact";
import { ButtonStyled, DefaultButton } from "..";
import { Styled } from "./styled";

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    onСonfirm: () => void;
    bodyText: string;
    cancelButtonText: string;
    confirmButtonText: string;
    isFade?: boolean;
}

export const DefaultModal = (props: IProps) => (
    <MDBModal fade={props.isFade} isOpen={props.isOpen} centered disableBackdrop toggle={() => {}} inline={false} noClickableBodyWithoutBackdrop overflowScroll>
        <MDBModalBody style={Styled.ModalBody}>{props.bodyText}</MDBModalBody>
        <MDBModalFooter className="d-flex justify-content-between logout-yn">
            <DefaultButton size="sm" onClick={props.onСonfirm} style={{...ButtonStyled.PrimaryButton, ...Styled.Button}} text={props.confirmButtonText} />
            <DefaultButton size="sm" onClick={props.onCancel} className="btn-light" style={Styled.Button} text={props.cancelButtonText} />
        </MDBModalFooter>
    </MDBModal>
);
