import { Styled as NQStyled } from "../styled";

const MarketSelectorHeader = {
    ...NQStyled.Headers,
    fontSize: 15,
    paddingBottom: 25,
    marginRight: 15
};

const MarketSelectorBlock = {
    marginBottom: -30,
    marginLeft: 5
};

export const Styled = {
    MarketSelectorHeader,
    MarketSelectorBlock
};
