import React from 'react'
import { MDBIcon, MDBContainer } from "mdbreact";
import {
    useFiles,
    ScrollTable,
    DateField,
    DeleteFileAction,
    DownloadFileButton,
    ViewFileButton,
    SaveFileButton
} from "../UI";
import { DocumentInputCell } from "./documentInputCell";
import { useUser } from "../../stores";
import "./style/style.css";
import { TableColumns } from "./documentationTableColumns";
import { DocumentationOrderCell } from "./documentationOrderCell";

const PDF_ICON_NAME = "file-pdf";
const EXCEL_ICON_NAME = "file-excel";

export const DocumentManagementGrid = () => {
    const { files } = useFiles();
    const { user } = useUser();

    const rows = files.map((file, index) => ({
        order: (
            <DocumentationOrderCell key={file.id} file={file} currentPosition={index} maxPosition={files.length - 1} />
        ),
        file: <MDBIcon key={file.id} size="2x" icon={file.extension === ".pdf" ? PDF_ICON_NAME : EXCEL_ICON_NAME} />,
        title: <DocumentInputCell key={file.id} index={index} file={file} />,
        date: <DateField key={file.id} date={file.uploadedOn} />,
        actions: (
            <MDBContainer className="d-flex">
                {user.isAdmin && <SaveFileButton file={file} />}
                {user.isAdmin && <DeleteFileAction file={file} />}
                <DownloadFileButton file={file} />
                {file.extension === ".pdf" && <ViewFileButton fileId={file.id} />}
            </MDBContainer>
        )
    }));

    return (
        <ScrollTable
            className="document-management-table"
            maxHeight="65vh"
            small
            data={{
                columns: TableColumns,
                rows: rows
            }}
        />
    );
}
