import React, { useEffect, useState } from "react";
import { useQuery } from "../../store/queryContext";
import { Line } from "react-chartjs-2";
import { GraphDataReceiver } from "../services/graphDataReceiver";
import "./plugins/chartjs-plugin-watermark";

export const LineChart = () => {
    const { query } = useQuery();
    const [data, setData] = useState<any>();
    const [isChartRemountRequire, setIsChartRemountRequire] = useState<boolean>(true);

    useEffect(() => {
        const chartData = GraphDataReceiver.getLineChartData(
            GraphDataReceiver.getChartData(query),
            query.chartData?.attributeNames,
            query.chartData?.labels
        );
        setData(chartData);
        setIsChartRemountRequire(true);
    }, [query]);

    useEffect(() => {
        if (isChartRemountRequire) {
            setIsChartRemountRequire(false);
        }
    }, [isChartRemountRequire]);

    return isChartRemountRequire ? (
        <></>
    ) : (
        <Line data={data} options={GraphDataReceiver.getLineChartOptions(query.title)} />
    );
};
