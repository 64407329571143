import React from "react";
import { MDBContainer } from "mdbreact";
import { RedirectButton, ButtonStyled } from "../components/UI";
import { Routes } from "../common";
import {
    RoleManagementTable,
    RoleProvider
} from "../components/roleManagement";

export const RoleManagementPage = () => (
    <RoleProvider isLoadRolesNeeded>
        <MDBContainer>
            <MDBContainer style={ButtonStyled.ButtonContainer}>
                <RedirectButton
                    text="ADD A NEW ROLE"
                    route={Routes.CreateRole}
                />
            </MDBContainer>
            <RoleManagementTable />
        </MDBContainer>
    </RoleProvider>
);
