import React from "react";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "..";
import { MDBInput } from "mdbreact";

import { chartType } from "./types/chartTypes";
import { VisualizationApi } from "../../../core/api";
import { VisualizationAvailabilityService } from "./services/visualizationAvailabilityService ";
import { QueryService, DataSetService } from "../common";

interface IProps {
    setError: (error: boolean) => void;
}

export const VisualizationCheckbox = (props: IProps) => {
    const { queryMode, query, setQuery } = useQuery();
    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        if (
            !VisualizationAvailabilityService.isVisualizationAvailable(
                DataSetService.getSelected(query.dataSets),
                query.isCorrelation
            )
        ) {
            props.setError(true);
            e.currentTarget.checked = false;
        } else {
            setQuery({
                ...query,
                isVisualization: e.currentTarget.checked,
                chartType:
                    VisualizationAvailabilityService.isCombinedChartAvailable(query) && !query.isCorrelation
                        ? chartType.special
                        : chartType.bar,
                chartData: e.currentTarget.checked ? await VisualizationApi.get(query) : undefined
            });
        }
    };

    return (
        <MDBInput
            type="checkbox"
            id="visualization-checkbox"
            label="Visualization"
            className="mt-3 ml-1"
            disabled={queryMode === QueryMode.View || !QueryService.isReportAvailable(query)}
            onChange={onChange}
            checked={query.isVisualization}
        />
    );
};
