import React from "react";
import { MDBContainer } from "mdbreact";
import { ArticleTypes } from "../typings";
import {
    DefaultParagraph,
    EditorSaveButton,
    EditorCancelButton,
    Constants,
    Spinner,
    ArticleEditor
} from "../components/UI";
import { EditableArticleProvider } from "../components/UI/textEditors/articleEditor/stores/editableArticleProvider";

export const LandingPageManagement = () => {
    return (
        <EditableArticleProvider>
            <MDBContainer>
                <Spinner />
                <DefaultParagraph text="Landing Page" />
                <EditorSaveButton
                    articleType={ArticleTypes.News}
                    isHeaderExists
                    bodyMaxLength={Constants.LandingBodyMaxLength}
                    titleMaxLength={Constants.FooterAndLandingTitleMaxLength}
                />
                <EditorCancelButton articleType={ArticleTypes.News} />
                <DefaultParagraph text="Title" />
                <ArticleEditor
                    articleType={ArticleTypes.News}
                    maxLength={Constants.FooterAndLandingTitleMaxLength}
                    propertyName="articleHeader"
                    label="Title"
                />
                <DefaultParagraph text="Body" />
                <ArticleEditor
                    articleType={ArticleTypes.News}
                    maxLength={Constants.LandingBodyMaxLength}
                    propertyName="articleBody"
                    label="Body"
                />
            </MDBContainer>
        </EditableArticleProvider>
    );
};
