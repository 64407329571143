import React from "react";
import { useUser } from "../../stores";
import { Routes } from "../routes";
import { BasePrivateRoute } from "./basePrivateRoute";

interface IProps {
    component: any;
}

export const LoginPrivateRoute = (props: IProps) => {
    const { user } = useUser();

    return (
        <BasePrivateRoute
            redirectTo={Routes.Login}
            component={props.component}
            isAccessAllow={user.id !== undefined}
        />
    );
};
