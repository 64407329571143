import { MDBContainer } from "mdbreact";
import React from "react";
import { useQuery } from "../store/queryContext";
import { CorrelationSortAndDisplayOptions } from "./correlationSortAndDisplayOptions";
import { StandartSortAndDisplayOptions } from "./standartSortAndDisplayOptions";

export const SortAndDisplayOptions = () => {
    const { query } = useQuery();

    return (
        <MDBContainer>
            {query.isCorrelation ? (
                <CorrelationSortAndDisplayOptions />
            ) : (
                <StandartSortAndDisplayOptions />
            )}
        </MDBContainer>
    );
};
