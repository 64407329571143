import { getFieldName } from "../fieldNamesCreator";
import { IDataSet } from "../../../../typings";

const deleteFilter = (dataSet: IDataSet, description: string) => {
    dataSet.filters = dataSet.filters?.filter(f => f.propertyName !== getFieldName(description));
};

const getFilter = (dataSet: IDataSet, description: string) =>
    dataSet.filters?.find(f => f.propertyName === getFieldName(description));

export const DataSetFilterService = {
    deleteFilter,
    getFilter
};
