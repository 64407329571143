import { httpGet, httpPost, httpPut } from "./request/requestApi";
import { IContact } from "../../typings";

const controllerEndPoint = "contact";

export class ContactApi {
    static async getAll(): Promise<Array<IContact>> {
        return await httpGet(`${controllerEndPoint}/get-all`);
    }

    static async delete(contactId: number) {
        await httpPost(`${controllerEndPoint}/delete`, {
            body: { contactId: contactId }
        });
    }

    static async create(contacts: Array<IContact>) {
        await httpPut(`${controllerEndPoint}/create`, {
            body: { contacts }
        });
    }

    static async update(contacts: Array<IContact>) {
        await httpPut(`${controllerEndPoint}/update`, {
            body: { contacts }
        });
    }
}
