import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { AttributeSelector } from "./attributeSelector";
import React, { useState } from "react";
import { useQuery } from "../store/queryContext";
import { IDataSet } from "../../../typings";

export const NarrowSearch = () => {
    const { query } = useQuery();
    const [collapseId, setCollapseId] = useState<number | undefined>(0);
    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol className="text-justify">
                    Choose Total Industry or choose attribute(s) to narrow the data in the report. Multiple choices are
                    interdependent and will continue to winnow down the dataset.
                </MDBCol>
            </MDBRow>
            {query.dataSets
                ?.filter(d => d.isSelected)
                ?.map((d: IDataSet, index: number) => (
                    <AttributeSelector
                        key={index}
                        dataSet={d}
                        index={index}
                        collapseId={collapseId}
                        setCollapseId={setCollapseId}
                    />
                ))}
        </MDBContainer>
    );
};
