import { httpGet } from "./request/requestApi";
import { IAttribute } from "../../components/queries/interfaces/IAttribute";
import { IQuery } from "../../components/queries/interfaces/IQuery";
import { IDataSet } from "../../typings";

const controllerEndPoint = "attribute";

export class AttributeApi {
    static async getAll(dataSet: IDataSet, query: IQuery): Promise<Array<IAttribute>> {
        const dataSetName = encodeURIComponent(dataSet.dataSetName ?? "");
        return query.country || query.region
            ? await httpGet(`${controllerEndPoint}/get-all?${AttributeApi.getQueryParam(query)}&volume=${dataSetName}`)
            : await httpGet(`${controllerEndPoint}/get-all?volume=${dataSetName}`);
    }

    static getQueryParam = (query: IQuery) => (query.country ? `country=${query.country}` : `region=${query.region}`);
}
