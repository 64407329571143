import React from "react";
import { DefaultButton } from "..";
import { QueryCreationSteps } from "./queryCreationSteps";
import { ExportToExcelButton } from "../buttons/exportToExcelButton";
import { useQuery } from "../../queries/store/queryContext";
import { ButtonStyled } from "../buttons/buttonStyled";
import { StepperService } from "./stepperService";
import { QueryValidationService } from "../../queries/common";
import "./styles.css";

interface IProps {
    activeStep: QueryCreationSteps;
    setActiveStep: (activeStep: QueryCreationSteps) => void;
    index: number;
}

export const DependOnStepButton = (props: IProps) => {
    const { query, errors, setErrors } = useQuery();

    const getButtonDependsOnStep = () => {
        switch (props.activeStep) {
            case QueryCreationSteps.PreviewAndExport:
                return <ExportToExcelButton />;
            default:
                return (
                    <DefaultButton
                        onClick={() => {
                            QueryValidationService.validate(query, errors, props.index + 1);
                            setErrors([...errors]);
                            if (StepperService.isStepAllowed(props.index + 1, errors))
                                props.setActiveStep(props.activeStep + 1);
                        }}
                        text="CONTINUE"
                        size="sm"
                        style={ButtonStyled.PrimaryButton}
                    />
                );
        }
    };
    return <>{getButtonDependsOnStep()}</>;
};
