import React from "react";
import { DefaultButton, ButtonStyled } from "..";
import { IDataSet } from "../../../typings";

interface IProps {
    dataset: IDataSet;
    isDisabled?: boolean;
    onClick: () => void;
}

export const DataSet = (props: IProps) => {
    const getStyle = () => {
        return props.dataset.isSelected ? ButtonStyled.ChoiseSelectedButton : ButtonStyled.ChoiseBaseButton;
    };

    return (
        <DefaultButton
            text={props.dataset.dataSetName}
            disabled={props.isDisabled}
            className="btn w-100"
            size="sm"
            style={getStyle()}
            onClick={() => {
                props.dataset.isSelected = !props.dataset.isSelected;
                props.onClick();
            }}
        />
    );
};
