import React from "react";
import { DataSet } from "../../UI";
import { AttributeApi, DataSetApi } from "../../../core/api";
import { useQuery } from "../store/queryContext";
import { QueryMode } from "..";
import { DatesService } from "../datesCalculations/dates/services/datesService";
import { IDataSet } from "../../../typings";
import { PropertyNames } from "../../../common/propertyNames";
import { CorrelationService, QueryErrorService, QueryService, SaarService, SortService } from "../common";

interface IProps {
    dataSet: IDataSet;
}

export const Dataset = (props: IProps) => {
    const { query, queryMode, updateQuery, errors, setErrors } = useQuery();

    const clearDataSet = () => {
        props.dataSet.filters = [];
        props.dataSet.isTotalIndustry = true;
        props.dataSet.isShowTotalOnly = true;
        props.dataSet.attributes = [];
        props.dataSet.sorts = [];
        query.isVisualization = false;
        query.isPivot = false;
        if (query.country)
            props.dataSet.filters.push({
                propertyName: PropertyNames.Country,
                values: [query.country]
            });
        if (query.region)
            props.dataSet.filters.push({
                propertyName: PropertyNames.Region,
                values: [query.region]
            });
    };

    const updateRelatedEntities = async () => {
        if (props.dataSet.isSelected) {
            props.dataSet.attributes = await AttributeApi.getAll(props.dataSet, query);
            props.dataSet.ranges = await DataSetApi.getDateRanges(props.dataSet, query);
            props.dataSet.dateRanges = [];
            props.dataSet.dateRanges.push(await DatesService.getDateRange(props.dataSet, query.country));
        }
    };

    return (
        <DataSet
            isDisabled={queryMode === QueryMode.View || !props.dataSet.isSelectAllowed}
            dataset={props.dataSet}
            onClick={async () => {
                clearDataSet();
                SaarService.updateSaar(query);
                await QueryService.updateQueryDatasetsAvailability(query);
                CorrelationService.updateCorrelationCheckbox(query);
                await updateRelatedEntities();
                const sorts = SortService.getSorts(query.dataSets);
                updateQuery({ ...query, sorts: sorts });
                let newErrorList = errors;
                if (query.dataSets?.some(d => d.isSelected))
                    newErrorList = QueryErrorService.filterMessageErrors(newErrorList);
                setErrors(QueryErrorService.filterStepErrors(newErrorList));
            }}
        />
    );
};
