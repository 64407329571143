const Cell = {
    padding: "0 15px 0 15px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#DEE2E6"
};

export const Styled = {
    Cell
};
