import React from "react";
import {  MDBProgress, MDBContainer } from "mdbreact";
import { usePromiseTracker } from "react-promise-tracker";

export const Preloader = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      <MDBContainer>
        {
          (promiseInProgress === true) ?
            <MDBProgress material preloader /> :
            null
        }
      </MDBContainer>
    )
  }