import React, { PropsWithChildren, useState, useEffect } from "react";
import { queryContext } from "./queryContext";
import { IQuery } from "../../queries/interfaces/IQuery";
import { QueryApi } from "../../../core/api";
import { IUser } from "../../../typings";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../stores";
import { QueryMode } from "..";
import { QueryService } from "../common";
import { IQueryError } from "../interfaces";

interface IProps {
    isQueriesLoadNeeded?: boolean;
    query?: IQuery;
    queryMode: QueryMode;
}

interface IState {
    user: IUser;
}

export const QueryProvider = (props: PropsWithChildren<IProps>) => {
    const [query, setQuery] = useState<IQuery>(props.query ? props.query : QueryService.getDefaultQuery());
    const [userQueries, setUserQueries] = useState<Array<IQuery>>([]);
    const [errors, setErrors] = useState<Array<IQueryError>>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [saveQueryRequest, setSaveQueryRequest] = useState({ isRequire: false });
    const { user } = useUser();

    const history = useHistory();

    const getUserId = () => {
        const userFromState = (history.location.state as IState)?.user;
        return userFromState ? userFromState.id : user.id;
    };

    const updateQuery = (newQuery?: IQuery) => {
        newQuery = QueryService.setQueryTitle(newQuery ? newQuery : query);
        setQuery({ ...newQuery });
    };

    const dropQuery = () => {
        setQuery(QueryService.getDefaultQuery());
        setErrors([]);
        setIsSubmitted(false);
        setIsLoading(false);
        setSaveQueryRequest({ isRequire: false });
    }

    const value: any = {
        query,
        setQuery,
        userQueries,
        setUserQueries,
        queryMode: props.queryMode,
        userId: getUserId(),
        errors,
        setErrors,
        updateQuery,
        isSubmitted,
        setIsSubmitted,
        isLoading,
        setIsLoading,
        saveQueryRequest, 
        setSaveQueryRequest,
        dropQuery,
    };

    useEffect(() => {
        (async function loadQueries(): Promise<void> {
            if (props.isQueriesLoadNeeded) setUserQueries(await QueryApi.getAll(getUserId()));
        })();
    }, []);

    return <queryContext.Provider value={value}>{props.children}</queryContext.Provider>;
};
