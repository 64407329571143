import React, { useState } from "react";
import { CustomInputField } from "../UI";
import { useRole } from "./store/roleContext";
import { RoleWithSameNameAlreadyExists } from "./messages/serverResponses";

interface IProps {
    isSubmitted: boolean;
    isRoleNameAlreadyExists: boolean;
}

export const RoleName = (props: IProps) => {
    const { role } = useRole();
    const [, setRoleName] = useState(role.name);

    return (
        <>
            <CustomInputField
                placeholder="Role"
                label="Role"
                maxLength={254}
                getValue={(value: any) => {
                    const text = value.toString();
                    role.name = text;
                    setRoleName(text);
                }}
                isErrorNeeded={props.isSubmitted}
                isRequired
                value={role.name}
            />
            {props.isRoleNameAlreadyExists && (
                <div className="mb-3 text-danger">
                    {RoleWithSameNameAlreadyExists}
                </div>
            )}
        </>
    );
};
