import React from "react";
import { Routes } from "../../../common";
import { useHistory } from "react-router-dom";
import { useQuery } from "../store/queryContext";
import { QueryCreationSteps, IconWithTooltip } from "../../UI";
import { QueryService } from "../common";

interface IProps {
    queryId: number | undefined;
    step: QueryCreationSteps;
    icon: string;
    tooltip: string;
}

export const RedirectToQueryButton = (props: IProps) => {
    const history = useHistory();
    const { queryMode } = useQuery();

    const redirectToQuery = async () => {
        const query = await QueryService.getQuery(props.queryId);
        history.push(Routes.EditQuery, {
            query: query,
            queryMode: queryMode,
            step: props.step
        });
        window.scrollTo(0, 0);
    };

    return <IconWithTooltip tooltip={props.tooltip} icon={props.icon} onClick={redirectToQuery} />;
};
