import { getFieldName } from "../common/fieldNamesCreator";
import { SubattributeApi } from "../../../core/api";
import { IAttribute } from "../interfaces/IAttribute";
import { ISubattribute } from "../interfaces/ISubattribute";
import { IDataSet } from "../../../typings";

const loadSubAttributes = async (dataSet: IDataSet, attribute: IAttribute) => {
    const fieldName = getFieldName(attribute.description);
    const subattributes = await SubattributeApi.getAll(
        dataSet.dataSetName,
        dataSet.filters?.filter(f => f.propertyName !== fieldName),
        fieldName
    );
    attribute.options = [];
    subattributes.forEach(s => {
        attribute.options?.push(getOption(fieldName, s, dataSet));
    });
};

const getOption = (fieldName: string, subattribute: ISubattribute, dataSet: IDataSet) => {
    return {
        value: subattribute.value,
        text: subattribute.description,
        checked: isOptionSelected(dataSet, fieldName, subattribute.value)
    };
};

const isOptionSelected = (dataSet: IDataSet, fieldName: string, option: string) =>
    dataSet.filters?.filter(f => f.propertyName === fieldName && f.values.filter(ft => ft === option).any()).any();

export const AttributeService = {
    loadSubAttributes,
    isOptionSelected
};
