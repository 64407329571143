import { CorrelationConstants, DataSetService, Messages, QueryErrorService } from "..";
import { datePeriod, dateRanges, IDataSet, periods } from "../../../../typings";
import { QueryCreationSteps } from "../../../UI";
import { DatesService } from "../../datesCalculations/dates/services/datesService";
import { IPeriodsRanges, IQuery, IQueryError } from "../../interfaces";

const validate = (query: IQuery, errors: Array<IQueryError>, step?: QueryCreationSteps) => {
    if (!query.isCorrelation) return;
    if (DataSetService.getSelected(query.dataSets).length < CorrelationConstants.CorrelationDataSetsCount) {
        QueryErrorService.pushError(errors, QueryCreationSteps.SelectMarketAndDataset,
            Messages.PleaseSelectTwoDatasetsToCorrelateThem, 0);
    }
    else if (!isCorrelationPeriodsValid(query) && (!step || step === QueryCreationSteps.PreviewAndExport)) {
        QueryErrorService.pushError(errors, QueryCreationSteps.ChooseDatesAndCalculations,
            Messages.AdjustDateRangesSoTheyHaveTheSameNumberOfTimePeriods, 0);
    }
};

const isCorrelationPeriodsValid = (query: IQuery) => {
    const firstDataSet = DataSetService.getFirstSelected(query.dataSets);
    const secondDataSet = DataSetService.getSecondSelected(query.dataSets);
    const dateRanges = firstDataSet.dateRanges?.first();
    switch (dateRanges?.period) {
        case periods.modelYear:
        case periods.year:
            return isPeriodsValid(DatesService.getYearsCount, firstDataSet, secondDataSet, dateRanges);
        case periods.month:
            return isPeriodsValid(DatesService.getMonthsCount, firstDataSet, secondDataSet, dateRanges);
        case periods.quarter:
            return isPeriodsValid(DatesService.getQuartersCount, firstDataSet, secondDataSet, dateRanges);
        default:
            return false;
    }
};

const isPeriodsValid = (
    getPeriods: (datePerion: datePeriod | undefined, periodRanges: IPeriodsRanges | undefined) => number,
    firstDataSet: IDataSet,
    secondDataSet: IDataSet,
    dateRanges: dateRanges | undefined) => {
    return getPeriods(dateRanges?.firstRange, firstDataSet.ranges) ===
        getPeriods(dateRanges?.secondRange, secondDataSet.ranges)
}

export const CorrelationValidationService = {
    validate
}