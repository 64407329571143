import { ICalculation } from "../../interfaces";

const IsRequiredRateCalculationsSelected = (calculations: ICalculation[] | undefined) => {
    return isRateDateTypeCalculationSelected(calculations) && isRateVolumeTypeCalculationSelected(calculations);
}

const isRateDateTypeCalculationSelected = (calculations: ICalculation[] | undefined) => {
    return calculations?.some(c => c.isSelected && c.isCommon);
}

const isRateVolumeTypeCalculationSelected = (calculations: ICalculation[] | undefined) => {
    return calculations?.some(c => c.isSelected && !c.isCommon);
}

export const RateService = {
    IsRequiredRateCalculationsSelected,
    isRateDateTypeCalculationSelected,
    isRateVolumeTypeCalculationSelected
};