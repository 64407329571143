import React from "react";
import { useQuery } from "../../store/queryContext";
import { SpecificCalculationCheckbox } from "./specificCalculationCheckbox";
import { DataSetNames } from "../../../../common/dataSetNames";
import { CalculationService } from "../../datesCalculations/calculations/calculationService";
import { CalculationNames, SpecificationService } from "../../common";

export const FeiuCheckbox = () => {
    const { query, setQuery } = useQuery();
    return (
        <>
            {SpecificationService.isSpecificationsSelected(query) && (
                <SpecificCalculationCheckbox
                    id="feiuCheckbox"
                    label="Fuel Economy Index Unadjusted"
                    onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                        query.isFEIU = e.currentTarget.checked;
                        CalculationService.updateSpecificCalculations(
                            query.isFEIU,
                            CalculationNames.FEIU,
                            DataSetNames.Specifications,
                            query
                        );
                        await CalculationService.updateDateRangesCalculations(query);
                        setQuery({ ...query });
                    }}
                    checked={query.isFEIU}
                />
            )}
        </>
    );
};
