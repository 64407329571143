import React from "react";
import { VerticalLinearStepper } from "../components/UI"
import { QueryProvider, QueryMode } from "../components/queries";

export const NewQueryPage = () =>
    <QueryProvider queryMode={QueryMode.EditAndDelete}>
        <VerticalLinearStepper />
    </QueryProvider>


