import React from "react";
import { useQuery } from "../../store/queryContext";
import { MDBInput } from "mdbreact";
import { QueryMode } from "../..";

interface IProps {
    id: string;
    label: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    checked: boolean | undefined;
}

export const SpecificCalculationCheckbox = (props: IProps) => {
    const { queryMode } = useQuery();
    return (
        <MDBInput
            type="checkbox"
            id={props.id}
            label={props.label}
            className="mt-3 ml-1"
            disabled={queryMode === QueryMode.View}
            onChange={props.onChange}
            checked={props.checked}
        />
    );
};
