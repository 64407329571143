import React, { useState } from "react";
import { DefaultButton, DefaultModal } from "../../..";
import { ArticleTypes } from "../../../../../typings";
import { ButtonStyled } from "../../../buttons/buttonStyled";
import { useEditableArticle } from "../stores/editableArticleContext";

interface IProps {
    articleType: ArticleTypes;
}

export const EditorCancelButton = (props: IProps) => {
    const { editableArticle, setEditableArticle, originArticle } = useEditableArticle(props.articleType);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <DefaultButton
                style={ButtonStyled.SecondaryButton}
                onClick={() => setIsModalOpen(JSON.stringify(originArticle) !== JSON.stringify(editableArticle))}
                icon="undo"
                text="Cancel"
            />
            <DefaultModal
                isOpen={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onСonfirm={() => {
                    setIsModalOpen(false);
                    setEditableArticle({ ...originArticle });
                }}
                isFade={false}
                cancelButtonText="No, stay"
                confirmButtonText="Yes, discard"
                bodyText="Do you want to discard unsaved changes?"
            />
        </>
    );
};
