import React from "react";
import { IContactContext } from "./IContactsContext";

export const contactsContext = React.createContext<IContactContext>({
    contacts: [],
    setContacts: (): void => {}
});

export const useContacts = () => {
    const { contacts, setContacts } = React.useContext(contactsContext);
    if (!contacts) {
        throw new Error("Contacts context has not been initialized.");
    }
    return { contacts, setContacts };
};
