const ArticleContainer = {
    marginTop: "20px"
};

const ArticleHeader = {
    borderBottom: "1px solid #EDF1F1"
};

export const Styled = {
    ArticleContainer,
    ArticleHeader
};
